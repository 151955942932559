import { useState } from "react";
import { cx } from "helpers/cx";
import { getMaxWidth } from "core/modules/audience/Filters/TraitFilter/Value";
import { Command, CommandList } from "core/design-system/components/Command";
import {
  COMPARISON_TO_NAME,
  GREATER_THAN,
  HAS_ANY_VALUE,
  IS_UNKNOWN,
  LESS_THAN,
  TRAIT_COMPARISON_TYPES,
} from "core/constants/traitFilterComponents";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  Combobox,
  ComboboxOption,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { Button, Input } from "@chakra-ui/react";

const PAGE_PROPERTIES = ["url", "path", "title"];
const BLACK_LISTED_PAGE_COMPARISONS = [
  IS_UNKNOWN,
  HAS_ANY_VALUE,
  GREATER_THAN,
  LESS_THAN,
];

const PAGE_EVENT_COMPARISONS = TRAIT_COMPARISON_TYPES.filter(
  (c) => !BLACK_LISTED_PAGE_COMPARISONS.includes(c.value),
);

export const PageEvent: React.FC<{
  property: string;
  comparison: string | number;
  value: string;
  onChangeProperty: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onChangeComparison: (comparison: string) => void;
  onChangeValue: (value: string) => void;
}> = ({
  property,
  comparison,
  value,
  onChangeProperty,
  onChangeComparison,
  onChangeValue,
}) => {
  const [propertyValue, setPropertyValue] = useState<string>(value);

  return (
    <div className="flex h-full">
      <div className="flex items-center gap-1 rounded-l-md bg-gray-50 pl-3 text-sm">
        <p>Page where</p>
      </div>
      <div className="flex min-h-[33px]">
        <div className="relative flex items-center">
          <div className="flex h-full items-center gap-1 bg-gray-50 pl-3 text-sm">
            <div
              className={cx(
                "flex cursor-pointer truncate rounded bg-gray-50 px-1 py-0.5 hover:bg-gray-200",
                `max-w-[${getMaxWidth(true)}]`,
              )}
            >
              <Popover>
                <PopoverButton className="text-sm font-semibold">
                  {property}
                </PopoverButton>
                <PopoverPanel className="flex w-full flex-col">
                  {({ close }) => (
                    <Command>
                      <CommandList>
                        {PAGE_PROPERTIES.map((prop, index) => (
                          <ComboboxOption
                            className="w-full"
                            value={prop}
                            onClick={(e) => {
                              onChangeProperty(e);
                              close();
                            }}
                          >
                            <div className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-2 py-1.5 text-gray-900 hover:bg-gray-100">
                              <p className="text-xs font-medium" key={prop}>
                                {prop}
                              </p>
                              {prop === property && (
                                <CheckIcon className="h-4" />
                              )}
                            </div>
                          </ComboboxOption>
                        ))}
                      </CommandList>
                    </Command>
                  )}
                </PopoverPanel>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-[33px]">
        <div className="relative flex items-center">
          <div className="flex h-full items-center gap-1 bg-gray-50 pl-3 text-sm">
            <div
              className={cx(
                "flex cursor-pointer truncate rounded bg-gray-50 px-1 py-0.5 hover:bg-gray-200",
                `max-w-[${getMaxWidth(true)}]`,
              )}
            >
              <Popover>
                <PopoverButton>
                  {COMPARISON_TO_NAME[comparison as any]}
                </PopoverButton>
                <PopoverPanel className="flex flex-col">
                  {({ close }) => (
                    <Command>
                      <CommandList>
                        {PAGE_EVENT_COMPARISONS.map((c) => (
                          <Combobox.Option
                            className="w-full"
                            value={c.value.toString()}
                            onClick={() => {
                              onChangeComparison(c.value.toString());
                              close();
                            }}
                          >
                            <div className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-2 py-1.5 text-gray-900 hover:bg-gray-100">
                              <p className="text-xs font-medium" key={c.name}>
                                {c.name}
                              </p>
                              {c.value.toString() === comparison && (
                                <CheckIcon className="h-4" />
                              )}
                            </div>
                          </Combobox.Option>
                        ))}
                      </CommandList>
                    </Command>
                  )}
                </PopoverPanel>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-[33px]">
        <div className="relative flex items-center">
          <div className="flex h-full items-center gap-1 bg-gray-50 pl-3 text-sm">
            <div
              className={cx(
                "flex cursor-pointer truncate rounded bg-gray-50 px-1 py-0.5 hover:bg-gray-200",
                `max-w-[${getMaxWidth(true)}]`,
              )}
            >
              <Popover>
                {({ close }) => (
                  <>
                    <PopoverButton className="text-sm font-semibold">
                      {propertyValue || "Select a value"}
                    </PopoverButton>
                    <PopoverPanel className="flex flex-col">
                      <Command>
                        <Input
                          size="sm"
                          borderRadius="md"
                          focusBorderColor="purple.500"
                          value={propertyValue}
                          onChange={(e) => setPropertyValue(e.target.value)}
                        />
                        <Button
                          onClick={() => {
                            onChangeValue(propertyValue);
                            close();
                          }}
                        >
                          Done
                        </Button>
                      </Command>
                    </PopoverPanel>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
