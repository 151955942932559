import { MoonIcon } from "@chakra-ui/icons";

export const EmptyState = () => {
  return (
    <div className="flex h-[400px] min-w-full items-center justify-center">
      <div className="text-center">
        <div className="mb-4 inline-block rounded-lg px-4 py-3 shadow-[0px_1px_8px_#6868F7]">
          <MoonIcon className="text-purple-500" />
        </div>
        <p className="text-normal font-semibold">No data found</p>
        <p className="text-sm text-gray-600">
          We found no data for your prompt
        </p>
      </div>
    </div>
  );
};
