import React from "react";
import { useGetStripeAudienceQuery } from "core/models/stripeApps";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { UsersIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@chakra-ui/react";

export const StripeAudience: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { data: stripeAudience } = useGetStripeAudienceQuery({
    appId,
  });

  return (
    <Tooltip label="Available after first sync" isDisabled={!!stripeAudience}>
      <div
        className={`inline-flex items-center gap-2 rounded-md px-2 py-1 text-sm ${
          stripeAudience
            ? "cursor-pointer text-purple-500 hover:bg-purple-50"
            : "text-purple-300"
        }`}
        onClick={() => {
          if (stripeAudience) {
            window.open(`/a/${appId}/audience/${stripeAudience.id}`, "_blank");
          }
        }}
      >
        <div className="flex items-center gap-1">
          <UsersIcon className="h-4 w-4" />
          <p className="text-xs">View audience</p>
        </div>
      </div>
    </Tooltip>
  );
};
