import { Navigate } from "react-router-dom";
import { PublicViewInsightsContainer } from "modules/ViewInsight/PublicViewInsightsContainer";
import { inIframe } from "helpers/iframe";
import { cx } from "helpers/cx";
import { IView } from "core/models/views";
import { useUserAuth } from "core/hooks/useUserAuth";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { Button, Container } from "@chakra-ui/react";

interface IPublicViewProps {
  appId: number;
  view: IView;
  sharingSecretToken: string;
}

function SignUpBanner() {
  if (inIframe()) return null;

  return (
    <div
      className="flex w-[985px] items-start justify-center"
      data-id-public-banner
    >
      <div className="mb-4 flex w-full items-center justify-between rounded-lg border border-gray-50 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center">
          <div className="flex">
            <CompanyJuneLogoIcon h={6} w={6} color="primary" />
          </div>
          <p className="ml-4 text-sm font-medium">
            Welcome to June! Create an account to edit and collaborate on this
            dashboard.
          </p>
        </div>
        <div className="flex items-center">
          <Button as="a" href="https://analytics.june.so/login" fontSize="sm">
            Log in
          </Button>
          <Button
            ml={4}
            as="a"
            href="https://analytics.june.so/start"
            fontSize="sm"
            colorScheme="purple"
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
}

export const PublicView: React.FC<IPublicViewProps> = ({
  appId,
  view,
  sharingSecretToken,
}) => {
  const { currentUser } = useUserAuth();
  if (currentUser && !inIframe())
    return <Navigate to={`/a/${appId}/home/${view.id}`} />;

  return (
    <div
      data-testid="page-container"
      className={cx(
        "h-full min-h-[100vh] w-full min-w-[100vw] overflow-hidden bg-gray-50",
      )}
    >
      <div className="h-[99.9vh] p-2">
        <div className={cx("h-full overflow-auto rounded-lg py-8")}>
          <Container
            px="60px"
            maxW="container.lg"
            minW="container.lg"
            mx="auto"
          >
            <SignUpBanner />
            <div className="mb-3 flex w-full items-center justify-between">
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <p className="text-2xl">{view?.emoji ? view?.emoji : "🏠"}</p>
                  <p className="text-2xl">{view.name}</p>
                </div>
              </div>
            </div>
            {view && (
              <PublicViewInsightsContainer
                viewId={Number(view.id)}
                sharingSecretToken={sharingSecretToken}
              />
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};
