import { AppObjectType } from "core/types/AppObject";

export const USER_TRAIT_LEVEL = 0;
export const GROUP_TRAIT_LEVEL = 1;
export const COMPANY_TRAIT_LEVEL = 2;

export const USER_COUNT = 0;
export const EVENT_COUNT = 1;
export const FIRST_SEEN = 2;
export const LAST_SEEN = 3;
export const BOOLEAN = 4;
export const EVENT_COUNT_CHANGE = 5;
export const DAY_COUNT = 6;
export const RATIO = 7;
export const SQL = 8;
export enum ComputationType {
  UserCount = USER_COUNT,
  EventCount = EVENT_COUNT,
  DayCount = DAY_COUNT,
  FirstSeen = FIRST_SEEN,
  LastSeen = LAST_SEEN,
  Boolean = BOOLEAN,
  EventCountChange = EVENT_COUNT_CHANGE,
  Ratio = RATIO,
  Sql = SQL,
}

export enum TRAIT_LEVEL {
  USER = USER_TRAIT_LEVEL,
  GROUP = GROUP_TRAIT_LEVEL,
  COMPANY = COMPANY_TRAIT_LEVEL,
}

export const TRAIT_LEVEL_STRING: Record<number, string> = {
  [USER_TRAIT_LEVEL]: "user",
  [GROUP_TRAIT_LEVEL]: "group",
  [COMPANY_TRAIT_LEVEL]: "company",
};

export const TRAIT_LEVEL_NUMBER: Record<string, TRAIT_LEVEL> = {
  user: USER_TRAIT_LEVEL,
  group: GROUP_TRAIT_LEVEL,
  company: COMPANY_TRAIT_LEVEL,
};

export const STRING_LEVEL_TO_TRAIT_LEVEL = {
  user: USER_TRAIT_LEVEL,
  group: GROUP_TRAIT_LEVEL,
  company: COMPANY_TRAIT_LEVEL,
} as { [key: string]: number };

export const TRAIT_LEVEL_PLURAL_STRING = {
  [USER_TRAIT_LEVEL]: "users",
  [GROUP_TRAIT_LEVEL]: "groups",
  [COMPANY_TRAIT_LEVEL]: "companies",
};

export const TRAIT_LEVEL_RESOURCE = {
  [USER_TRAIT_LEVEL]: "contacts",
  [GROUP_TRAIT_LEVEL]: "groups",
  [COMPANY_TRAIT_LEVEL]: "companies",
};

export const TRAIT_JOIN_OPERATOR = {
  AND: 0,
  OR: 1,
};

export const READ_ONLY_TRAITS = ["First seen", "Last seen"];

export const APP_OBJECT_TO_TRAIT_LEVEL = {
  [AppObjectType.User]: USER_TRAIT_LEVEL,
  [AppObjectType.Group]: GROUP_TRAIT_LEVEL,
  [AppObjectType.Company]: COMPANY_TRAIT_LEVEL,
};
