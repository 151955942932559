import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AdditionalColumnCell } from "modules/Feed/List/AdditionalColumnCell";
import { snakeCase } from "lodash";
import { parseFilterGroupsFromUrl, updateURL } from "helpers/filters";
import { IEventLog } from "core/types/EventLog";
import { IAudienceFilters } from "core/types/Audience";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { useGetEventLogsQuery } from "core/models/eventLogs";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { SearchBar } from "core/components/SearchBar";
import { IListColumn } from "core/components/List/List.types";
import List from "core/components/List";
import { EventCodeSnippet } from "core/components/Event/CodeSnippet";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { config } from "./config";

function buildEventPropertiesListConfig(eventLogs: IEventLog[]): IListColumn[] {
  const eventPropertyKeys = eventLogs.reduce(
    (keys: string[], eventLog: IEventLog) => {
      const propertyKeys =
        eventLog.properties && (Object.keys(eventLog.properties) as string[]);
      propertyKeys?.forEach((k: string) => {
        if (!keys.includes(k)) {
          keys.push(k);
        }
      });
      return keys;
    },
    [],
  );
  return eventPropertyKeys?.map((k) => ({
    title: snakeCase(k).split("_").join(" "),
    name: k,
    align: "left",
    Component: AdditionalColumnCell,
  })) as unknown as IListColumn[];
}

function EventFeedList({
  eventName,
  showSearch = true,
  showFilters = true,
  showEventName,
  showEventType,
}: {
  eventName?: string;
  showSearch?: boolean;
  showFilters?: boolean;
  showEventName?: boolean;
  showEventType?: boolean;
}) {
  const { eventId } = useParams();
  const { id: appId } = useCurrentApp();

  const [eventLogs, setEventLogs] = useState<IEventLog[]>([]);
  const [page, setPage] = useState(1);
  const [hasMoreEventLogs, setHasMoreEventLogs] = useState(false);
  const query = useQueryParam("query");

  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>(
    parseFilterGroupsFromUrl() as IAudienceFilters,
  );
  const [searchQuery, setSearchQuery] = useState(eventName || query || "");

  const { data, isFetching } = useGetEventLogsQuery({
    appId,
    page,
    audienceFilters: eventId === undefined ? audienceFilters : undefined,
    eventName,
    query: searchQuery,
  });

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setPage(1);
  };

  const loadMore = () => {
    if (hasMoreEventLogs) setPage(page + 1);
  };

  useEffect(() => {
    if (eventId) return;

    updateURL({
      appId: Number(appId),
      audienceFilters,
      searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, audienceFilters, searchQuery]);

  useEffect(() => {
    if (data?.pagy) {
      setHasMoreEventLogs(data.pagy.page !== data.pagy.last);
    }
    if (data?.eventLogs) {
      const allEventLogs =
        page === 1 ? data.eventLogs : [...eventLogs, ...data.eventLogs];

      setEventLogs(allEventLogs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listConfig = config({
    showEventType,
    showEventName,
    additionalColumns: buildEventPropertiesListConfig(eventLogs),
  });

  if (eventLogs.length === 0 && isFetching) return <Spinner />;

  return (
    <>
      {showFilters && (
        <AudienceFilters
          audienceFilters={audienceFilters}
          setAudienceFilters={setAudienceFilters}
        />
      )}
      {showSearch && (
        <SearchBar
          my={4}
          searchQuery={searchQuery}
          onSearch={onSearch}
          placeholder="Search your events by name..."
        />
      )}
      {eventName && eventLogs.length === 0 && (
        <EventCodeSnippet
          entity="event"
          eventName={eventName}
          className="my-3 mt-0"
        />
      )}
      {(eventLogs.length > 0 || searchQuery) && (
        <>
          <List rows={eventLogs} config={listConfig} mb={4} />
          {hasMoreEventLogs && (
            <Flex justify="center" mb={8}>
              <Button isLoading={isFetching} onClick={loadMore}>
                Load more
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  );
}

export default EventFeedList;
