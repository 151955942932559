import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import moment from "moment";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { IAppObject } from "core/types/AppObject";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { useAttioObjects } from "core/hooks/useAttioObjects";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import { UninstalledCard } from "core/components/Attio/UninstalledCard";
import { ArrowPathIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Button, Skeleton, Tooltip } from "@chakra-ui/react";

const Profile: React.FC<{
  entity: IContact | IGroup;
  appObject: IAppObject;
}> = ({ entity, appObject }) => {
  const {
    objects,
    isLoading,
    isFetching,
    error,
    lastSyncedAt,
    isCreatingObject,
    isUpdatingObject,
    workspaceName,
    route,
    onCreateObject,
    onUpdateObject,
    onFetchObjects,
    crmSyncSetting,
  } = useAttioObjects({ entity, appObject });

  const { appId } = useParams();
  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="flex items-center gap-2">
        <AttioIcon w={4} h={4} mt={0.5} />
        <Skeleton borderRadius="md" height="20px" width="260px" />
      </div>
    );

  if (!crmSyncSetting?.isSetup)
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <AttioIcon w={4} h={4} />
          <p
            onClick={() =>
              navigate(`/a/${appId}/integrations/attio/${appObject.objectType}`)
            }
            className="cursor-pointer text-sm font-medium hover:underline"
          >
            Finish setup
          </p>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <AttioIcon w={4} h={4} />
          <p className="text-sm">An error occurred</p>
        </div>
        <div className="flex items-center">
          <Tooltip
            label={isFetching ? "Syncing..." : "Try again"}
            hasArrow
            placement="top"
            fontSize="xs"
          >
            <Button
              variant="link"
              isLoading={isFetching}
              onClick={onFetchObjects}
              color="gray.700"
              fontWeight="normal"
              textDecoration="none"
              boxShadow="none"
              size="xs"
            >
              <ArrowPathIcon className="h-4 w-4" />
            </Button>
          </Tooltip>
          <Tooltip
            label="Attio settings"
            hasArrow
            placement="top"
            fontSize="xs"
          >
            <Button
              size="xs"
              variant="link"
              color="gray.700"
              fontWeight="normal"
              textDecoration="none"
              boxShadow="none"
              onClick={() =>
                navigate(
                  `/a/${appId}/integrations/attio/${appObject.objectType}`,
                )
              }
            >
              <Cog6ToothIcon className="h-4 w-4" />
            </Button>
          </Tooltip>
        </div>
      </div>
    );

  if (!isLoading && !isFetching && objects.length === 0)
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <AttioIcon w={4} h={4} mt={0.5} />
          <Button
            isDisabled={isCreatingObject}
            variant="link"
            onClick={onCreateObject}
            color="gray.700"
            fontWeight="normal"
            textDecoration="none"
            boxShadow="none"
          >
            {isCreatingObject
              ? `Creating ${appObject?.singularName?.toLocaleLowerCase()}...`
              : `Create ${appObject?.singularName?.toLocaleLowerCase()} in Attio`}
          </Button>
        </div>
        <Tooltip label="View settings" hasArrow placement="top" fontSize="xs">
          <Button
            size="xs"
            variant="link"
            boxShadow="none"
            color="gray.700"
            onClick={() =>
              navigate(`/a/${appId}/integrations/attio/${appObject.objectType}`)
            }
          >
            <Cog6ToothIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
      </div>
    );

  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-2">
        <div className="flex-0">
          <AttioIcon w={4} h={4} mb="4px" />
        </div>
        <div className="flex-1">
          <div className="flex cursor-pointer items-center gap-1">
            <p className="text-sm text-gray-800">Last synced</p>
            <a
              href={`https://app.attio.com/${workspaceName}${route}${objects?.[0]?.id}`}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-purple-500 hover:underline"
            >
              {moment.utc(lastSyncedAt).fromNow()}
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <Tooltip
          label={isUpdatingObject ? "Syncing..." : "Sync to Attio"}
          hasArrow
          placement="top"
          fontSize="xs"
        >
          <Button
            isLoading={isUpdatingObject}
            size="xs"
            variant="link"
            onClick={onUpdateObject}
            color="gray.700"
            fontWeight="normal"
            textDecoration="none"
            boxShadow="none"
          >
            <ArrowPathIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
        <Tooltip label="Attio settings" hasArrow placement="top" fontSize="xs">
          <Button
            size="xs"
            variant="link"
            boxShadow="none"
            color="gray.700"
            onClick={() =>
              navigate(`/a/${appId}/integrations/attio/${appObject.objectType}`)
            }
          >
            <Cog6ToothIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export const AttioProfileContainer: React.FC<{
  entity: IContact | IGroup;
  appObject: IAppObject;
}> = ({ entity, appObject }) => {
  const { hasAttioInstalled, isLoadingSetting } = useAttioSettings();

  if (isLoadingSetting)
    return <Skeleton borderRadius="lg" height="30px" width="100%" />;

  if (!hasAttioInstalled) return <UninstalledCard />;

  return <Profile entity={entity} appObject={appObject} />;
};
