import React from "react";
import { IViewInsight } from "core/models/viewInsights";
import { useViewInsights } from "core/hooks/useViewInsights";
import { Grid, Spinner } from "@chakra-ui/react";
import { PublicViewInsight } from "./PublicViewInsight";

export const PublicViewInsightsContainer: React.FC<{
  viewId: number;
  sharingSecretToken?: string;
}> = ({ viewId, sharingSecretToken }) => {
  const { viewInsights, isLoadingViewInsights } = useViewInsights({
    viewId,
    sharingSecretToken,
  });

  if (isLoadingViewInsights) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
        <Spinner emptyColor="gray.200" color="gray.500" thickness="3px" />
      </div>
    );
  }
  const sortedViewInsights = viewInsights
    ? [...viewInsights].sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    : [];

  return (
    <Grid templateColumns={`repeat(3, 1fr)`} autoRows="315px" gridGap={5}>
      {sortedViewInsights?.map((viewInsight: IViewInsight, index: number) => {
        return (
          <PublicViewInsight
            key={viewInsight.id}
            viewInsight={viewInsight}
            sharingSecretToken={sharingSecretToken}
            isAI={Boolean(viewInsight.queryId)}
          />
        );
      })}
    </Grid>
  );
};
