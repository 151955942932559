import { MappingData } from "modules/Settings/Crm/SyncSettings/MappingData";
import { IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";

export const UserData: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  return (
    <MappingData appObject={appObject} integration={Integration.Hubspot} />
  );
};
