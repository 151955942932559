import { IAudienceFilters } from "core/types/Audience";
import { IAppObject } from "core/types/AppObject";
import { Level } from "core/models/people";
import { usePeopleCount } from "core/hooks/usePeopleCount";
import { useObjectCsvExport } from "core/hooks/useObjectCsvExport";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { Button, Tooltip } from "@chakra-ui/react";
import { OBJECT_TYPE_TO_GROUP_TYPE, OBJECT_TYPE_TO_LEVEL } from "./Tab";

export const ExportCsv: React.FC<{
  appObject: IAppObject;
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  groupId?: string;
}> = ({ appObject, audienceFilters, searchQuery, groupId }) => {
  const groupType = OBJECT_TYPE_TO_GROUP_TYPE[
    appObject.objectType
  ] as GroupType;
  const level = OBJECT_TYPE_TO_LEVEL[appObject.objectType] as Level;

  const { requestCsvExport } = useObjectCsvExport({
    audienceFilters,
    groupId,
    objectType: appObject.objectType,
  });

  const { matchedCount, isLoadingCount } = usePeopleCount({
    audienceFilters,
    searchQuery,
    groupId,
    level,
    groupType,
  });

  const exportTooltipLabel =
    searchQuery !== ""
      ? "Please clear the search bar to enable csv exports."
      : matchedCount === 1
        ? `Export ${matchedCount?.toLocaleString()} ${appObject.singularName.toLocaleLowerCase()} as CSV`
        : `Export ${matchedCount?.toLocaleString()} ${appObject.pluralName.toLocaleLowerCase()} as CSV`;

  return (
    <Tooltip label={exportTooltipLabel} placement="bottom">
      <Button
        minW="100px"
        size="sm"
        colorScheme="gray"
        onClick={requestCsvExport}
        isDisabled={isLoadingCount || matchedCount === 0 || searchQuery !== ""}
      >
        Export CSV
      </Button>
    </Tooltip>
  );
};
