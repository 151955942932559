import moment from "moment";
import {
  getInsightConfig,
  getReportConfigByType,
} from "modules/ViewInsight/ViewInsightContainer";
import { EditorDrawer } from "modules/Audiences/Alerts/EditorDrawer";
import { useGetSlackChannelsByIdQuery } from "core/models/slackApps";
import { IAlertWithAlertable } from "core/models/alerts";
import { FREQUENCIES } from "core/constants/alert";
import { SlackInsightEditorDrawer } from "core/components/InsightCard/SlackInsightEditorDrawer";
import { Button, useDisclosure } from "@chakra-ui/react";
import { SingleEventAlertDrawer } from "./SingleEventAlertDrawer";

const getAlertableDescription = (alert: IAlertWithAlertable) => {
  if (alert.alertableType === "Report" && "reportType" in alert.alertable) {
    return `${alert.alertable.name}`;
  } else if (alert.alertableType === "Audience") {
    return "Audience";
  } else {
    return "Event";
  }
};

const getAlertableTitle = (alert: IAlertWithAlertable) => {
  if (
    alert.alertableType === "Report" &&
    "reportType" in alert.alertable &&
    alert.insightId !== undefined &&
    alert.appObject
  ) {
    const insight = getInsightConfig(
      alert.alertable.reportType,
      alert.insightId,
    );

    const title = insight?.title.replace(
      /{APP_OBJECT_PLURAL_NAME}/,
      alert.appObject.pluralName.toLowerCase(),
    );

    return title;
  } else {
    return alert.alertable.name || alert.alertable.title;
  }
};

export const AlertRow: React.FC<{
  alert: IAlertWithAlertable;
}> = ({ alert }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const title = getAlertableTitle(alert);
  const description = getAlertableDescription(alert);

  const { data: slackChannel, isLoading: isLoadingSlackChannel } =
    useGetSlackChannelsByIdQuery({
      channelId: alert.slackChannelId || "",
      appId: alert.appId,
    });

  const frequency = FREQUENCIES.find((f) => f.value === alert.frequency)?.label;

  const insight =
    alert.alertableType === "Report" &&
    "reportType" in alert.alertable &&
    alert.insightId !== undefined
      ? getInsightConfig(alert.alertable.reportType, alert.insightId)
      : undefined;
  const reportConfig =
    alert.alertableType === "Report" && "reportType" in alert.alertable
      ? getReportConfigByType(alert.alertable.reportType)
      : undefined;

  return (
    <>
      <tr>
        <td className="flex flex-col whitespace-nowrap py-1 text-sm">
          <div className="text-gray-900">{title}</div>
          <div className="whitespace-nowrap text-xs text-gray-500">
            {description}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {isLoadingSlackChannel ? (
            <div className="flex h-[20px] w-[72px] animate-pulse flex-row items-center justify-center space-x-5">
              <div className="h-full w-full rounded-md bg-gray-300"></div>
            </div>
          ) : slackChannel ? (
            `#${slackChannel.name}`
          ) : (
            "Channel not found"
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {frequency}
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {moment.utc(alert.lastSentAt).fromNow() || "Never"}
        </td>
        <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <Button colorScheme="purple" variant="ghost" onClick={onOpen}>
            Edit
          </Button>
        </td>
      </tr>
      {alert.alertableType === "Report" &&
        "reportType" in alert.alertable &&
        alert.insightId !== undefined &&
        insight && (
          <SlackInsightEditorDrawer
            config={reportConfig}
            report={alert.alertable}
            insight={insight}
            isOpen={isOpen}
            onClose={onClose}
            alert={alert}
            isLoading={false}
            appId={String(alert.appId)}
            reportId={String(alert.alertable.id)}
          />
        )}
      {alert.alertableType === "Event" && (
        <SingleEventAlertDrawer
          alert={alert}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      {alert.alertableType === "Audience" && (
        <EditorDrawer
          isOpen={isOpen}
          onClose={onClose}
          audienceId={String(alert.alertable.id)}
        />
      )}
    </>
  );
};
