import { useParams } from "react-router-dom";
import {
  useDeleteCompanyMutation,
  useGetCompanyProfileQuery,
  useMergeCompanyMutation,
} from "core/models/companies";

export interface IUseCompanyProps {
  id: string;
  appId?: number;
}

export const useCompany = ({ id, appId: paramAppId }: IUseCompanyProps) => {
  const params = useParams<{ appId: string }>() as { appId: string };
  const appId = paramAppId ? paramAppId : params.appId;

  const {
    data: company,
    isLoading: isCompanyLoading,
    isFetching: isCompanyFetching,
    error: companyError,
    refetch,
  } = useGetCompanyProfileQuery({ appId: Number(appId), id });

  const [deleteCompany] = useDeleteCompanyMutation();
  const [mergeCompany] = useMergeCompanyMutation();

  return {
    company,
    isCompanyLoading,
    isCompanyFetching,
    companyError,
    deleteCompany,
    mergeCompany,
    getCompanyProfile: refetch,
  };
};
