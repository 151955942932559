import React from "react";
import { AppObjectType } from "core/types/AppObject";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { HubspotObjectTypes } from "core/hooks/useHubspotObjects";
import { IHSContact, useHubspotContact } from "core/hooks/useHubspotContact";
import { UninstalledCard } from "core/components/Hubspot/UninstalledCard";
import { Profile } from "core/components/Hubspot/Profile";

export interface IHubspotProfileProps {
  appId: string;
  contact: IHSContact;
  hubspotId?: string;
  showSettings?: boolean;
  showConnect?: boolean;
  objectType: AppObjectType;
}

const LoadProfile: React.FC<IHubspotProfileProps> = ({
  appId,
  contact,
  hubspotId,
  showSettings,
  showConnect,
  objectType,
}) => {
  const {
    contacts,
    lastSyncedAt,
    isLoadingContacts,
    createHubspotContact,
    updateHubspotContact,
    isCreatedContactLoading,
    isUpdatedContactLoading,
    refetchContacts,
    fetchContactsError,
    createContactError,
    updateContactError,
    canCreate,
  } = useHubspotContact({
    appId,
    contact,
  });

  return (
    <Profile
      contacts={contacts}
      lastSyncedAt={lastSyncedAt}
      isLoading={isLoadingContacts}
      isCreating={isCreatedContactLoading}
      isUpdating={isUpdatedContactLoading}
      onCreate={createHubspotContact}
      onUpdate={updateHubspotContact}
      type={HubspotObjectTypes.Contacts}
      typeName="Contact"
      hubspotId={hubspotId}
      refetch={refetchContacts}
      fetchError={fetchContactsError}
      createError={createContactError}
      updateError={updateContactError}
      isCreatingDisabled={!canCreate}
      identifier="email"
      showSettings={showSettings}
      showConnect={showConnect}
      objectType={objectType}
    />
  );
};

export const HubspotProfileContainer: React.FC<{
  appId: string;
  contact: IHSContact;
  showSettings?: boolean;
  showConnect?: boolean;
  objectType: AppObjectType;
}> = ({
  appId,
  contact,
  showSettings = true,
  showConnect = true,
  objectType,
}) => {
  const { setting, hasHubspotInstalled } = useHubspotSettings();

  if (!hasHubspotInstalled) return <UninstalledCard />;

  return (
    <LoadProfile
      appId={appId}
      contact={contact}
      hubspotId={setting.hubspotId}
      showSettings={showSettings}
      showConnect={showConnect}
      objectType={objectType}
    />
  );
};
