import { Link } from "react-router-dom";
import React from "react";
import { IContactGroup } from "core/types/Contact";
import { AppObjectSlugs } from "core/types/AppObject";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { groupNameFromTraits } from "core/helpers/groupNameFromTraits";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Box, Flex, Text } from "@chakra-ui/react";

interface ICompaniesProps {
  groups: IContactGroup[];
  name: string;
  slug: AppObjectSlugs;
}

export const Companies: React.FC<ICompaniesProps> = ({
  groups,
  name,
  slug = "group",
}) => {
  const currentApp = useCurrentApp();

  return (
    <Box>
      <Flex mb={2} align="center" justify="space-between">
        <Text fontWeight="medium">{name}</Text>
      </Flex>
      <>
        {groups.map((group) => {
          return (
            <Link
              key={group.groupId}
              to={`/a/${currentApp.id}/${slug}/${group.groupId}/`}
              style={{ width: "100%" }}
            >
              <Flex align="center" mb={2}>
                <CompanyAvatar group={{ id: group.groupId }} size="sm" />
                <Text ml={3} maxW={"100%"} noOfLines={1} fontWeight="medium">
                  {groupNameFromTraits(group) || group.groupId}
                </Text>
              </Flex>
            </Link>
          );
        })}
      </>
    </Box>
  );
};
