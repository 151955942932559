import "focus-visible/dist/focus-visible";
import { BrowserRouter } from "react-router-dom";
import { VerifyEmail } from "modules/VerifyEmail";
import { useUserAuth } from "core/hooks/useUserAuth";
import juneTheme from "core/design-system/constants/theme";
import { UpdateNotification } from "Components/UpdateNotification";
import { ChakraProvider, Spinner } from "@chakra-ui/react";
import UnauthenticatedApp from "./UnauthenticatedApp";
import { isDev } from "./constants/environment";
import AuthenticatedApp from "./AuthenticatedApp";

const App = () => {
  const { currentUser, isLoading } = useUserAuth();

  return (
    <ChakraProvider theme={juneTheme}>
      {!isDev && <UpdateNotification />}
      <BrowserRouter>
        <>
          {isLoading ? (
            <div className="flex h-screen w-screen items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              {currentUser ? (
                <>
                  {currentUser.confirmed ? (
                    <AuthenticatedApp user={currentUser} />
                  ) : (
                    <VerifyEmail email={currentUser.email} />
                  )}
                </>
              ) : (
                <UnauthenticatedApp />
              )}
            </>
          )}
        </>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
