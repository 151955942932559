import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import DefaultHeader from "modules/Pages/DefaultHeader";
import { GettingStartedBanner } from "modules/Home/GettingStartedBanner";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { useEventList } from "core/hooks/useEventList";
import { Ping } from "core/design-system/components/Ping";
import { PAGE, TRACK } from "core/constants/report-setup";
import { Tab } from "core/components/Tabs/Tab";
import { SearchBar } from "core/components/SearchBar";
import { PageContainer } from "core/components/PageContainer";
import { ErrorMessage } from "core/components/List/ErrorMessage";
import List from "core/components/List";
import { CreateEventModal } from "core/components/Event/CreateEventModal";
import { Button, Flex, Stack } from "@chakra-ui/react";

const SECTION = {
  name: "Events",
  title: "Events",
  description: "The events that you have sent",
  howItWorksArticle:
    "https://help.june.so/en/articles/5095720-track-in-product-basic-behaviors",
  howItWorksArticleId: "5095720",
  sectionRight: () => (
    <div className="flex items-center gap-2">
      <Button px={5} as={RouterLink} to="./feed" leftIcon={<Ping />}>
        View live feed
      </Button>
      <CreateEventModal />
    </div>
  ),
};

function Events() {
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();
  const [eventType, setEventType] = useState<number>(TRACK);
  const {
    listConfig,
    events,
    pagy,
    isLoading,
    error,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    setPage,
    searchQuery,
    setSearchQuery,
  } = useEventList({ eventType });

  return (
    <PageContainer minW="container.lg" maxW="full">
      <Stack justify="flex-start" align="flex-start">
        <Flex
          opacity={shouldShowGettingStartedBanner ? 0.4 : undefined}
          filter={shouldShowGettingStartedBanner ? "grayscale(0.8)" : undefined}
          w="full"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <DefaultHeader w="inherit" section={SECTION} />
        </Flex>
        <Flex mt={2}>
          <Tab
            key="events"
            isSelected={eventType === TRACK}
            onTabChange={() => {
              setPage(1);
              setSearchQuery("");
              setEventType(TRACK);
            }}
            pl={0}
          >
            <div className="flex gap-1">Events</div>
          </Tab>
          <Tab
            key="pageviews"
            isSelected={eventType === PAGE}
            onTabChange={() => {
              setPage(1);
              setSearchQuery("");
              setEventType(PAGE);
            }}
            pl={0}
          >
            <div className="flex gap-1">Pageviews</div>
          </Tab>
        </Flex>
      </Stack>
      {error ? (
        <ErrorMessage />
      ) : (
        <>
          <div className="flex pb-10">
            <Flex
              my={3}
              w="full"
              direction="column"
              opacity={shouldShowGettingStartedBanner ? 0.4 : undefined}
              filter={
                shouldShowGettingStartedBanner ? "grayscale(0.8)" : undefined
              }
            >
              <SearchBar
                mb={4}
                searchQuery={searchQuery}
                onSearch={setSearchQuery}
                placeholder="Search your events by name..."
              />
              <List
                config={listConfig}
                isLoading={isLoading}
                rows={events as any[]}
                pagy={pagy}
                pagination={{
                  currentPage,
                  previousPage,
                  nextPage,
                }}
                hideAvatar={true}
                skipPagination={skipPagination}
              />
            </Flex>
          </div>
          {shouldShowGettingStartedBanner && <GettingStartedBanner />}
        </>
      )}
    </PageContainer>
  );
}

export default Events;
