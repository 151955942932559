import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ReactComponentLike } from "prop-types";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { IQuery } from "core/types/Query";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { isUserReportLevel } from "core/modules/reports/utils";
import { GroupBanner } from "core/modules/reports/GroupBanner";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useAppObjects } from "core/hooks/useAppObjects";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import { ReportInsight } from "core/components/Reportinsight";
import { EventCodeSnippet } from "core/components/Event/CodeSnippet";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { Divider, Grid } from "@chakra-ui/react";
import { IViewInsight } from "core/models/viewInsights";

export const InsightsContainer: React.FC<{
  report: IReport;
  templateConfig: ITemplateConfig;
  viewInsight?: IViewInsight;
  insightConfig?: IInsightSection;
  appObject?: IAppObject;
}> = ({ report, templateConfig, viewInsight, insightConfig, appObject }) => {
  const { appId } = useParams();
  const { serializeKey, hasBeenPersisted } = useReportSetup();
  const groupId = useQueryParam("groupId");
  const groupType = useQueryParam("groupType");
  const isUserLevel = appObject
    ? appObject.objectType === AppObjectType.User
    : isUserReportLevel();
  const { setActiveAppObject } = useAppObjects();

  useEffect(() => {
    if (appObject) {
      setActiveAppObject(appObject);
    }
  }, [appObject]);

  let sections = templateConfig.sections;
  if (sections?.user && sections?.company) {
    sections = !isUserLevel ? sections.company : sections.user;
  }

  return (
    <>
      <div className="mb-4">
        {groupId && groupType && (
          <GroupBanner groupId={groupId} groupType={groupType} />
        )}
      </div>
      {report.reportType === FEATURE_RELEASE &&
        report?.config?.events?.[0]?.name && (
          <EventCodeSnippet
            entity="feature"
            eventName={report?.config?.events?.[0]?.name}
          />
        )}
      <Grid
        data-testid="report-insights-container"
        templateColumns="repeat(2, 1fr)"
        gap={4}
        minW="550px"
      >
        {(insightConfig ? [insightConfig.section] : sections).map(
          (Section: ReactComponentLike) => (
            <Section
              key={serializeKey}
              report={report}
              appId={appId}
              config={templateConfig}
              reportId={report.id}
              previewMode={false}
              hasBeenPersisted={hasBeenPersisted}
              unit={appObject && appObject.pluralName.toLowerCase()}
              viewInsight={viewInsight}
              showYAxis
            />
          ),
        )}
      </Grid>
      {!insightConfig && (
        <div className="my-5 flex w-full items-center gap-2">
          <div className="w-full">
            <Divider />
          </div>
          <div>
            <SparklesIcon className="h-4 text-gray-500" />
          </div>
          <div className="w-full">
            <Divider />
          </div>
        </div>
      )}
      {!insightConfig && (
        <div
          data-testid="report-ai-insights"
          className="mb-8 flex flex-col gap-4"
        >
          {report?.insights?.map((insight: IQuery) => (
            <ReportInsight query={insight} />
          ))}
        </div>
      )}
    </>
  );
};
