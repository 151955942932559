import { useParams } from "react-router-dom";
import React from "react";
import { inIframe } from "helpers/iframe";
import { cx } from "helpers/cx";
import { useGetViewInsightQuery } from "core/models/viewInsights";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { PublicResult } from "core/components/AI/Graph/PublicResult";
import Loader from "Components/Loader";
import ErrorPage from "Components/Error/Page";
import { Button, Container } from "@chakra-ui/react";

function SignUpBanner() {
  if (inIframe()) return null;

  return (
    <div
      className="flex w-[905px] items-start justify-center"
      data-id-public-banner
      data-testid="public-banner"
    >
      <div className="mb-4 flex w-full items-center justify-between rounded-lg border border-gray-50 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center">
          <div className="flex">
            <CompanyJuneLogoIcon h={6} w={6} color="primary" />
          </div>
          <p className="ml-4 text-sm font-medium">
            Welcome to June! Create an account to edit and collaborate on this
            dashboard.
          </p>
        </div>
        <div className="flex items-center">
          <Button as="a" href="https://analytics.june.so/login" fontSize="sm">
            Log in
          </Button>
          <Button
            ml={4}
            as="a"
            href="https://analytics.june.so/start"
            fontSize="sm"
            colorScheme="purple"
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
}

function PublicQueryRoute() {
  const { appId, viewId, viewInsightId, groupId, sharingSecretToken } =
    useParams<{
      [key: string]: string;
    }>() as {
      appId: string;
      viewId: string;
      viewInsightId: string;
      groupId: string;
      sharingSecretToken: string;
    };

  const {
    data: viewInsight,
    isLoading,
    isFetching,
    error,
  } = useGetViewInsightQuery({
    appId: Number(appId),
    id: Number(viewInsightId),
    viewId: Number(viewId),
    sharingSecretToken,
    groupId,
  });

  if (isLoading || isFetching) return <Loader />;
  if (error) {
    return (
      <ErrorPage
        title="You don't have permissions to view this query"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  if (viewInsight && viewInsight?.query)
    return (
      <div
        data-testid="page-container"
        className={cx(
          "h-full min-h-[100vh] w-full min-w-[100vw] overflow-hidden bg-gray-50",
        )}
      >
        <div className="h-[99.9vh] p-2">
          <div className={cx("h-full w-full overflow-auto rounded-lg py-8")}>
            <Container
              px="60px"
              mx="auto"
              w={inIframe() ? "100%" : "auto"}
              maxW={inIframe() ? "100%" : "container.lg"}
              minW={inIframe() ? "auto" : "container.lg"}
            >
              <SignUpBanner />
              {viewInsight && (
                <PublicResult
                  viewInsight={viewInsight}
                  query={viewInsight.query}
                />
              )}
            </Container>
          </div>
        </div>
      </div>
    );

  return <ErrorPage />;
}

export default PublicQueryRoute;
