import { useParams } from "react-router-dom";
import { useState } from "react";
import { IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { useTraits } from "core/hooks/useTraits";
import { useToast } from "core/hooks/useToast";
import {
  IDataMapping,
  ITempDataMapping,
  useDataMappings,
} from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { BoltIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ComponentDefaultProps,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

interface ICreateTraitModal extends ComponentDefaultProps {
  dataMapping?: IDataMapping | ITempDataMapping;
  integration: Integration;
  appObject: IAppObject;
}

export const CreateTraitModal: React.FC<ICreateTraitModal> = ({
  dataMapping,
  integration,
  appObject,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { appId } = useParams();
  const { updateMapping } = useDataMapping({
    appId: Number(appId),
    integration,
    appObject,
  });
  const { dataMappings } = useDataMappings({
    appId: Number(appId),
    integration,
    appObject,
  });
  const { traits } = useTraits(appObject.objectType);
  const usedTraits = dataMappings.map((dm) => dm.source);
  const [traitName, setTraitName] = useState("");
  const traitAlreadyExists = usedTraits.includes(traitName);

  function onSave() {
    if (!dataMapping || !dataMapping.id || !dataMapping.destination) return;

    updateMapping({
      id: dataMapping.id,
      source: traitName,
      destination: dataMapping.destination,
    });
    toast({
      title: "Updated successfully",
    });
  }

  return (
    <div className="max-w-full">
      <Button
        variant="ghost"
        size="sm"
        borderRadius="lg"
        bg="gray.50"
        color={dataMapping?.source ? "black" : "red.500"}
        disabled={!traits?.length}
        maxW="full"
        minW="88px"
        onClick={() => {
          if (dataMapping?.source) return;
          onOpen();
        }}
      >
        <div className="flex w-full items-center justify-center gap-1">
          <p className="truncate">{dataMapping?.source || "Create trait"}</p>
          {dataMapping?.isComputed && (
            <Tooltip label="Computed trait" hasArrow placement="right">
              <BoltIcon className="h-3 w-3 text-purple-500" />
            </Tooltip>
          )}
        </div>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p className="text-lg font-medium leading-6 text-gray-900">
              Create trait
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mb-2 flex items-center rounded-lg bg-purple-50 p-2">
              <InformationCircleIcon className="mr-2 h-4 text-purple-500" />
              <p className="text-sm text-purple-500">
                Choose a trait name that doesn't already exist.
              </p>
            </div>
            <div className="mb-4 mt-4 block">
              <Input
                placeholder="Trait"
                borderRadius="md"
                focusBorderColor="purple.500"
                type="text"
                name="trait"
                size="sm"
                onChange={(e) => setTraitName(e.target.value)}
              />
            </div>
            <div className="mt-4 w-full">
              {traitAlreadyExists && (
                <p className="rounded-md bg-red-50 px-2 py-1 text-center text-sm text-red-500">
                  This trait already exists.
                </p>
              )}
            </div>
            <div className="my-4 flex justify-end">
              <Button
                colorScheme="purple"
                onClick={onSave}
                isDisabled={traitName === "" || traitAlreadyExists}
              >
                Save
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
