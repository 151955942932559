import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import NewActiveUsersLineChart from "core/modules/reports/report-types/ActiveUsers/NewActiveUsersLineChart";
import { dauMockData } from "core/modules/reports/report-types/ActiveUsers/mockData";
import { useQueryParam } from "core/hooks/useQueryParam";
import {
  DAU_OPTIONS,
  INTERVAL_TYPES,
  SEVEN_DAYS,
  getTimeRangeFromValue,
} from "core/constants/timerange";
import { LineContainer } from "core/components/LineContainer";

export const DauContainer: React.FC<IReportInsight> = ({
  config,
  additionalConfigProps,
  showInProgress,
  viewInsight,
  ...props
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "dau",
  ) as IInsightSection;

  const timerangeParam = useQueryParam("insight_timerange");
  const initialTimeRange = viewInsight?.timerangeValue
    ? getTimeRangeFromValue(viewInsight.timerangeValue)
    : timerangeParam
      ? getTimeRangeFromValue(parseInt(timerangeParam, 10))
      : SEVEN_DAYS;

  return (
    <LineContainer
      LineChart={NewActiveUsersLineChart}
      insight={insight}
      initialTimeRange={initialTimeRange.value}
      timeRangeOptions={DAU_OPTIONS}
      intervalType={INTERVAL_TYPES.DAY}
      keyAccessor={"data"}
      intervalPeriod={"ONE_DAY"}
      mockData={dauMockData}
      config={config}
      additionalConfigProps={additionalConfigProps}
      showInProgress={showInProgress}
      {...props}
    />
  );
};
