import { PillTabs, PillTabTrigger } from "Components/ui/pill-tabs";
import { useState } from "react";
import { ObjectFeedList } from "./Sections/Feed/List";
import { ObjectOverviewSection } from "./Overview";
import { HomeIcon, QueueListIcon, UsersIcon } from "@heroicons/react/16/solid";
import { cx } from "helpers/cx";
import { ObjectUsersList } from "./Sections/Users/List";
import { EditingViewProvider } from "core/contexts/EditingViewContext";
import { ObjectContentActions } from "./ContentActions";
import { AppObjectType } from "core/types/AppObject";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCompanyView } from "core/hooks/useCompanyView";
import { useUpdateViewMutation } from "core/models/views";

export const ObjectContentSection: React.FC = () => {
  const [value, setValue] = useState("overview");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);

  const { activeAppObject } = useAppObjects();
  const isUserProfile = activeAppObject?.objectType === AppObjectType.User;

  const { view, isLoadingView } = useCompanyView();
  const [updateView, { isLoading: isUpdatingView }] = useUpdateViewMutation();

  return (
    <EditingViewProvider>
      <div className="flex flex-col">
        <div
          id="overview-nav"
          className="sticky top-0 z-20 border-b border-gray-200 bg-white"
        >
          <div className="flex items-center justify-between px-3 py-2">
            <div className="text-sm">
              <PillTabs
                value={value}
                onValueChange={setValue}
                className="flex gap-2"
              >
                <PillTabTrigger value="overview">
                  <div className="flex items-center gap-1">
                    <HomeIcon
                      className={cx(
                        "size-4 ",
                        value === "overview" ? "text-black" : "text-gray-600",
                      )}
                    />{" "}
                    Overview
                  </div>
                </PillTabTrigger>
                {!isUserProfile && (
                  <PillTabTrigger value="users">
                    <div className="flex items-center gap-1">
                      <UsersIcon
                        className={cx(
                          "size-4 ",
                          value === "users" ? "text-black" : "text-gray-600",
                        )}
                      />
                      Users
                    </div>
                  </PillTabTrigger>
                )}
                <PillTabTrigger value="feed">
                  <div className="flex items-center gap-1">
                    <QueueListIcon
                      className={cx(
                        "size-4 ",
                        value === "feed" ? "text-black" : "text-gray-600",
                      )}
                    />
                    Feed
                  </div>
                </PillTabTrigger>
              </PillTabs>
            </div>
            {view && !isUserProfile && (
              <div className="flex items-center gap-1">
                <ObjectContentActions
                  view={view}
                  updateView={updateView}
                  isLoadingView={isLoadingView}
                  isUpdatingView={isUpdatingView}
                  showAddModal={showAddModal}
                  setShowAddModal={setShowAddModal}
                  showSQLModal={showSQLModal}
                  setShowSQLModal={setShowSQLModal}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={cx(
            "h-full w-full",
            value === "users" ? "bg-white" : "px-3 pb-10 pt-2",
          )}
        >
          {view && value === "overview" && (
            <ObjectOverviewSection
              view={view}
              isLoadingView={isLoadingView}
              showAddModal={showAddModal}
              setShowAddModal={setShowAddModal}
              showSQLModal={showSQLModal}
              setShowSQLModal={setShowSQLModal}
              showAudienceModal={false}
              setShowAudienceModal={() => {}}
            />
          )}
          {value === "users" && (
            <ObjectUsersList
              useInternalUsers={view?.useInternalUsers || false}
            />
          )}
          {value === "feed" && (
            <ObjectFeedList
              useInternalUsers={view?.useInternalUsers || false}
            />
          )}
        </div>
      </div>
    </EditingViewProvider>
  );
};
