import { useState } from "react";
import { Check } from "lucide-react";
import { IFilter } from "core/types/Filters.d";
import { useGetUsersQuery } from "core/models/users";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { getValueText } from "core/helpers/audience/traitValue";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button } from "@chakra-ui/react";

interface WorkspaceMemberValuesProps {
  filter: IFilter;
  onSelectValue: (value: string) => void;
  onDone: () => void;
}

export const WorkspaceMemberValues: React.FC<WorkspaceMemberValuesProps> = ({
  filter,
  onSelectValue,
  onDone,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { id: appId } = useCurrentApp();
  const { data: users, isLoading } = useGetUsersQuery({ appId });
  const selectedValues = Array.isArray(filter.body.value)
    ? filter.body.value
    : [];

  const filteredUsers = users?.filter((user) =>
    user.email.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const selectedUsers = users?.filter((user) =>
    selectedValues.includes(user.id.toString()),
  );

  if (isLoading) {
    return (
      <div className="flex items-center gap-1 py-1 text-sm">
        <div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
      </div>
    );
  }

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <PopoverButton>
            <div className="flex items-center gap-2">
              {selectedUsers && selectedUsers.length > 0 ? (
                <div className="ml-1 flex items-center gap-1 text-sm font-semibold">
                  {getValueText(selectedUsers.map((user) => user.email))}
                </div>
              ) : (
                <span className="text-sm text-gray-500">Select users...</span>
              )}
            </div>
          </PopoverButton>
          <PopoverPanel>
            <>
              <Lifecycle
                onUnmount={() => {
                  setSearchValue("");
                  onDone();
                }}
              />
              <Command>
                <CommandInput
                  placeholder="Search users..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <CommandList>
                  {isLoading ? (
                    <span className="px-2 py-1 sm:leading-4">Loading...</span>
                  ) : (
                    <>
                      {filteredUsers?.length === 0 && (
                        <span className="px-2 py-1 sm:leading-4">
                          No users found
                        </span>
                      )}
                      <div className="flex max-h-[220px] flex-col overflow-y-scroll">
                        {filteredUsers?.map((user) => (
                          <CommandOption
                            key={user.id.toString()}
                            value={() => {
                              onSelectValue(user.id.toString());
                            }}
                            rightIcon={
                              selectedValues.includes(user.id.toString()) && (
                                <Check className="h-4 w-4 text-gray-800" />
                              )
                            }
                          >
                            <div className="flex items-center gap-2">
                              <span className="line-clamp-1 text-ellipsis sm:leading-4">
                                {user.email}
                              </span>
                            </div>
                          </CommandOption>
                        ))}
                      </div>
                    </>
                  )}
                </CommandList>
                <div className="relative w-full border-t border-gray-200 bg-white pt-2">
                  <Button
                    width="100%"
                    onClick={() => {
                      onDone();
                      close();
                    }}
                  >
                    Done
                  </Button>
                </div>
              </Command>
            </>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
