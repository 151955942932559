import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { DefaultHeader } from "modules/Pages/DefaultHeader";
import { Plan } from "core/types/App";
import { usePaywall } from "core/hooks/usePaywall";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useBilling } from "core/hooks/useBilling";
import { baseUrl } from "core/constants/paywall";
import { UNIFIED_PRICING, USAGE_BASED_PRICING } from "core/constants/features";
import { DEMO_APP_ID } from "core/constants/appIds";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { MauLimit } from "core/components/Paywall/MauLimit";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  ComponentDefaultProps,
  Divider,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const SECTION = {
  name: "Usage & Billing",
  title: "Usage & Billing",
  description: `Your workspace's usage and billing information`,
};

const BillingRow: React.FC<
  {
    name: string;
    value: string;
    isBold?: boolean;
    tooltip?: string;
    valueTooltip?: string;
  } & ComponentDefaultProps
> = ({ name, value, isBold, tooltip, valueTooltip, children, ...props }) => {
  return (
    <Flex justifyContent="space-between" {...props}>
      <Flex align="center" gap={2}>
        <Text
          fontSize="sm"
          color={isBold ? "black" : "gray.600"}
          fontWeight={isBold ? "bold" : "normal"}
          textTransform="capitalize"
        >
          {name} {children}
        </Text>
        {tooltip && (
          <Tooltip label={tooltip} placement="right" hasArrow>
            <QuestionMarkCircleIcon className="h-4" />
          </Tooltip>
        )}
      </Flex>
      <Flex align="center" gap={2}>
        <Text
          fontSize="sm"
          color={isBold ? "black" : "gray.800"}
          fontWeight={isBold ? "bold" : "normal"}
        >
          {value}
        </Text>
        {valueTooltip && (
          <Tooltip label={valueTooltip} placement="top" hasArrow>
            <QuestionMarkCircleIcon className="h-4" />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

const PLAN_COSTS = {
  [Plan.Free]: 0,
  [Plan.Growth]: 149,
  [Plan.Pro]: 349,
} as { [key: string]: number };

export const Billing: React.FC = () => {
  const currentApp = useCurrentApp();
  const navigate = useNavigate();
  const hasNewBilling = currentApp?.features?.includes(USAGE_BASED_PRICING);
  const hasUnifiedPricing = currentApp?.features?.includes(UNIFIED_PRICING);
  const {
    userCount,
    MAULimitForPlan,
    additionalMAUs,
    startDate,
    endDate,
    isGuardrail,
  } = useMauThreshold();
  const { isFree, isTrialing, plan } = usePaywall();
  const totalAmountDue = (isFree ? 0 : additionalMAUs * 15) + PLAN_COSTS[plan];
  const { redirectToBillingPortal, isStripeCustomer } = useBilling();
  const redirectUrl = `${baseUrl}/a/${currentApp.id}/settings/billing`;

  useEffect(() => {
    if (!hasNewBilling) {
      navigate(`/a/${currentApp.id}/home`);
    }
  }, [navigate, hasNewBilling, currentApp.id]);

  if (!hasNewBilling) return null;

  return (
    <Box>
      <Flex align="center" justifyContent="space-between">
        <div className="flex w-full justify-between">
          <div className="flex">
            <DefaultHeader section={SECTION} />
          </div>
          <div className="flex items-center gap-2">
            <PaywallModal
              redirectUrl={redirectUrl}
              showIcon={false}
              isCloseable
            >
              {(paywallModal: IPaywallModalChildren) => (
                <>
                  {!isTrialing && (
                    <paywallModal.UpgradeButton
                      tooltipText="Increase your monthly active user threshold and unlock premium features"
                      colorScheme="purple"
                      isDisabled={currentApp.id === DEMO_APP_ID}
                    >
                      {plan === Plan.Pro ? "Manage subscriptions" : "Upgrade"}
                    </paywallModal.UpgradeButton>
                  )}
                  <paywallModal.Modal />
                </>
              )}
            </PaywallModal>
            {isStripeCustomer && (
              <div className="flex">
                <Button
                  variant="outline"
                  onClick={redirectToBillingPortal}
                  rightIcon={<ExternalLinkIcon />}
                  data-testid="manage-billing-button"
                >
                  Manage billing
                </Button>
              </div>
            )}
            <div className="flex">
              <Button onClick={() => window.Intercom("show")} variant="outline">
                Write to us
              </Button>
            </div>
            {hasUnifiedPricing && isTrialing && (
              <div className="flex">
                <Button
                  as="a"
                  href="https://www.june.so/pricing"
                  target="_blank"
                  colorScheme="purple"
                >
                  View pricing
                </Button>
              </div>
            )}
          </div>
        </div>
      </Flex>

      <Flex
        w="full"
        direction="column"
        my={4}
        gridGap={6}
        data-testid="billing-container"
      >
        <Flex direction="column">
          <Text fontSize="sm" fontWeight="bold">
            Current period
          </Text>
          <Text fontSize="sm">
            {moment(startDate).format(`DD MMM, YYYY`)} -{" "}
            {moment(endDate).format(`DD MMM, YYYY`)}
          </Text>
        </Flex>
        <Flex direction="column">
          <Text fontSize="sm" fontWeight="bold">
            Overview
          </Text>
          <Text mb={2} fontSize="sm">
            An overview of your usage and costs
          </Text>
          <Flex
            direction="column"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            mt={4}
          >
            <Flex p={4} direction="column" gridGap={4}>
              <MauLimit overrideBarColor="purple" isUnlimited />
              <BillingRow
                data-testid="billing-plan"
                name={
                  hasUnifiedPricing
                    ? "All in one subscription"
                    : `${plan.split("_plan").join("")} plan ${
                        isTrialing ? "trial" : ""
                      }`
                }
                value={
                  isTrialing
                    ? `Free for ${currentApp.trialDaysRemaining} day${currentApp.trialDaysRemaining > 1 ? "s" : ""}`
                    : `$${PLAN_COSTS[plan]} / month`
                }
                valueTooltip={isTrialing ? `For pricing, talk to sales` : ""}
              >
                {isTrialing && (
                  <p className="ml-2 inline rounded-sm bg-orange-50 px-2 py-1 text-xs font-medium text-orange-400">
                    {currentApp.trialDaysRemaining === 0
                      ? "until today"
                      : `${currentApp.trialDaysRemaining} day${currentApp.trialDaysRemaining > 1 ? "s" : ""} left`}
                  </p>
                )}
              </BillingRow>
              <Divider />
              <BillingRow
                data-testid="billing-mau-included"
                name="MAU included"
                value={MAULimitForPlan.toLocaleString()}
              />
              {!isFree && (
                <>
                  <Divider />
                  <BillingRow
                    data-testid="billing-additional-mau-used"
                    name={`Additional MAU used ${isGuardrail ? "with guardrail*" : ""}`}
                    tooltip={`The ${plan} plan includes ${MAULimitForPlan.toLocaleString()} MAUs. You've used an extra ${(userCount >
                    MAULimitForPlan
                      ? userCount - MAULimitForPlan
                      : 0
                    ).toLocaleString()} MAUs for the given period. We charge $15 for every additional 1,000 MAUs.`}
                    value={`${(userCount > MAULimitForPlan
                      ? userCount - MAULimitForPlan
                      : 0
                    ).toLocaleString()}`}
                  />
                </>
              )}
              {!isFree && (
                <>
                  <Divider />
                  <BillingRow
                    data-testid="billing-additional-mau-cost"
                    name="Additional MAUs"
                    value={`${additionalMAUs} x $15`}
                  />
                </>
              )}
              {!isTrialing && (
                <>
                  <Divider />
                  <BillingRow
                    data-testid="billing-total-cost"
                    isBold
                    name="Current total this month"
                    value={`$${totalAmountDue.toLocaleString()}`}
                  />
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" gridGap={5}>
          <Flex
            direction="column"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            bg="gray.50"
            p={4}
            gap={4}
          >
            <Flex direction="column">
              <Text mb={1} fontSize="sm" fontWeight="semibold">
                How we calculate your MAU (Monthly Active Users){" "}
              </Text>
              <Text fontSize="sm" color="gray.700">
                Monthly active users are calculated by looking at the count of
                unique users who have triggered an event or page view between
                the {moment(startDate).format(`Do MMM, YYYY`)} and the{" "}
                {moment(endDate).format(`Do MMM, YYYY`)}.
              </Text>
            </Flex>
            <Flex direction="column">
              <Text mb={1} fontSize="sm" fontWeight="semibold">
                Guardrail*
              </Text>
              <Text fontSize="sm" color="gray.700">
                The Growth plan includes up to 1,000 track events per MAU. The
                vast majority of June customers use well under these limits.
                Exceeding these limits, we will calculate your MAU based on the
                monthly tracked events (total number of events / 1,000).
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
