import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { getAppObject } from "core/modules/appObjects/utils";
import { selector as appObjectsSelector } from "core/models/appObject/selectors";
import {
  setActiveAppObject,
  setAppObjects,
} from "core/models/appObject/actions";

export const USER_APP_OBJECT = {
  id: 1,
  appId: 1,
  singularName: "User",
  pluralName: "Users",
  slug: "user",
  objectType: AppObjectType.User,
  isEnabled: true,
};

export const COMPANY_APP_OBJECT = {
  id: 2,
  appId: 1,
  singularName: "Company",
  pluralName: "Companies",
  slug: "company",
  objectType: AppObjectType.Company,
  isEnabled: true,
};

export const useAppObjects = (
  objectType?: AppObjectType,
): {
  setAppObjects: (appObjects: IAppObject[]) => void;
  appObjects: IAppObject[];
  activeAppObject: IAppObject | null;
  setActiveAppObject: (appObjects: IAppObject) => void;
  userAppObject: IAppObject;
  companyAppObject: IAppObject;
  groupAppObject: IAppObject;
  appObject?: IAppObject;
} => {
  const dispatch = useDispatch();
  const { activeAppObject, appObjects } = useSelector(appObjectsSelector);
  const userAppObject = useMemo(
    () => getAppObject(appObjects, "user"),
    [appObjects],
  );
  const companyAppObject = useMemo(
    () => getAppObject(appObjects, "company"),
    [appObjects],
  );
  const groupAppObject = useMemo(
    () => getAppObject(appObjects, "group"),
    [appObjects],
  );

  function setActiveObject(appObject: IAppObject) {
    dispatch(setActiveAppObject({ activeAppObject: appObject }));
  }

  function setObjects(appObjects: IAppObject[]) {
    dispatch(setAppObjects({ appObjects }));
  }

  const objectTypeToAppObject = {
    [AppObjectType.User]: userAppObject,
    [AppObjectType.Company]: companyAppObject,
    [AppObjectType.Group]: groupAppObject,
  };

  return {
    setAppObjects: setObjects,
    appObjects,
    activeAppObject,
    setActiveAppObject: setActiveObject,
    userAppObject,
    companyAppObject,
    groupAppObject,
    appObject: objectType && objectTypeToAppObject[objectType],
  };
};
