import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface ITraitDebuggerData {
  totalCount: number;
  asString: {
    valueCount: number;
  };
  asNumber: {
    valueCount: number;
  };
  asBoolean: {
    valueCount: number;
  };
  asDate: {
    valueCount: number;
  };
}

export const traitDebuggerApi = createApi({
  baseQuery,
  reducerPath: "traitDebuggerApi",
  tagTypes: ["TraitDebugger"],
  endpoints: (builder) => ({
    getTraitDebuggerData: builder.query<
      ITraitDebuggerData,
      { appId: number; traitId: number }
    >({
      query: ({ appId, traitId }) => ({
        url: `/traits/debugger`,
        params: { app_id: appId, id: traitId },
      }),
      providesTags: ["TraitDebugger"],
      transformResponse: (response: any): ITraitDebuggerData =>
        humps.camelizeKeys(response) as ITraitDebuggerData,
    }),
  }),
});

export const { useGetTraitDebuggerDataQuery } = traitDebuggerApi;
