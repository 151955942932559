import { useMemo, useState } from "react";
import { TableCell } from "core/components/AI/Graph/DataTable/TableCell";
import { EmptyState } from "core/components/AI/Graph/DataTable/EmptyState";
import { CountDisplay } from "core/components/AI/Graph/DataTable/CountDisplay";
import { Cell } from "core/components/AI/Graph/DataTable/Cell";
import {
  IDataTable,
  TextAlign,
} from "core/components/AI/Graph//DataTable/types";
import { Button } from "Components/ui/button";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

export const DataTable: React.FC<IDataTable> = ({
  data,
  columns,
  page,
  setPage,
  hasMore,
}) => {
  const [scrollPos, setScrollPos] = useState(0);
  const align = columns?.length === 2 ? TextAlign.RIGHT : TextAlign.LEFT;

  const tableColumns = useMemo(
    () =>
      columns?.map((col, index) => ({
        id: col.name,
        accessorKey: col.name,
        header: col.name,
        cell: ({ getValue }: any) => (
          <Cell
            value={getValue()}
            columnName={col.name}
            index={index}
            align={align}
          />
        ),
      })) || [],
    [columns, align],
  );

  const table = useReactTable({
    data: data || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!data || !columns) return null;

  const isCount =
    (columns.length === 1 && columns[0].name.includes(`count`)) ||
    columns[0].name.toLowerCase().includes(`uniqexact`);

  if (isCount) {
    return <CountDisplay value={data[0][columns[0].name]} />;
  }

  if (data.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="flex flex-col">
      <div
        className="w-full overflow-x-auto rounded-lg bg-white"
        onScroll={(e) => setScrollPos(e.currentTarget.scrollLeft)}
      >
        <table className="relative min-w-full divide-y divide-gray-100">
          <thead className="sticky top-0 z-10 border-b border-gray-100 bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="divide-x divide-gray-100">
                {headerGroup.headers.map((header, index) => (
                  <TableCell
                    key={header.id}
                    isFirstColumn={index === 0}
                    showShadow={scrollPos > 0}
                    align={align}
                    isHeader
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-100 bg-white">
            {table.getRowModel().rows.map((row, rowIndex) => (
              <tr key={row.id} className="divide-x divide-gray-100">
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    isFirstColumn={index === 0}
                    showShadow={scrollPos > 0}
                    align={align}
                    isLastRow={rowIndex === table.getRowModel().rows.length - 1}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {hasMore && (
        <div className="my-2 flex justify-center">
          <Button
            size="sm"
            variant="outline"
            onClick={() => setPage?.((page || 1) + 1)}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};
