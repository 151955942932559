import moment from "moment";
import { cx } from "helpers/cx";
import { ComparisonType, IFilter } from "core/types/Filters.d";
import { DataTypeString } from "core/models/traits";
import { getValueText } from "core/helpers/audience/traitValue";
import {
  HAS_ANY_VALUE,
  IS_UNKNOWN,
} from "core/constants/traitFilterComponents";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, Tooltip } from "@chakra-ui/react";
import { WorkspaceMemberValues } from "./WorkspaceMemberValues";
import { ValueSelection } from "./ValueSelection";
import { ValueInput } from "./ValueInput";

export const getMaxWidth = (truncate: boolean) => {
  if (!truncate) return "100%";

  const width = window.innerWidth;
  if (width < 1600) {
    return "120px";
  } else if (width >= 1600 && width < 1700) {
    return "220px";
  } else {
    return "100%";
  }
};

export const Value: React.FC<{
  value: string | string[];
  setValue: (value: string | string[]) => void;
  comparisonType: ComparisonType;
  filter: IFilter;
  setFilter: (filter: IFilter) => void;
  onSaveFilter: (filter: IFilter) => void;
  onChangeTraitValue: (value: string) => void;
  truncate?: boolean;
  dataType?: DataTypeString;
}> = ({
  value,
  setValue,
  comparisonType,
  onChangeTraitValue,
  filter,
  setFilter,
  onSaveFilter,
  truncate = false,
  dataType,
}) => {
  if ([HAS_ANY_VALUE, IS_UNKNOWN].includes(comparisonType)) return null;

  function handleValueChange(value: string) {
    const _filter = {
      ...filter,
      body: { ...filter.body, value },
    };
    setFilter(_filter);
    setValue(value);
    onChangeTraitValue(value);
  }

  function handleMultiValueSelect(value: string) {
    const currentValue = Array.isArray(filter.body.value)
      ? filter.body.value
      : [];

    let newValue = value.trim().includes(",")
      ? value
          .trim()
          .split(",")
          .map((v) => v.trim())
      : currentValue.includes(value)
        ? currentValue.filter((v) => v !== value)
        : currentValue.concat(value);

    const _filter = {
      ...filter,
      body: {
        ...filter.body,
        value: newValue,
      },
    };
    setFilter(_filter);
    setValue(newValue);
  }

  function getPlaceholderText() {
    if (
      comparisonType === ComparisonType.AFTER ||
      comparisonType === ComparisonType.BEFORE
    )
      return moment().format("YYYY-MM-DD");
    return "Enter a value";
  }

  if (dataType === DataTypeString.WORKSPACE_MEMBER) {
    return (
      <WorkspaceMemberValues
        filter={filter}
        onSelectValue={handleMultiValueSelect}
        onDone={() => {
          onSaveFilter(filter);
        }}
      />
    );
  }

  if (
    comparisonType === ComparisonType.IS ||
    comparisonType === ComparisonType.IS_NOT
  )
    return (
      <Popover className="relative">
        {({ close }) => (
          <>
            <PopoverButton>
              <Tooltip label={truncate ? getValueText(value) : ""} hasArrow>
                <div className={cx("flex", `max-w-[${getMaxWidth(truncate)}]`)}>
                  <p
                    data-testid="audience-trait-value"
                    className={cx(
                      "cursor-pointer truncate rounded-md px-1 py-0.5 text-sm font-semibold hover:bg-gray-200",
                      !value && "bg-gray-200",
                    )}
                  >
                    {getValueText(value)}
                  </p>
                </div>
              </Tooltip>
            </PopoverButton>
            <PopoverPanel>
              <ValueSelection
                filter={filter}
                onSelectValue={handleMultiValueSelect}
                onDone={() => {
                  onSaveFilter(filter);
                  close();
                }}
              />
            </PopoverPanel>
          </>
        )}
      </Popover>
    );

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <PopoverButton>
            <div className={cx("flex", `max-w-[${getMaxWidth(truncate)}]`)}>
              <p
                data-testid="audience-trait-value"
                className={cx(
                  "cursor-pointer truncate rounded-md px-1 py-0.5 text-sm font-semibold hover:bg-gray-200",
                  !value && "bg-gray-200",
                )}
              >
                {value || "Select a value"}
              </p>
            </div>
          </PopoverButton>
          <PopoverPanel className="flex w-full flex-col">
            <div className="absolute z-popover mt-1 max-h-60 w-[200px] overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div className="flex flex-col gap-2 px-2 py-1">
                <ValueInput
                  traitValue={value as string}
                  onValueChange={handleValueChange}
                  placeholder={getPlaceholderText()}
                  dataType={dataType}
                  comparisonType={comparisonType}
                />

                <Button
                  borderRadius="lg"
                  onClick={() => {
                    setFilter(filter);
                    onSaveFilter(filter);
                    close();
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
