import { MappingData } from "modules/Settings/Crm/SyncSettings/MappingData";
import { IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { SelectObject } from "./SelectObject";

export const SalesforceWorkspaceData: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  return (
    <>
      <SelectObject appObject={appObject} />
      <MappingData appObject={appObject} integration={Integration.Salesforce} />
    </>
  );
};
