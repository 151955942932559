import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IContact } from "core/types/Contact";
import { useGroup } from "core/hooks/useGroup";
import { getContactName } from "core/helpers/contactName";
import { ITrait } from "core/types/Trait";
import { getContactEmail } from "core/helpers/contactEmail";
import { ClipboardButton } from "core/components/ClipboardButton";
import ContactAvatar from "core/components/Avatars/ContactAvatar";
import { Box, Flex, StyleProps, Text, Tooltip } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { cx } from "helpers/cx";
import { motion } from "framer-motion";
import useFlag from "core/hooks/useFlag";
import { NEW_USER_PROFILE } from "core/constants/features";
import { useAppObjects } from "core/hooks/useAppObjects";

export interface IContactProps extends IContact {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string | null;
  traits: ITrait;
  isIdentified?: boolean;
  anonymousId?: string;
}

export interface IContactNameCellProps extends StyleProps {
  props: IContactProps;
  groupId?: string;
  showCompany?: boolean;
  showName?: boolean;
  width?: number;
  showCopyIcon?: boolean;
}

export const ContactNameCell: React.FC<IContactNameCellProps> = ({
  props,
  groupId,
  showCompany = false,
  showName = true,
  width = 300,
  showCopyIcon = true,
  ...styleProps
}) => {
  const { appId } = useParams();
  const { userAppObject } = useAppObjects();
  const { id, isIdentified, traits, anonymousId } = props;
  const [labelWidth, setLabelWidth] = useState(width);
  const [showCopy, setShowCopy] = useState(false);
  const hasNewProfile = useFlag(NEW_USER_PROFILE);
  const profileLink = hasNewProfile
    ? `/a/${appId}/objects/${userAppObject?.id}/object/${encodeURIComponent(
        id,
      )}/`
    : `/a/${appId}/contact/${encodeURIComponent(id)}`;

  const isAnonymous = id === "";
  const name = getContactName(props);
  const email = getContactEmail(props);

  const hasEmail = Boolean(email && email !== "null" && email !== "Unknown");
  const hasName = Boolean(name && name !== "Unknown");

  const { fetchGroup, group } = useGroup({
    id: groupId || "",
  });

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  if (isAnonymous) {
    return (
      <Flex align="center">
        <Box pr={4}>
          <ContactAvatar size="xs" contact={{ id, traits: {} }} />
        </Box>
        <Tooltip
          label={
            anonymousId
              ? `This is an anonymous user with no userId and just an anonymousId. Their anonymous id is ${anonymousId}`
              : "This is an anonymous user with no userId"
          }
        >
          <Flex
            align="flex-start"
            direction="column"
            _hover={{ cursor: "default" }}
          >
            {anonymousId && showName ? (
              <>
                <Text fontSize="sm" noOfLines={1}>
                  Anonymous
                </Text>
                <Text fontSize="sm" color="gray.600" noOfLines={1}>
                  {anonymousId}
                </Text>
              </>
            ) : (
              <Text fontSize="sm" noOfLines={1}>
                Anonymous
              </Text>
            )}
          </Flex>
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Link to={profileLink}>
      <Tooltip
        placement="top"
        isDisabled={Boolean(isIdentified)}
        label={
          <Flex direction="column" gap={1}>
            {!Boolean(isIdentified) && (
              <div>
                <p className="font-medium text-gray-600">Missing user</p>
                <Text mb={2}>
                  We have received a track event which was performed by this
                  user, but the user is missing an identify call. Make sure the
                  user with id{" "}
                  <Text
                    fontWeight="semibold"
                    fontStyle="italic"
                    borderRadius={"md"}
                    p={1}
                    as="span"
                    color="gray.400"
                  >
                    {id}
                  </Text>{" "}
                  is sending an identify call.
                </Text>
              </div>
            )}
          </Flex>
        }
      >
        <Flex align="center" position="relative">
          <Flex mr={3}>
            <ContactAvatar size="sm" contact={{ id, traits, isIdentified }} />
          </Flex>
          {!Boolean(isIdentified) && (
            <Flex
              left="20px"
              top="-5px"
              position="absolute"
              mr={1}
              align="center"
              gridGap={2}
              flexWrap="wrap"
            >
              <Flex align="center">
                <WarningTwoIcon color="yellow.400" mr={1} mb={0.5} />
              </Flex>
            </Flex>
          )}
          <div
            className="flex w-full flex-col"
            onMouseEnter={() => {
              if (!showCopyIcon) return;
              setShowCopy(true);
              setLabelWidth(width - 10);
            }}
            onMouseLeave={() => {
              if (!showCopyIcon) return;
              setShowCopy(false);
              setLabelWidth(width);
            }}
          >
            <div className="flex w-full items-center justify-between">
              <motion.div
                initial={{ width: labelWidth }}
                animate={{ width: labelWidth }}
                className={cx(
                  "flex w-full items-center justify-between",
                  labelWidth ? `max-w-[${labelWidth}px]` : "max-w-full",
                )}
              >
                <Tooltip label={hasEmail ? email : id}>
                  <p className="truncate text-sm">{hasEmail ? email : id}</p>
                </Tooltip>
              </motion.div>
              {showCopy && (
                <motion.div animate={{ x: -5 }}>
                  <ClipboardButton
                    value={email ?? id}
                    showLabel={false}
                    copyText="Copy email"
                    variant="ghost"
                    bg="transparent"
                    color="black"
                    _hover={{ bg: "transparent" }}
                  />
                </motion.div>
              )}
            </div>
            {hasName && showName && (
              <Text fontSize="xs" color="gray.600" noOfLines={1}>
                {name}
              </Text>
            )}
            {showCompany && (
              <Text
                fontSize="xs"
                color={Boolean(group) ? "gray.600" : "warning.500"}
                noOfLines={1}
              >
                {Boolean(group) ? group?.name || group?.id : "Missing company"}
              </Text>
            )}
          </div>
        </Flex>
      </Tooltip>
    </Link>
  );
};

export default ContactNameCell;
