import React from "react";
import { LogoType } from "core/design-system/components/Icon/Icon.types";
import Icon from "core/design-system/components/Icon";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  ComponentDefaultProps,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export interface IntegrationMetadata {
  logo?: LogoType;
  logoComponent?: JSX.Element;
  docsUrl?: string;
  title: string;
  description: string;
  isBeta?: boolean;
}

interface IContainer extends ComponentDefaultProps {
  size?: string;
  isExpanded?: boolean;
}

export const Container: React.FC<IContainer> = ({
  children,
  isExpanded,
  ...props
}) => {
  return (
    <Flex gap={6} direction="column" h="full">
      <Accordion
        allowToggle
        defaultIndex={isExpanded ? 0 : undefined}
        {...props}
      >
        {children}
      </Accordion>
    </Flex>
  );
};

export const Collapsable: React.FC<{
  headerIcon?: React.ReactNode;
  title: string | React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
}> = ({ headerIcon, title, children, onClick }) => (
  <AccordionItem
    mb={4}
    border="1px solid gray.50"
    boxShadow="sm"
    borderRadius="md"
  >
    <AccordionButton
      onClick={onClick}
      px={5}
      py={4}
      _hover={{ bg: "transparent" }}
    >
      <Box flex="1" textAlign="left">
        <Flex align="center" gridGap={2}>
          <Flex>{headerIcon}</Flex>
          <Flex>
            <Text>{title}</Text>
          </Flex>
        </Flex>
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel px={12}>{children}</AccordionPanel>
  </AccordionItem>
);

export const Section: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => (
  <Flex direction="column" h="full">
    <Flex h="full" direction="column" gap={4}>
      {children}
    </Flex>
  </Flex>
);

export const Header: React.FC<{
  metadata: IntegrationMetadata;
  ActionComponent?: React.FC;
}> = ({ metadata, ActionComponent }) => {
  const hasLogo = metadata.logo || metadata.logoComponent;
  return (
    <Flex direction="column">
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          {metadata.logo && (
            <Icon w={5} h={5} iconType="logo" name={metadata.logo} />
          )}
          {metadata.logoComponent && (
            <Flex w={5} h={5}>
              {metadata.logoComponent}
            </Flex>
          )}
          <Box ml={hasLogo ? 2 : 0}>
            <Text fontSize="xl" fontWeight="bold">
              {metadata.title}
              {metadata.isBeta && (
                <Badge ml={2} colorScheme="purple" borderRadius="md">
                  Beta
                </Badge>
              )}
            </Text>
          </Box>
        </Flex>
        <div className="flex items-center justify-center gap-2">
          <Tooltip label="Learn more">
            <Button
              as="a"
              target="_blank"
              rel="noreferrer"
              href={metadata.docsUrl}
              leftIcon={<BookOpenIcon className="h-4 w-4" />}
            >
              <div className="flex items-center gap-1">Learn</div>
            </Button>
          </Tooltip>
          {ActionComponent && <ActionComponent />}
        </div>
      </Flex>
    </Flex>
  );
};

export const Body: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex direction="column" py={4} {...props}>
      {children}
    </Flex>
  );
};

export const Footer: React.FC<ComponentDefaultProps> = ({ children }) => {
  return (
    <Flex borderTop="1px solid" borderColor="gray.200" pt={10}>
      {children}
    </Flex>
  );
};
