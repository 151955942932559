import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IReport, IReportConfig } from "core/types/Report";
import { IAudience } from "core/types/Audience";
import { AppObjectType } from "core/types/AppObject";
import { useGetInsightQuery } from "core/models/insights";
import { useQueryParam } from "core/hooks/useQueryParam";
import { serializeConfig } from "./useInsightDataFetching";
import { useEditDashboard } from "./useEditDashboard";

interface IUseDrilldownProps {
  report: IReport;
  skip: boolean;
  insightType: number;
  insightParams?: {
    intervalDays?: number;
    intervalType?: number;
    date?: string;
    hour?: string;
    eventIndex?: number;
    milestone?: string;
    completed?: boolean;
    converted?: boolean;
    traitValues?: string[];
    daysOfUsage?: number;
    intervalLength?: number;
  };
}

export interface IDrilldownGroup {
  groupId: string;
  traits: { [key: string]: string };
}

const DEFAULT_PAGE_SIZE = 18;

export const useGroupDrilldown = ({
  report,
  insightType,
  skip,
  insightParams,
}: IUseDrilldownProps) => {
  const { appId } = useParams<{ appId: string; reportId: string }>();
  const [page, setPage] = useState(0);
  const [hasMoreGroups, setHasMoreGroups] = useState(false);
  const [groups, setGroups] = useState<IDrilldownGroup[]>([]);
  const levelQueryParam = useQueryParam("level");
  const { fullModeInsight } = useEditDashboard();

  const groupTypes = {
    [AppObjectType.Group]: "group",
    [AppObjectType.Company]: "company",
    [AppObjectType.User]: "user",
  } as { [key in AppObjectType]: "group" | "company" | "user" };

  function getGroupType(): "group" | "company" | "user" {
    if (levelQueryParam) return levelQueryParam as "group" | "company" | "user";
    if (fullModeInsight && fullModeInsight?.viewInsight?.appObject)
      return groupTypes[fullModeInsight?.viewInsight?.appObject?.objectType];

    return "group";
  }

  const {
    data: response,
    isLoading,
    isFetching,
    error,
  } = useGetInsightQuery(
    {
      appId: Number(appId),
      reportId: Number(report?.id),
      groupType: getGroupType(),
      config: (serializeConfig({ report, config: report?.config }) ||
        {}) as IReportConfig,
      audience: (report?.audience || {}) as IAudience,
      insightType,
      insightParams: { page, limit: DEFAULT_PAGE_SIZE, ...insightParams },
    },
    { skip },
  );

  useEffect(() => {
    if (response?.data) {
      if (
        response?.data?.groups?.length === 0 ||
        response?.data?.groups?.length < DEFAULT_PAGE_SIZE
      ) {
        setHasMoreGroups(false);
      } else {
        setHasMoreGroups(true);
      }

      const allGroups =
        page === 0
          ? response?.data?.groups
          : [...groups, ...response?.data?.groups];

      setGroups(allGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const loadMore = () => {
    if (hasMoreGroups) setPage(page + 1);
  };

  return {
    isLoading: isLoading || isFetching,
    error,
    hasMoreGroups,
    groups,
    loadMore,
  };
};
