import { useParams } from "react-router-dom";
import { IdentifierPropertyMenu } from "modules/Settings/Integrations/Hubspot/IdentifierPropertyMenu";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { useToast } from "core/hooks/useToast";
import { IDataMapping } from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { useCrmProperties } from "core/hooks/useCrmProperties";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, ButtonGroup, Tooltip } from "@chakra-ui/react";

interface IEditableDestinationMenu {
  appObject: IAppObject;
  integration: Integration;
  matchingIdentifierDataMapping: IDataMapping;
  isLoading?: boolean;
}

const NAME_PROPERTY = {
  name: "name",
  label: "Company name",
  hasUniqueValue: true,
  type: "string",
};

const DOMAIN_PROPERTY = {
  name: "domain",
  label: "Company Domain Name",
  hasUniqueValue: true,
  type: "string",
};

export const EditableDestinationMenu: React.FC<IEditableDestinationMenu> = ({
  appObject,
  integration,
  matchingIdentifierDataMapping,
  isLoading,
}) => {
  const { appId } = useParams();
  const toast = useToast();
  const { currentProperties, properties, hasMore, page, setSearch, setPage } =
    useCrmProperties({
      appId: Number(appId),
      integration,
      objectType: appObject.objectType,
    });

  const { updateMapping } = useDataMapping({
    appId: Number(appId),
    integration,
    appObject,
  });

  function canEditDataMapping() {
    return (
      integration === Integration.Attio ||
      (integration === Integration.Hubspot &&
        appObject.objectType === AppObjectType.Group)
    );
  }

  function onSelect(destination: string) {
    updateMapping({
      id: matchingIdentifierDataMapping.id,
      source: matchingIdentifierDataMapping.source,
      destination,
      isEditable: canEditDataMapping(),
    });
    toast({
      title: "Updated successfully",
    });
  }

  const property = properties.find(
    (hp) => hp.name === matchingIdentifierDataMapping?.destination,
  );

  return (
    <ButtonGroup
      variant="ghost"
      size="sm"
      borderRadius="lg"
      isAttached
      bg="gray.50"
      isDisabled={!matchingIdentifierDataMapping.isEditable || isLoading}
    >
      <Tooltip
        hasArrow
        label={
          !matchingIdentifierDataMapping.isEditable
            ? "This cannot be changed"
            : ""
        }
        placement="top"
        shouldWrapChildren
      >
        <Popover className="relative">
          <PopoverButton disabled={!matchingIdentifierDataMapping.isEditable}>
            <Button variant="ghost" as={Button} isLoading={isLoading}>
              {property ? (
                <p className="nowrap max-w-[180px] truncate">
                  {property.label}
                </p>
              ) : (
                <p className="nowrap max-w-[180px] truncate">
                  Select a property
                </p>
              )}
            </Button>
          </PopoverButton>
          <PopoverPanel className="flex flex-col">
            {({ close }) => (
              <Command className="w-[250px]">
                <CommandInput
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search"
                />
                <CommandList>
                  {currentProperties.length === 0 && (
                    <div className="flex items-center justify-center">
                      <span className="px-2 py-1 text-left sm:leading-4">
                        No properties found
                      </span>
                    </div>
                  )}
                  {integration === Integration.Hubspot && (
                    <IdentifierPropertyMenu
                      property={NAME_PROPERTY}
                      integration={integration}
                      onSelect={() => {
                        onSelect(NAME_PROPERTY.name);
                        close();
                      }}
                    />
                  )}
                  {integration === Integration.Hubspot && (
                    <IdentifierPropertyMenu
                      property={DOMAIN_PROPERTY}
                      integration={integration}
                      onSelect={() => {
                        onSelect(DOMAIN_PROPERTY.name);
                        close();
                      }}
                    />
                  )}
                  {currentProperties.map((property, index) => (
                    <IdentifierPropertyMenu
                      key={index}
                      integration={integration}
                      property={property}
                      onSelect={() => {
                        onSelect(property.name);
                        close();
                      }}
                    />
                  ))}

                  {hasMore && (
                    <CommandOption
                      value={() => {
                        setPage(page + 1);
                      }}
                    >
                      Load more...
                    </CommandOption>
                  )}
                </CommandList>
              </Command>
            )}
          </PopoverPanel>
        </Popover>
      </Tooltip>
    </ButtonGroup>
  );
};
