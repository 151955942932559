import { Link } from "react-router-dom";
import { ITrait } from "core/types/Trait";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { isValidUrl } from "core/helpers/isValidUrl";
import { avatarFromTraits } from "core/helpers/contactAvatar";
import { getCompanyName } from "core/helpers/companyName";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Tooltip } from "@chakra-ui/react";
import useFlag from "core/hooks/useFlag";
import { NEW_PROFILE } from "core/constants/features";
import { useAppObjects } from "core/hooks/useAppObjects";

export interface ICompanyNameCellProps {
  props: {
    appId?: string | number;
    id: string;
    name?: string;
    traits: ITrait;
  };
}

function CompanyNameCell({
  props: { appId, id, name, traits },
}: ICompanyNameCellProps) {
  const companyName = getCompanyName({ name, traits });
  const avatar = avatarFromTraits(traits);
  const logoURL = useClearbitLogo({ domain: id });
  const url = isValidUrl(avatar) ? avatar : logoURL;
  const { companyAppObject } = useAppObjects();
  const hasNewProfile = useFlag(NEW_PROFILE);

  return (
    <Link
      to={
        hasNewProfile
          ? `/a/${appId}/objects/${companyAppObject?.id}/object/${id}/`
          : `/a/${appId}/company/${id}/`
      }
    >
      <div className="flex w-full items-center gap-2">
        <div className="min-w-[30px]">
          <CompanyAvatar size="sm" group={{ id }} logoURL={url} />
        </div>
        <Tooltip label={companyName}>
          <p className="truncate text-sm">{companyName}</p>
        </Tooltip>
      </div>
    </Link>
  );
}

export default CompanyNameCell;
