import { Navigate, useNavigate } from "react-router-dom";
import { Settings } from "modules/Settings/Integrations/Salesforce/Settings";
import * as Integration from "modules/Settings/Integrations/Integration";
import { UninstallModal } from "modules/Settings/Integrations/Hubspot/UninstallModal";
import { useSalesforceSettings } from "core/hooks/useSalesforceSettings";
import useFlag from "core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { trackEvent } from "core/helpers/trackEvent";
import { SalesforceIcon } from "core/design-system/components/Icon/Interface/SalesforceIcon";
import { SALESFORCE } from "core/constants/features";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Install } from "./Salesforce/Install";

const CONTENT: Integration.IntegrationMetadata = {
  title: "Salesforce",
  description: "Send your June data to Salesforce",
  logoComponent: <SalesforceIcon w="22px" h="22px" />,
  docsUrl: "https://help.june.so/en/articles/6782394-hubspot-integration",
  isBeta: false,
};

export const PaywallUpgradeHeader: React.FC = () => {
  return (
    <Text fontWeight="bold" fontSize="2xl">
      Get access to the Salesforce
    </Text>
  );
};

export const PaywallUpgradeSubHeader: React.FC = () => {
  return (
    <Text color="gray.700" fontSize="md" fontWeight="normal" px={10}>
      Upgrade to start using the{" "}
      <Text as="span" fontWeight="bold">
        Salesforce integration
      </Text>{" "}
      and with June
    </Text>
  );
};

const UninstallAction = ({
  onOpenConfirmation,
  isConfirmationOpen,
  onCloseConfirmation,
  onDeleteSetting,
  id,
}: {
  onOpenConfirmation: () => void;
  isConfirmationOpen: boolean;
  onCloseConfirmation: () => void;
  onDeleteSetting: () => void;
  id: number;
}) => {
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<EllipsisVerticalIcon className="h-5 w-5" />}
          colorScheme="gray"
        />
        <MenuList boxShadow="lg">
          <MenuItem
            icon={<TrashIcon className="h-4 w-4" />}
            onClick={onOpenConfirmation}
            color="red.500"
            fontWeight="medium"
          >
            Uninstall
          </MenuItem>
        </MenuList>
      </Menu>
      <UninstallModal
        isOpen={isConfirmationOpen}
        onClose={onCloseConfirmation}
        onUninstall={() => {
          trackEvent({
            eventName: "clicked_salesforce_uninstall",
            appId: id,
          });
          onDeleteSetting();
        }}
        integration="salesforce"
      />
    </>
  );
};

const ConnectAction = ({ appId }: { appId: number }) => {
  return (
    <Button
      onClick={() => {
        trackEvent({ eventName: "clicked_install_salesforce", appId });
        window.location.replace(
          `${import.meta.env.VITE_API_HOST}/auth/salesforce/install`,
        );
      }}
      colorScheme="purple"
    >
      Connect Salesforce
    </Button>
  );
};

export const Salesforce: React.FC = () => {
  const { id } = useCurrentApp();
  const { hasSalesforceInstalled, isLoadingSetting, onDeleteSetting } =
    useSalesforceSettings();
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation,
  } = useDisclosure();
  const hasSalesforceEnabled = useFlag(SALESFORCE);
  const navigate = useNavigate();

  if (window.location.pathname.includes("settings/integrations/salesforce")) {
    return <Navigate to={`/a/${id}/integrations/salesforce`} />;
  }

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header
          metadata={CONTENT}
          ActionComponent={() =>
            !hasSalesforceEnabled ? (
              <Tooltip
                label="Book a call with us to find out more about our Salesforce integration"
                hasArrow
              >
                <Button
                  colorScheme="purple"
                  onClick={() =>
                    navigate(`/a/${id}/onboarding/schedule?book-a-call=true`)
                  }
                >
                  Book a demo
                </Button>
              </Tooltip>
            ) : hasSalesforceInstalled ? (
              <UninstallAction
                {...{
                  hasSalesforceInstalled,
                  onOpenConfirmation,
                  isConfirmationOpen,
                  onCloseConfirmation,
                  onDeleteSetting,
                  id,
                }}
              />
            ) : (
              <ConnectAction appId={id} />
            )
          }
        />
        <Integration.Body>
          <Integration.Section>
            {isLoadingSetting ? (
              <div className="flex w-full items-center justify-center py-12">
                <Spinner />
              </div>
            ) : hasSalesforceInstalled ? (
              <Settings />
            ) : (
              <Install />
            )}
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};
