import { useNavigate } from "react-router-dom";
import { HubspotProfileContainer as HubspotGroupProfileContainer } from "modules/Groups/Group/HubspotProfileContainer";
import { HubspotProfileContainer as HubspotUserProfileContainer } from "modules/Contacts/Contact/HubspotProfileContainer";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { SalesforceIcon } from "core/design-system/components/Icon/Interface/SalesforceIcon";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { Skeleton, Tooltip } from "@chakra-ui/react";
import { AttioProfileContainer } from "./AttioProfileContainer";

export const CrmHeader: React.FC<{
  entity: IContact | IGroup;
  appObject: IAppObject;
  integration?: Integration;
}> = ({ entity, appObject, integration }) => {
  const { id: appId } = useCurrentApp();
  const { hasHubspotInstalled } = useHubspotSettings();
  const { hasAttioInstalled } = useAttioSettings();
  const navigate = useNavigate();

  if (!appObject) {
    return null;
  }

  const HubspotProfileContainer =
    appObject.objectType === AppObjectType.User
      ? HubspotUserProfileContainer
      : HubspotGroupProfileContainer;

  if (integration === Integration.Attio) {
    return (
      <div className="flex w-full justify-between">
        <AttioProfileContainer entity={entity} appObject={appObject} />
      </div>
    );
  }

  if (integration === Integration.Hubspot) {
    return (
      <div className="flex w-full justify-between">
        <HubspotProfileContainer
          appId={String(appId)}
          contact={entity}
          company={entity as IGroup}
          objectType={appObject.objectType}
        />
      </div>
    );
  }

  if (!hasHubspotInstalled && !hasAttioInstalled) {
    return (
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <ArrowPathIcon className="h-4 w-4 text-gray-600" />
          <p className="text-sm">Sync with your CRM</p>
        </div>
        <div className="flex items-center gap-3">
          <Tooltip label="Connect with HubSpot">
            <div
              onClick={() => navigate(`/a/${appId}/integrations/hubspot`)}
              className="cursor-pointer"
            >
              <HubspotIcon color="#f57722" w={5} h={5} />
            </div>
          </Tooltip>
          <Tooltip label="Connect with Attio">
            <div
              onClick={() => navigate(`/a/${appId}/integrations/attio`)}
              className="cursor-pointer"
            >
              <AttioIcon w={5} h={5} />
            </div>
          </Tooltip>
          <Tooltip label="Connect with Salesforce">
            <div
              onClick={() => navigate(`/a/${appId}/integrations/salesforce`)}
              className="cursor-pointer"
            >
              <SalesforceIcon w={5} h={5} />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }

  if (hasAttioInstalled && hasHubspotInstalled) {
    return (
      <>
        <div className="w-full">
          {entity ? (
            <HubspotProfileContainer
              appId={String(appId)}
              contact={entity}
              company={entity}
              objectType={appObject.objectType}
            />
          ) : (
            <Skeleton borderRadius="lg" mb={4} height="150px" width="100%" />
          )}
        </div>
        <div className="flex w-full">
          {entity ? (
            <AttioProfileContainer entity={entity} appObject={appObject} />
          ) : (
            <Skeleton borderRadius="lg" height="20px" width="100%" />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {!hasAttioInstalled && (
        <div className="w-full">
          {entity ? (
            <HubspotProfileContainer
              appId={String(appId)}
              company={entity}
              contact={entity}
              objectType={appObject.objectType}
            />
          ) : (
            <Skeleton borderRadius="lg" mb={4} height="150px" width="100%" />
          )}
        </div>
      )}
      {!hasHubspotInstalled && (
        <div className="flex w-full">
          {entity ? (
            <AttioProfileContainer entity={entity} appObject={appObject} />
          ) : (
            <Skeleton borderRadius="lg" height="20px" width="100%" />
          )}
        </div>
      )}
    </>
  );
};
