import { useEffect, useState } from "react";
import moment from "moment";
import { IGroup } from "core/types/Group";
import { AppObjectType } from "core/types/AppObject";
import {
  useCreateObjectMutation,
  useGetObjectsQuery,
  useUpdateObjectMutation,
} from "core/models/hubspot/objects";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export enum HubspotObjectTypes {
  Contacts = "contacts",
  Companies = "companies",
  Deals = "deals",
}

export const hubspotObjectTypesSingular = {
  [HubspotObjectTypes.Contacts]: "contact",
  [HubspotObjectTypes.Companies]: "company",
  [HubspotObjectTypes.Deals]: "deal",
};

interface IUseHubspotObjectsParams {
  appId: number | string;
  company: IGroup;
  objectType: AppObjectType;
}

export interface IHubspotObject {
  id: string;
  properties: Record<string, string>;
}

interface IHubspotObjectsResponse {
  objects: IHubspotObject[];
  lastSyncedAt: string;
  createHubspotObject: () => void;
  updateHubspotObject: (id: string) => void;
  isLoadingObjects: boolean;
  isCreatedObjectLoading: boolean;
  isUpdatedObjectLoading: boolean;
  refetchObjects: () => void;
  fetchObjectsError: FetchBaseQueryError | SerializedError | undefined;
  createObjectError: FetchBaseQueryError | SerializedError | undefined;
  updateObjectError: FetchBaseQueryError | SerializedError | undefined;
  canCreate: boolean;
}

export const useHubspotObjects = ({
  appId,
  company,
  objectType,
}: IUseHubspotObjectsParams): IHubspotObjectsResponse => {
  const [objects, setObjects] = useState<IHubspotObject[]>([]);
  const [lastSyncedAt, setLastSyncedAt] = useState<string>("");

  const {
    data: fetchObjects,
    isLoading: isLoadingObjects,
    error: fetchObjectsError,
    refetch: refetchObjects,
  } = useGetObjectsQuery({
    appId,
    groupId: company.id,
    objectType,
  });

  const [
    createObject,
    {
      data: createdObjects,
      isLoading: isCreatedObjectLoading,
      error: createObjectError,
    },
  ] = useCreateObjectMutation();

  const [
    updateObject,
    {
      data: updatedObjects,
      isLoading: isUpdatedObjectLoading,
      error: updateObjectError,
    },
  ] = useUpdateObjectMutation();

  useEffect(() => {
    if (fetchObjects?.objects) {
      setObjects(fetchObjects.objects);
      setLastSyncedAt(moment.utc(fetchObjects.lastSyncedAt).fromNow());
    }

    if (createdObjects?.objects) {
      setObjects(createdObjects.objects);
      setLastSyncedAt(moment.utc(createdObjects.lastSyncedAt).fromNow());
    }

    if (updatedObjects?.objects) {
      setObjects(updatedObjects.objects);
      setLastSyncedAt(moment.utc(updatedObjects.lastSyncedAt).fromNow());
    }
  }, [fetchObjects, createdObjects, updatedObjects]);

  function createHubspotObject() {
    createObject({ appId, groupId: company.id, objectType });
  }

  function updateHubspotObject(id: string) {
    updateObject({
      id,
      groupId: company.id,
      objectType,
      appId,
    });
  }

  function canCreate() {
    if (objectType === AppObjectType.Group) return true;

    return Boolean(company.id);
  }

  return {
    objects,
    lastSyncedAt:
      (fetchObjects?.lastSyncedAt ||
        createdObjects?.lastSyncedAt ||
        updatedObjects?.lastSyncedAt) &&
      lastSyncedAt,
    isLoadingObjects,
    createHubspotObject,
    updateHubspotObject,
    isCreatedObjectLoading,
    isUpdatedObjectLoading,
    fetchObjectsError,
    createObjectError,
    updateObjectError,
    refetchObjects,
    canCreate: canCreate(),
  };
};
