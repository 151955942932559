import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { ITrait as TraitKeyValue } from "core/types/Trait";
import { Plan } from "core/types/App";
import { useProfilePinnedTraits } from "core/hooks/useProfilePinnedTraits";
import { usePaywall } from "core/hooks/usePaywall";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useContact } from "core/hooks/useContact";
import Icon from "core/design-system/components/Icon";
import { TRAIT_LEVEL } from "core/constants/traits";
import { TraitEditor } from "core/components/Traits/TraitEditor";
import Trait from "core/components/Traits/Trait";
import {
  MinusIcon,
  PlusIcon as PlusIconSolid,
} from "@heroicons/react/24/solid";
import {
  BoltIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  Alert,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { CheckIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { TraitsBanner } from "./Banner";

interface ITraitsContainerProps extends StyleProps {
  id: string;
  traitLevel: TRAIT_LEVEL;
  traitKeyValues?: TraitKeyValue[];
  showError: boolean;
  allowComputedTrait?: boolean;
}

const BLOCKED_TRAIT_NAMES = ["0", "1", "2", "3", "4"];

const NoEmailTraitAlert = () => {
  return (
    <Alert status="info" mb="2" background="orange.50" rounded="md">
      <InfoOutlineIcon fontSize="sm" color="orange.500" mr={2} />
      <Text color="gray.700" fontSize="sm">
        You haven't sent an `email` trait.{" "}
        <Link
          textDecoration={"underline"}
          href="https://segment.com/docs/connections/spec/identify/#traits"
          isExternal
        >
          Read more.
        </Link>
      </Text>
    </Alert>
  );
};

export const TraitsContainer: React.FC<ITraitsContainerProps> = ({
  id,
  traitLevel,
  traitKeyValues = [],
  showError = false,
  allowComputedTrait = true,
  ...styleProps
}) => {
  const { id: appId } = useCurrentApp();
  const { fetchContact } = useContact({ appId, id });
  const { plan } = usePaywall();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    pinnedKeyValues,
    unpinnedKeyValues,
    shouldShowUnpinned,
    hiddenTraitsCount,
    showAll,
    setShowAll,
    pinTrait,
    traitByKey,
  } = useProfilePinnedTraits({
    appId,
    traitLevel,
    traitKeyValues,
    plan,
  });

  const navigate = useNavigate();
  const [editMode, setEditMode] = React.useState(false);
  const [traitEditModal, showTraitEditModal] = useState(false);
  const [showTraitsBanner, setShowTraitsBanner] = useState<boolean>(
    localStorage.getItem("dismiss_traits_banner") !== "true",
  );

  const hasTraits = traitKeyValues && traitKeyValues?.length > 0;
  const hasEmailTrait = Boolean(
    [...traitKeyValues].find(({ name }) => name === "email"),
  );

  return (
    <>
      <TraitEditor
        id={id}
        refetch={fetchContact}
        traitLevel={traitLevel}
        isOpen={traitEditModal}
        onClose={() => showTraitEditModal(false)}
      />
      <Box w="100%" {...styleProps}>
        <div className="mb-2 flex items-center justify-between">
          <div className="font-medium capitalize">Traits</div>
          {editMode ? (
            <Button
              as={IconButton}
              icon={<CheckIcon h={4} w={4} />}
              color="green.500"
              onClick={() => {
                setEditMode(false);
                setShowAll(false);
              }}
              variant="ghost"
              shadow="none"
              boxShadow="none"
              size="xs"
              fontSize="xs"
              bg="transparent"
              border="none"
            ></Button>
          ) : (
            <Menu>
              <MenuButton
                as={IconButton}
                size="xs"
                aria-label="Add trait"
                fontSize="xs"
                icon={<EllipsisVerticalIcon className="h-5" />}
                variant="ghost"
                shadow="none"
                boxShadow="none"
                mr={-2}
              ></MenuButton>
              <MenuList maxW="250px">
                {allowComputedTrait && (
                  <MenuItem
                    onClick={() =>
                      navigate(`/a/${appId}/settings/computed-traits`, {
                        replace: true,
                      })
                    }
                  >
                    <Flex direction="row" align="center" gap={2}>
                      <BoltIcon className="h-4 w-4" />
                      <Text fontSize="sm">Add computed trait</Text>
                    </Flex>
                  </MenuItem>
                )}
                <MenuItem onClick={() => showTraitEditModal(!traitEditModal)}>
                  <Flex direction="row" align="center" gap={2}>
                    <PlusIcon className="h-4 w-4" />
                    <Text fontSize="sm">Add manual trait</Text>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditMode(true);
                    setShowAll(true);
                  }}
                >
                  <Flex direction="row" align="center" gap={2}>
                    <Icon iconType="icon" name="pin-outline" fill="none" />
                    <Text fontSize="sm">Edit pinned traits</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </div>
        {hasTraits ? (
          <Box>
            {traitLevel === TRAIT_LEVEL.USER && !hasEmailTrait && (
              <NoEmailTraitAlert />
            )}

            {pinnedKeyValues.map(({ name, value }, index) => {
              return (
                <Box key={index}>
                  {!traitByKey[name]?.isArchived &&
                    !BLOCKED_TRAIT_NAMES.includes(name) && (
                      <Box pb={2}>
                        <Trait
                          traitId={traitByKey[name]?.id}
                          name={name}
                          description={traitByKey[name]?.description}
                          isComputed={!!traitByKey[name]?.isComputed}
                          config={traitByKey[name]?.config}
                          value={
                            typeof value === "string"
                              ? value
                              : JSON.stringify(value)
                          }
                          lastComputedAt={traitByKey[name]?.lastComputedAt}
                          isTransient={false}
                          isPaywalled={
                            plan !== Plan.Pro &&
                            !!traitByKey[name]?.isComputed &&
                            !traitByKey[name]?.isLive
                          }
                          isPinned={traitByKey[name]?.isPinned}
                          editMode={editMode}
                          onPinTrait={(isPinned: boolean) =>
                            pinTrait(name, isPinned)
                          }
                          isLive={traitByKey[name]?.isLive}
                        />
                      </Box>
                    )}
                </Box>
              );
            })}

            {shouldShowUnpinned && (
              <Flex direction={"column"} py={2} mb={1}>
                <InputGroup>
                  <InputLeftElement h="32px">
                    <Icon
                      iconType="icon"
                      name="search"
                      color="blackAlpha.500"
                      h={3}
                      w={3}
                      mr={1}
                    />
                  </InputLeftElement>
                  <Input
                    size="sm"
                    bg="white"
                    borderRadius="lg"
                    focusBorderColor="primary"
                    placeholder="Search traits"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
              </Flex>
            )}

            {shouldShowUnpinned &&
              unpinnedKeyValues
                .filter((t) =>
                  searchTerm ? t.name.toLowerCase().includes(searchTerm) : true,
                )
                .map(({ name, value }, index) => {
                  return (
                    <Box key={index}>
                      {!traitByKey[name]?.isArchived &&
                        !BLOCKED_TRAIT_NAMES.includes(name) && (
                          <Box pb={2}>
                            <Trait
                              traitId={traitByKey[name]?.id}
                              name={name}
                              description={traitByKey[name]?.description}
                              isComputed={!!traitByKey[name]?.isComputed}
                              config={traitByKey[name]?.config}
                              value={
                                typeof value === "string"
                                  ? value
                                  : JSON.stringify(value)
                              }
                              lastComputedAt={traitByKey[name]?.lastComputedAt}
                              isTransient={false}
                              isPaywalled={
                                plan !== Plan.Pro &&
                                !!traitByKey[name]?.isComputed &&
                                !traitByKey[name]?.isLive
                              }
                              isPinned={traitByKey[name]?.isPinned}
                              editMode={editMode}
                              onPinTrait={(isPinned: boolean) =>
                                pinTrait(name, isPinned)
                              }
                              isLive={traitByKey[name]?.isLive}
                            />
                          </Box>
                        )}
                    </Box>
                  );
                })}
          </Box>
        ) : (
          <Flex
            direction="column"
            alignItems="center"
            justify="center"
            width="100%"
            fontSize="sm"
            color="gray.500"
          >
            <Text mb={8}>No traits found.</Text>
          </Flex>
        )}
        <div className="mt-2">
          {editMode ? (
            <div />
          ) : (
            <div className="flex justify-between">
              {hiddenTraitsCount > 0 || showAll ? (
                <div className="flex justify-center">
                  <Button
                    onClick={() => setShowAll(!showAll)}
                    variant="link"
                    shadow="none"
                    boxShadow="none"
                  >
                    {showAll ? (
                      <div className="flex items-center gap-1">
                        <MinusIcon className="h-4 w-4" />
                        Show less
                      </div>
                    ) : (
                      <div className="flex items-center gap-1">
                        <PlusIconSolid className="h-4 w-4" />
                        {`${hiddenTraitsCount} more`}
                      </div>
                    )}
                  </Button>
                </div>
              ) : (
                <div />
              )}
            </div>
          )}
        </div>
        {showTraitsBanner && (
          <TraitsBanner setShowTraitsBanner={setShowTraitsBanner} />
        )}
      </Box>
    </>
  );
};

export default TraitsContainer;
