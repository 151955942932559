import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import useFlag from "core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { baseUrl } from "core/constants/paywall";
import { PAYWALLED } from "core/constants/features";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { MauLimit } from "core/components/Paywall/MauLimit";
import { HeaderTextComponent } from "./HeaderText";

export const Paywall: React.FC = () => {
  const location = useLocation();
  const currentApp = useCurrentApp();
  const isPaywalled = useFlag(PAYWALLED);

  const {
    isApproachingLimit: isApproachingMauLimit,
    isAboveLimit: isAboveMauLimit,
    isTrialEnded,
  } = useMauThreshold();

  const [isMauPaywallModalShowing, setMauPaywallModalShowing] = useState(false);

  useEffect(() => {
    // Don't show paywall on settings pages
    if (location.pathname.includes("/settings/")) {
      return setMauPaywallModalShowing(false);
    }

    if (isPaywalled) {
      return setMauPaywallModalShowing(true);
    }
    if (
      !currentApp?.isPaying &&
      !currentApp?.isTrialing &&
      !currentApp?.isPaymentOverdue &&
      (isApproachingMauLimit || isAboveMauLimit)
    ) {
      setMauPaywallModalShowing(true);
    }
    if (isTrialEnded) {
      setMauPaywallModalShowing(true);
    }
  }, [
    currentApp,
    isApproachingMauLimit,
    isAboveMauLimit,
    isTrialEnded,
    isPaywalled,
    location.pathname,
  ]);

  return (
    <PaywallModal
      HeaderTextComponent={HeaderTextComponent}
      CallToActionComponent={MauLimit}
      redirectUrl={`${baseUrl}/a/${currentApp.id}/getting-started`}
    >
      {(paywall: IPaywallModalChildren) => (
        <paywall.Modal
          onClose={() => setMauPaywallModalShowing(false)}
          isOpen={isMauPaywallModalShowing}
        />
      )}
    </PaywallModal>
  );
};
