import { useParams } from "react-router-dom";
import { OBJECT_TYPE_TO_TRAIT_LEVEL } from "modules/Settings/Objects/Object/TraitEditModal";
import {
  TraitValueType,
  TypeSummary,
} from "modules/Settings/Objects/Object/Trait/TypeSummary";
import DefaultHeader from "modules/Pages/DefaultHeader";
import { AppObjectType } from "core/types/AppObject";
import { useGetTraitQuery } from "core/models/traits";
import { useGetTraitDebuggerDataQuery } from "core/models/traitDebugger";
import { useGetAppObjectQuery } from "core/models/appObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { Skeleton } from "@chakra-ui/react";

export const TraitDebugger: React.FC = () => {
  const currentApp = useCurrentApp();
  const { objectId, traitId } = useParams();
  const { data: appObject, isLoading } = useGetAppObjectQuery({
    appId: currentApp.id,
    id: Number(objectId),
  });

  const section = {
    name: "Trait debugger",
    title: "Trait debugger",
    description: `Debug trait data for ${appObject?.singularName} trait.`,
  };

  const level =
    OBJECT_TYPE_TO_TRAIT_LEVEL[appObject?.objectType ?? AppObjectType.User];

  const { data: trait, isLoading: isTraitLoading } = useGetTraitQuery({
    appId: currentApp.id,
    level,
    traitId: Number(traitId),
  });

  const { data: debuggerData, isLoading: isDebuggerDataLoading } =
    useGetTraitDebuggerDataQuery({
      appId: currentApp.id,
      traitId: Number(traitId),
    });

  const pages = [
    { name: "Objects", path: "../objects", current: false },
    {
      name: appObject?.pluralName ?? "Object",
      path: `../objects/${objectId}`,
      current: false,
    },
    {
      name: "Traits",
      path: `../objects/${objectId}/traits`,
      current: false,
    },
    {
      name: "Debugger",
      path: "#",
      current: true,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex w-[985px] flex-col">
      <div className="flex flex-col gap-4">
        <Breadcrumbs pages={pages} />
        <DefaultHeader
          w="inherit"
          section={{
            ...section,
          }}
        />
      </div>
      <div className="flex flex-col gap-2 ">
        {isTraitLoading ? (
          <Skeleton h="16px" w="100px" borderRadius="xl" />
        ) : (
          <div className="text-lg font-medium">{trait?.name}</div>
        )}

        {isDebuggerDataLoading ? (
          <Skeleton h="16px" w="120px" borderRadius="xl" />
        ) : (
          <div className="text-md text-gray-600">
            {debuggerData?.totalCount ?? 0}{" "}
            {debuggerData?.totalCount === 1
              ? appObject?.singularName.toLowerCase()
              : appObject?.pluralName.toLowerCase()}{" "}
            have this trait
          </div>
        )}
        <div className="mt-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Count
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Data
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {appObject && trait ? (
                <>
                  <TypeSummary
                    appObject={appObject}
                    trait={trait}
                    type={TraitValueType.String}
                    typeData={debuggerData?.asString}
                  />
                  <TypeSummary
                    appObject={appObject}
                    trait={trait}
                    type={TraitValueType.Number}
                    typeData={debuggerData?.asNumber}
                  />
                  <TypeSummary
                    appObject={appObject}
                    trait={trait}
                    type={TraitValueType.Boolean}
                    typeData={debuggerData?.asBoolean}
                  />
                  <TypeSummary
                    appObject={appObject}
                    trait={trait}
                    type={TraitValueType.Date}
                    typeData={debuggerData?.asDate}
                  />
                </>
              ) : (
                <>
                  {[...Array(4)].map((_, index) => (
                    <tr key={index}>
                      {[...Array(3)].map((_, index) => (
                        <td key={index} className="py-4">
                          <Skeleton h="16px" w="120px" borderRadius="xl" />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
