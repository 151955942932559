import { useEffect, useState } from "react";
import { AppObjectType } from "core/types/AppObject";
import {
  Integration,
  IProperty,
  useLazyGetPropertiesQuery,
} from "core/models/dataMappings";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";

interface IUseCrmMapping {
  properties: IProperty[];
  currentProperties: IProperty[];
  onGetProperties: () => void;
  isLoading: boolean;
  hasMore: boolean;
  page: number;
  search: string;
  setSearch: (query: string) => void;
  setPage: (page: number) => void;
}

const PER_PAGE = 20;

export const useCrmProperties = ({
  appId,
  integration,
  objectType,
}: {
  appId: number;
  integration: Integration;
  objectType: AppObjectType;
}): IUseCrmMapping => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const { crmSyncSetting: setting } = useCrmSyncSettings({
    integration,
    objectType,
  });

  const [getProperties, { data, isLoading }] = useLazyGetPropertiesQuery();
  const properties = data || [];

  useEffect(() => {
    getProperties({
      appId,
      integration,
      objectType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting?.objectName]);

  const currentProperties =
    search.length > 0
      ? properties
          ?.filter((p: IProperty) =>
            p.label.toLowerCase().includes(search.toLowerCase()),
          )
          .slice(0, PER_PAGE * page)
      : properties?.slice(0, PER_PAGE * page);

  function onGetProperties() {
    getProperties({
      appId,
      integration,
      objectType,
    });
  }

  return {
    properties,
    currentProperties,
    onGetProperties,
    hasMore: currentProperties.length === PER_PAGE * page,
    isLoading,
    search,
    setSearch,
    page,
    setPage,
  };
};
