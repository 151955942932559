import { format, formatDistanceToNow } from "date-fns";

export function formatNumber(value: number): string {
  return value.toLocaleString();
}

export function formatDate(
  value: string | Date,
  useRelative: boolean = false,
): string {
  const date = value instanceof Date ? value : new Date(value);
  if (isNaN(date.getTime())) {
    return value.toString();
  }
  return useRelative
    ? formatDistanceToNow(date, { addSuffix: true })
    : format(date, "do MMM, yyyy");
}

export function formatValue(
  value: unknown,
  relative: boolean = false,
): string | number {
  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "number") {
    return formatNumber(value);
  }

  if (value instanceof Date) {
    return formatDate(value, relative);
  }

  const stringValue = String(value);
  // Try to parse as number
  const numberValue = Number(stringValue);
  if (!isNaN(numberValue)) {
    return Math.ceil(numberValue);
  }

  // Try to parse as date
  const dateValue = new Date(stringValue);
  if (!isNaN(dateValue.getTime())) {
    return formatDate(dateValue, relative);
  }

  return stringValue;
}
