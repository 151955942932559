import humps from "humps";
import { SnakeCasedProperties } from "type-fest";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ICrmEntity } from "../crmEntities";
import { IHubspotContact } from "core/hooks/useHubspotContact";
import { IHubspotCompany } from "core/hooks/useHubspotCompany";
import { IAttioObjectResponse } from "core/models/attio";

export interface ICrmObjects {
  objects: IHubspotContact[] | IHubspotCompany[] | IAttioObjectResponse[];
  crmEntity: ICrmEntity;
  metadata: {
    route: string;
  };
}

export const objectCrmApi = createApi({
  baseQuery,
  reducerPath: "objectCrmApi",
  tagTypes: ["Crm"],
  endpoints: (builder) => ({
    getObjects: builder.query({
      query: ({
        appId,
        appObjectId,
        objectId,
        integration,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
        integration: string;
      }) => ({
        url: `/objects/crm`,
        params: humps.decamelizeKeys({
          appId,
          appObjectId,
          objectId,
          integration,
        }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<ICrmObjects>,
      ): ICrmObjects => humps.camelizeKeys(response) as ICrmObjects,
      providesTags: (result, error, { objectId }) => {
        return result ? [{ type: "Crm", id: objectId }] : [];
      },
    }),
    createObject: builder.mutation({
      query: ({
        appId,
        appObjectId,
        objectId,
        integration,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
        integration: string;
      }) => ({
        url: `/objects/crm`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          appObjectId,
          objectId,
          integration,
        }),
        body: { appId, appObjectId, objectId, integration },
      }),
      invalidatesTags: (result, error, { objectId }) => {
        return [{ type: "Crm", id: objectId }];
      },
      transformResponse: (
        response: SnakeCasedProperties<ICrmObjects>,
      ): ICrmObjects => humps.camelizeKeys(response) as ICrmObjects,
    }),
    updateObject: builder.mutation({
      query: ({
        appId,
        appObjectId,
        objectId,
        integration,
        crmId,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
        integration: string;
        crmId: string;
      }) => ({
        url: `/objects/crm/${crmId}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          appObjectId,
          objectId,
          integration,
          crmId,
        }),
      }),
      invalidatesTags: (result, error, { objectId }) => {
        return [{ type: "Crm", id: objectId }];
      },
      transformResponse: (
        response: SnakeCasedProperties<ICrmObjects>,
      ): ICrmObjects => humps.camelizeKeys(response) as ICrmObjects,
    }),
  }),
});

export const {
  useGetObjectsQuery,
  useCreateObjectMutation,
  useUpdateObjectMutation,
} = objectCrmApi;
