import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { cx } from "helpers/cx";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { IQuery } from "core/types/Query";
import { AppObjectSlugs, IAppObject } from "core/types/AppObject";
import { getAppObject } from "core/modules/appObjects/utils";
import { IViewInsight } from "core/models/viewInsights";
import { useGetSearchReportsQuery } from "core/models/reports";
import { useLazyGetInsightsQuery } from "core/models/insights";
import { ViewLocation } from "core/hooks/useViews";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  ACTIVE_USERS,
  AI,
  EXPLORER,
  FEATURE_RELEASE,
  FUNNEL,
  MILESTONES,
  NEW_USERS,
  POWER_USERS,
  REPORT_CONFIGS,
  RETENTION,
} from "core/constants/report-configurations";
import { SparklesIcon } from "@heroicons/react/20/solid";
import { Flex, Grid, GridItem, Select, Skeleton, Text } from "@chakra-ui/react";
import { ViewInsight } from "../ViewInsight";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

function getPinnableInsights(reportConfig: ITemplateConfig): IInsightSection[] {
  return reportConfig.insights.filter(
    (insight: IInsightSection) => !!insight.view,
  );
}

function getReportConfig(reportType: number): ITemplateConfig | undefined {
  return getReportConfigByType(reportType);
}

interface ReportTypeOption {
  label: string;
  reportType: number;
}

const REPORT_TYPES = [
  {
    label: "Active users",
    reportType: ACTIVE_USERS,
  },
  {
    label: "Activation",
    reportType: MILESTONES,
  },
  {
    label: "Feature",
    reportType: FEATURE_RELEASE,
  },
  {
    label: "Power users",
    reportType: POWER_USERS,
  },
  {
    label: "Retention",
    reportType: RETENTION,
  },
  {
    label: "Funnel",
    reportType: FUNNEL,
  },
  {
    label: "New users",
    reportType: NEW_USERS,
  },
  {
    label: "Explorer",
    reportType: EXPLORER,
  },
  {
    label: "AI",
    reportType: AI,
  },
] as ReportTypeOption[];

export const ReportInsightsBody: React.FC<{
  location: ViewLocation;
  viewId: number;
  viewInsights: IViewInsight[];
}> = ({ location, viewId, viewInsights }) => {
  const { appId, groupId } = useParams();
  const [selectedReportType, setSelectedReportType] =
    useState<ReportTypeOption>(REPORT_TYPES[0]);
  const [selectedReport, setSelectedReport] = useState<IReport | undefined>(
    undefined,
  );
  const { appObjects, activeAppObject, setActiveAppObject, userAppObject } =
    useAppObjects();

  const { data: reports, isFetching } = useGetSearchReportsQuery({
    appId: Number(appId),
    query: undefined,
    labelIds: [],
    reportTypes: [selectedReportType.reportType],
  });

  useEffect(() => {
    if (userAppObject) setActiveAppObject(userAppObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, groupId, userAppObject]);

  const [getInsights, { data: insights }] = useLazyGetInsightsQuery();

  const reportConfig = getReportConfig(selectedReportType.reportType);
  const pinnableInsights =
    reportConfig && (getPinnableInsights(reportConfig) as IInsightSection[]);
  const pinnableViewInsights =
    selectedReport &&
    (pinnableInsights
      ?.filter((insight: IInsightSection) => {
        return selectedReport.reportType === ACTIVE_USERS
          ? insight.typeId !== 15
          : true;
      })
      ?.map((insight: IInsightSection, index: number) => {
        if (insight.view?.level === "group" && groupId) return null;
        if (
          insight.view?.level !== activeAppObject?.slug &&
          !(
            insight.view?.level === "group" &&
            activeAppObject?.slug === AppObjectSlugs.Company
          )
        )
          return null;
        return {
          id: index,
          appId: Number(appId),
          reportId: selectedReport.id,
          insightId: insight.typeId,
          order: index,
          insight: {
            name: insight.title,
            reportType: selectedReportType.reportType,
            level: insight.view?.level,
          },
          appObject: activeAppObject,
        };
      })
      .filter((x) => !!x) as IViewInsight[]);

  function handleReportTypeChange(reportTypeOption: ReportTypeOption) {
    const newReportType = REPORT_TYPES.find(
      (rt) => String(rt.reportType) === String(reportTypeOption.reportType),
    );
    newReportType && setSelectedReportType(newReportType);
  }

  function handleReportChange(e: any) {
    const selectedReportId = e.currentTarget.value;
    const chosenReport = reports?.find(
      (r) => String(r.id) === selectedReportId,
    ) as IReport;
    setSelectedReport(chosenReport);
  }

  function handleAppObjectChange(e: any) {
    if (!appObjects) return;
    const slug = e.currentTarget.value;
    const appObject = getAppObject(appObjects, slug);
    appObject && setActiveAppObject(appObject);
  }

  useEffect(() => {
    if (selectedReportType && reports && reports?.length > 0) {
      setSelectedReport(reports[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportType, reports]);

  useEffect(() => {
    if (selectedReportType.reportType === AI) {
      getInsights({ appId: Number(appId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportType]);

  return (
    <>
      <Flex key={pinnableViewInsights?.length} w="full">
        <Flex minW="130px">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul className="-mx-2 space-y-1">
              {REPORT_TYPES?.map((reportType: ReportTypeOption) => {
                if (reportType.reportType === AI) return null;

                return (
                  <li
                    key={reportType.label}
                    className={cx(
                      reportType.reportType === selectedReportType.reportType
                        ? "bg-gray-50 text-purple-500"
                        : "text-gray-700 hover:bg-gray-50 hover:text-purple-500",
                      "group flex cursor-pointer items-center gap-x-1 rounded-md p-2 pl-3 text-sm font-semibold leading-6",
                    )}
                    onClick={() => handleReportTypeChange(reportType)}
                  >
                    {reportType.label}{" "}
                    {reportType.reportType === AI && (
                      <SparklesIcon className="h-3 text-purple-500" />
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </Flex>
        <Flex ml={10}>
          <Flex direction="column">
            <Flex pb={4} gridGap={2} direction="column" display="block">
              {selectedReportType.reportType !== AI && (
                <Text mb={2} fontWeight="medium" fontSize="xs">
                  Reports
                </Text>
              )}
              <Flex gridGap={2}>
                {isFetching ? (
                  <Skeleton h="40px" w="150px" borderRadius="md" />
                ) : (
                  <Flex direction="column">
                    {selectedReportType.reportType !== AI && (
                      <Select
                        className="py-0"
                        fontSize="sm"
                        onChange={handleReportChange}
                        borderRadius="lg"
                        focusBorderColor="purple"
                        placeholder="Choose a report"
                        value={selectedReport?.id}
                      >
                        {reports?.map((report: IReport) => {
                          return (
                            <option value={report.id}>{report.name}</option>
                          );
                        })}
                      </Select>
                    )}
                  </Flex>
                )}
                {isFetching ? (
                  <Skeleton h="40px" w="150px" borderRadius="md" />
                ) : (
                  <Flex direction="column">
                    {selectedReportType.reportType !== AI && !groupId && (
                      <Select
                        className="py-0"
                        fontSize="sm"
                        onChange={handleAppObjectChange}
                        borderRadius="lg"
                        focusBorderColor="purple"
                        placeholder="Choose the level"
                        value={activeAppObject?.slug}
                      >
                        {appObjects?.map((appObject: IAppObject) => {
                          return (
                            <option value={appObject.slug}>
                              {appObject.singularName}
                            </option>
                          );
                        })}
                      </Select>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex>
              {selectedReportType.reportType !== AI ? (
                selectedReport?.reportType ===
                  selectedReportType?.reportType && (
                  <Grid
                    key={`${selectedReport.id}`}
                    mt={4}
                    templateColumns="repeat(3, 1fr)"
                    gridGap={5}
                  >
                    {pinnableViewInsights?.map(
                      (viewInsight: IViewInsight, index: number) => {
                        const viewInsightId = viewInsights?.find(
                          (vi) =>
                            vi.insightId === viewInsight.insightId &&
                            vi.reportId === viewInsight.reportId,
                        )?.id;

                        const isAdded = !!viewInsightId;

                        return (
                          <GridItem key={index} w="300px" h="300px">
                            <ViewInsight
                              location={location}
                              viewInsight={{
                                ...viewInsight,
                                id: viewInsightId ? viewInsightId : 0,
                                report: viewInsight.report || selectedReport,
                              }}
                              isAdding
                              isAdded={isAdded}
                              viewId={viewId}
                              skipFetch={true}
                            />
                          </GridItem>
                        );
                      },
                    )}
                  </Grid>
                )
              ) : (
                <Grid mt={4} templateColumns="repeat(3, 1fr)" gridGap={5}>
                  {selectedReportType.reportType === AI &&
                    insights?.data?.map((insight: IQuery, index: number) => {
                      const isAdded = !!viewInsights?.find(
                        (vi) => vi.queryId === insight.id,
                      );
                      return (
                        <GridItem key={index} maxW="300px" w="300px" h="300px">
                          <ViewInsight
                            location={location}
                            viewInsight={
                              {
                                id: insight.id,
                                appId: Number(appId),
                                reportId: Number(insight.reportId),
                                queryId: insight.id,
                                order: index,
                                insight: {
                                  name: insight.prompt,
                                  reportType: AI,
                                  reportName: insight.reportName,
                                },
                              } as IViewInsight
                            }
                            viewId={viewId}
                            query={insight}
                            isAdding
                            isAdded={isAdded}
                            isAI
                          />
                        </GridItem>
                      );
                    })}
                </Grid>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
