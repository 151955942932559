import { useRef, useEffect } from "react";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { Box, Flex, Spinner, StyleProps, Text } from "@chakra-ui/react";
import Typed from "typed.js";

export const JuneSpinner: React.FC<{
  spinnerProps?: StyleProps;
  backgroundProps?: StyleProps;
}> = ({ spinnerProps, backgroundProps }) => {
  return (
    <div className="relative">
      <Spinner
        size="sm"
        position="relative"
        left={"15px"}
        top="48px"
        color="#6968F4"
        zIndex={1}
        {...spinnerProps}
      />
      <Box
        h="18px"
        w="18px"
        bg="#fff"
        transform="rotate(80deg)"
        position="relative"
        top="25px"
        left="14px"
        {...backgroundProps}
      />
      <CompanyJuneLogoIcon color="#6968F4" h={10} w={10} />
    </div>
  );
};

const LOADING_TEXTS = [
  "Grabbing your data...",
  "Crunching the numbers...",
  "Almost there...",
  "Wow! This is taking a while...",
];

export const LoadingChart: React.FC<StyleProps> = ({ ...styleProps }) => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: LOADING_TEXTS,
      typeSpeed: 50,
      backSpeed: 30,
      showCursor: false,
      backDelay: 5000,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <Box
      textAlign="center"
      justifyContent="center"
      h="350px"
      bg="white"
      data-testid="insight-card-loading"
      pos="relative"
      {...styleProps}
    >
      <Box display="relative" w="100%" h="300px" mb={4}>
        <Flex
          h="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <JuneSpinner
            spinnerProps={{ top: "48px", left: "4px" }}
            backgroundProps={{ top: "25px", left: "14px" }}
          />
          <Flex mt={2} direction="column">
            <Text
              h="30px"
              ref={el}
              fontSize="sm"
              fontWeight="semibold"
              color="gray.700"
            ></Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
