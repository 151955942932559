import { config as groupListConfig } from "modules/Groups/List/config";
import { config as userListConfig } from "modules/Contacts/List/config";
import { config as companyListConfig } from "modules/Companies/List/config";
import { AppObjectType } from "core/types/AppObject";

export const useListConfig = (objectType?: AppObjectType) => {
  let _userListConfig = userListConfig;
  let _groupListConfig = groupListConfig;

  const listConfig = {
    [AppObjectType.User]: userListConfig,
    [AppObjectType.Group]: groupListConfig,
    [AppObjectType.Company]: companyListConfig,
  };

  return {
    userListConfig: _userListConfig,
    groupListConfig: _groupListConfig,
    listConfig: (objectType && listConfig[objectType]) || _userListConfig,
  };
};
