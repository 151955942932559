import { ViewContainer } from "../ViewInsight/ViewInsightsContainer";
import { ViewLocation } from "core/hooks/useViews";
import { Center, Spinner } from "@chakra-ui/react";
import { ContactActivity } from "modules/Contacts/Contact/ContactActivity";
import { useParams } from "react-router-dom";
import { ContactPages } from "modules/Contacts/Contact/ContactPages";
import { ContactMostTriggeredEvents } from "modules/Contacts/Contact/ContactMostTriggeredEvents";
import { useAppObjects } from "core/hooks/useAppObjects";
import { AppObjectType } from "core/types/AppObject";
import { IView } from "core/models/views";

export const ObjectOverviewSection: React.FC<{
  view: IView;
  isLoadingView: boolean;
  showAddModal: boolean;
  setShowAddModal: (show: boolean) => void;
  showSQLModal: boolean;
  setShowSQLModal: (show: boolean) => void;
  showAudienceModal: boolean;
  setShowAudienceModal: (show: boolean) => void;
}> = ({
  view,
  isLoadingView,
  showAddModal,
  setShowAddModal,
  showSQLModal,
  setShowSQLModal,
  showAudienceModal,
  setShowAudienceModal,
}) => {
  const { objectId } = useParams<{ objectId: string }>();
  const { activeAppObject } = useAppObjects();

  if (isLoadingView) {
    return (
      <Center h="100vh" maxH="full">
        <Spinner />
      </Center>
    );
  }

  if (!view) {
    return <div>No view found</div>;
  }

  return (
    <>
      {activeAppObject?.objectType === AppObjectType.User ? (
        <div className="flex w-full flex-col gap-2">
          <ContactActivity contactId={objectId} />
          <div className="flex flex-row gap-2">
            <ContactMostTriggeredEvents contactId={objectId} />
            <ContactPages contactId={objectId} />
          </div>
        </div>
      ) : (
        <Center maxW="full">
          <ViewContainer
            view={view}
            location={ViewLocation.Company}
            viewId={Number(view.id)}
            columns={3}
            paywallInsights
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            showSQLModal={showSQLModal}
            setShowSQLModal={setShowSQLModal}
            showAudienceModal={showAudienceModal}
            setShowAudienceModal={setShowAudienceModal}
          />
        </Center>
      )}
    </>
  );
};
