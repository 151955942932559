import { IInsightSection } from "core/types/TemplateConfig";
import { AppObjectType } from "core/types/AppObject";
import { useViewInsightManagement } from "core/hooks/useViewInsightManagement";
import { useViewInsightActions } from "core/hooks/useViewInsightActions";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { PlusIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, Tooltip } from "@chakra-ui/react";

interface IAddToViewActionProps {
  insight: IInsightSection;
  reportId: string;
}

export interface IViewActionItem {
  displayName: string;
  value: string;
  isAdded: boolean;
  viewInsightId: number | null;
  canAddInsightToView: boolean;
}

export const AddToViewAction: React.FC<IAddToViewActionProps> = ({
  insight,
  reportId,
}) => {
  const { id } = useCurrentApp();
  const { activeAppObject } = useAppObjects();

  const { items, isLoading, searchValue, setSearchValue, hasBeenPinned } =
    useViewInsightActions({
      appId: id,
      reportId,
      insightId: insight.typeId,
    });

  const { handleAddOrRemoveInsight } = useViewInsightManagement({
    appId: id,
    reportId,
    insightId: insight.typeId,
    objectType: activeAppObject?.objectType ?? AppObjectType.User,
  });

  return (
    <>
      <div className="flex items-center justify-center">
        <Popover className="relative">
          <PopoverButton>
            <Tooltip hasArrow label="Add to dashboard" placement="top">
              <Button variant="ghost" color={"gray.600"}>
                {hasBeenPinned ? (
                  <CheckCircleIcon className="size-5 text-purple-500" />
                ) : (
                  <PlusIcon className="size-5" />
                )}
              </Button>
            </Tooltip>
          </PopoverButton>
          <PopoverPanel>
            {({ close }) => (
              <>
                <Lifecycle onUnmount={() => setSearchValue("")} />
                <Command>
                  <CommandInput
                    placeholder="Search dashboards..."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                  <CommandList>
                    {isLoading ? (
                      <span className="px-2 py-1 sm:leading-4">Loading...</span>
                    ) : (
                      <>
                        {items.length === 0 && (
                          <span className="px-2 py-1 sm:leading-4">
                            No dashboards found
                          </span>
                        )}
                        {items.map((item, index) => (
                          <CommandOption
                            disabled={
                              !item.canAddInsightToView && !item.isAdded
                            }
                            key={index}
                            value={() => {
                              close();
                              handleAddOrRemoveInsight({
                                isAdded: item.isAdded,
                                viewInsightId: item.viewInsightId,
                                viewId: item.value,
                                viewName: item.displayName,
                              });
                            }}
                            rightIcon={
                              item.isAdded && (
                                <CheckIcon className="h-4 w-4 text-gray-800" />
                              )
                            }
                          >
                            <Tooltip
                              label="Maximum insights added. Remove one to add another."
                              placement="top"
                              isDisabled={
                                item.canAddInsightToView || item.isAdded
                              }
                            >
                              <div className="w-full">
                                <span className="line-clamp-1 text-ellipsis sm:leading-4">
                                  {item.displayName}
                                </span>
                              </div>
                            </Tooltip>
                          </CommandOption>
                        ))}
                      </>
                    )}
                  </CommandList>
                </Command>
              </>
            )}
          </PopoverPanel>
        </Popover>
      </div>
    </>
  );
};
