import { useState } from "react";
import { DefaultHeader } from "modules/Pages/DefaultHeader";
import SaveAudienceModal from "modules/Audiences/SaveAudienceModal/SaveAudienceModal";
import { config } from "modules/Audiences/List/config";
import { DEFAULT_FILTER_GROUPS } from "helpers/filters";
import { useGetAudiencesQuery } from "core/models/audiences";
import usePagination from "core/hooks/usePagination";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { SearchBar } from "core/components/SearchBar";
import { PageContainer } from "core/components/PageContainer";
import List from "core/components/List";
import { Button } from "@june-so/ui";
import { Stack, useDisclosure } from "@chakra-ui/react";

const SECTION = {
  name: "Audiences",
  title: "Audiences",
  description: "Save audiences and re-use them",
  howItWorksArticle:
    "https://help.june.so/en/articles/6772077-how-to-use-saved-audiences",
};

const Audiences: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState("");
  const { currentPage, previousPage, nextPage } = usePagination({
    searchQuery,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, isFetching } = useGetAudiencesQuery({
    appId,
    page: currentPage,
    query: searchQuery,
  });
  const audiences = data?.audiences || [];

  const pages = [
    { name: "Audiences", path: `/a/${appId}/audiences`, current: true },
  ];

  return (
    <PageContainer>
      <div className="flex flex-col gap-2">
        <Breadcrumbs pages={pages} />
        <DefaultHeader
          section={{
            ...SECTION,
            sectionRight: () => (
              <Button color="purple" onClick={onOpen}>
                Create audience
              </Button>
            ),
          }}
        />
      </div>
      <Stack justify="flex-start" align="flex-start" mb={4}>
        <SearchBar
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Search an audience by name..."
        />
      </Stack>

      <List
        isLoading={isLoading || isFetching}
        pagy={data?.pagy}
        config={config}
        rows={audiences}
        pagination={{
          currentPage,
          previousPage,
          nextPage,
        }}
      />
      <SaveAudienceModal
        initialAudienceFilters={DEFAULT_FILTER_GROUPS}
        isOpen={isOpen}
        onClose={onClose}
        showFilterPreview={false}
      />
    </PageContainer>
  );
};

export default Audiences;
