import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IPagy } from "core/types/Pagy";
import { IAudience } from "core/types/Audience";
import {
  IAudienceChanges,
  IAudienceLog,
  useGetAudienceChangesQuery,
  useGetAudienceLogsQuery,
} from "core/models/audienceLogs";

interface IUseAudienceLogs {
  audience: IAudience;
  page: number;
  period?: "week" | "month";
}

export function useAudienceLogs({
  audience,
  page,
  period = "week",
}: IUseAudienceLogs): {
  audienceLogs: IAudienceLog[];
  pagy: IPagy | undefined;
  changes: IAudienceChanges | undefined;
  isLoadingAudienceLogs: boolean;
  isLoadingChanges: boolean;
} {
  const { appId } = useParams<{ appId: string }>();
  const [audienceLogs, setAudienceLogs] = useState<IAudienceLog[]>([]);

  const { data, isLoading: isLoadingAudienceLogs } = useGetAudienceLogsQuery({
    appId: Number(appId),
    audienceId: Number(audience?.id),
    appObjectId: audience?.appObject?.id,
    page,
    period,
  });
  const { data: changes, isLoading: isLoadingChanges } =
    useGetAudienceChangesQuery({
      appId: Number(appId),
      audienceId: Number(audience?.id),
      appObjectId: audience?.appObject?.id,
    });

  useEffect(() => {
    if (data?.audienceLogs) {
      const allAudienceLogs =
        page === 1
          ? data?.audienceLogs
          : [...audienceLogs, ...(data?.audienceLogs || [])];

      setAudienceLogs(allAudienceLogs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    audienceLogs,
    pagy: data?.pagy,
    changes,
    isLoadingAudienceLogs,
    isLoadingChanges,
  };
}
