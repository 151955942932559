import humps from "humps";
import { IPagy } from "core/types/Pagy";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedProperties } from "type-fest";

export interface IPageRow {
  url: string;
  totalCount: number;
  uniqueCount: number;
}

interface GetPagesArgs {
  appId: number | string;
  page: number | string;
  query: string;
}

interface IGetPagesResponse {
  pages: IPageRow[];
  pagy: IPagy;
}

export const pagesApi = createApi({
  baseQuery,
  reducerPath: "pagesApi",
  tagTypes: ["Page"],
  endpoints: (builder) => ({
    getPages: builder.query<IGetPagesResponse, GetPagesArgs>({
      query: ({ appId, page, query }) => ({
        url: "/pages",
        params: { app_id: appId, page, query },
      }),
      providesTags: ["Page"],
      transformResponse: (
        response: SnakeCasedProperties<IGetPagesResponse>,
      ): IGetPagesResponse => humps.camelizeKeys(response) as IGetPagesResponse,
    }),
  }),
});

export const { useGetPagesQuery } = pagesApi;
