import { trackEvent } from "core/helpers/trackEvent";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { UninstallModal } from "../Hubspot/UninstallModal";

export const Action = ({
  hasAttioInstalled,
  onOpenConfirmation,
  isConfirmationOpen,
  onCloseConfirmation,
  onDeleteSettings,
  id,
}: {
  hasAttioInstalled: boolean;
  hasAttioFeature: boolean;
  onOpenConfirmation: () => void;
  isConfirmationOpen: boolean;
  onCloseConfirmation: () => void;
  onDeleteSettings: () => void;
  id: number;
}) => {
  return (
    <div className="flex">
      <Flex align="center" gridGap={2}>
        {hasAttioInstalled ? (
          <>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<EllipsisVerticalIcon className="h-5 w-5" />}
                colorScheme="gray"
              />
              <MenuList boxShadow="lg">
                <MenuItem
                  icon={<TrashIcon className="h-4 w-4" />}
                  onClick={onOpenConfirmation}
                  color="red.500"
                  fontWeight="medium"
                >
                  Uninstall
                </MenuItem>
              </MenuList>
            </Menu>

            <UninstallModal
              isOpen={isConfirmationOpen}
              onClose={onCloseConfirmation}
              onUninstall={() => {
                trackEvent({
                  eventName: "clicked_attio_uninstall",
                  appId: id,
                });
                onDeleteSettings();
              }}
              integration="attio"
            />
          </>
        ) : (
          <Tooltip>
            <Button
              onClick={() => {
                trackEvent({ eventName: "clicked_install_attio", appId: id });
                window.location.replace(
                  `${import.meta.env.VITE_API_HOST}/auth/attio`,
                );
              }}
              colorScheme="purple"
            >
              Connect Attio
            </Button>
          </Tooltip>
        )}
      </Flex>
    </div>
  );
};
