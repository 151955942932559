import { useState } from "react";
import { cx } from "helpers/cx";
import { IAudience } from "core/types/Audience";
import { AppObjectType } from "core/types/AppObject";
import { AudienceStatus } from "core/models/audienceLogs";
import usePagination from "core/hooks/usePagination";
import { useAudienceLogs } from "core/hooks/useAudienceLogs";
import { Toggle, ToggleButton } from "core/components/Toggle";
import SingleGroup from "core/components/Group/SingleGroup";
import { Drilldown } from "core/components/Drilldown";
import SingleContact from "core/components/Contact/SingleContact";
import { Button, useDisclosure } from "@chakra-ui/react";
import { SingleCompany } from "./Company/SingleCompany";

interface IChangesPill {
  changes?: {
    entered: number;
    exited: number;
  };
}

const ChangesPill: React.FC<IChangesPill> = ({ changes }) => {
  const noChanges = changes?.entered === 0 && changes?.exited === 0;

  return (
    <div
      className={cx(
        "flex items-center gap-2 py-2 text-xs",
        noChanges && "text-gray-600",
      )}
    >
      {noChanges ? (
        "No changes this week"
      ) : (
        <>
          {changes?.entered === 0 ? (
            <span className="text-gray-400">+{changes?.entered}</span>
          ) : (
            <span className="text-green-500">+{changes?.entered}</span>
          )}
          {changes?.exited === 0 ? (
            <span className="text-gray-400">-{changes?.exited}</span>
          ) : (
            <span className="text-orange-500">-{changes?.exited}</span>
          )}
          <span className="text-gray-600"> this week</span>
        </>
      )}
    </div>
  );
};

export const AudienceLogs: React.FC<{ audience: IAudience }> = ({
  audience,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentPage, nextPage, setPage } = usePagination({
    withURL: false,
    persist: false,
  });

  const [period, setPeriod] = useState<"week" | "month">("week");
  const {
    audienceLogs,
    changes,
    pagy,
    isLoadingAudienceLogs,
    isLoadingChanges,
  } = useAudienceLogs({
    audience,
    page: currentPage,
    period,
  });
  const noChanges = changes?.entered === 0 && changes?.exited === 0;

  return (
    <>
      <Button
        size="xs"
        isLoading={isLoadingChanges}
        onClick={noChanges ? undefined : onOpen}
      >
        <ChangesPill changes={changes} />
      </Button>
      <Drilldown isOpen={isOpen} onClose={onClose}>
        {(Drilldown) => (
          <>
            <Drilldown.Title
              title="Audience changes"
              isLoading={isLoadingAudienceLogs}
            />
            <div className="flex w-full items-center justify-center">
              <Toggle h={"2rem"} mt={6}>
                <ToggleButton
                  isDisabled={false}
                  isSelected={period === "week"}
                  onToggle={() => {
                    setPage(1);
                    setPeriod("week");
                  }}
                >
                  This week
                </ToggleButton>
                <ToggleButton
                  isDisabled={false}
                  isSelected={period === "month"}
                  onToggle={() => {
                    setPage(1);
                    setPeriod("month");
                  }}
                >
                  This month
                </ToggleButton>
              </Toggle>
            </div>
            <Drilldown.Body pt={4}>
              <Drilldown.List
                key={`${audienceLogs.length}`}
                items={audienceLogs}
                hasMore={audienceLogs.length < Number(pagy?.count)}
                loadNext={() => nextPage()}
                isLoading={isLoadingAudienceLogs}
                emptyStateText={`No audience changes this week`}
                itemRenderer={({ item }) => (
                  <div className="flex items-center justify-between gap-2">
                    {audience.appObject.objectType === AppObjectType.User ? (
                      <SingleContact
                        id={item.entityId}
                        hideHoverBorder={true}
                        hideExternalLinkIcon={true}
                      />
                    ) : (
                      <>
                        {audience.appObject.objectType ===
                          AppObjectType.Group && (
                          <SingleGroup
                            id={item.entityId}
                            hideHoverBorder={true}
                            hideExternalLinkIcon={true}
                          />
                        )}
                        {audience.appObject.objectType ===
                          AppObjectType.Company && (
                          <SingleCompany
                            id={item.entityId}
                            hideHoverBorder={true}
                            hideExternalLinkIcon={true}
                          />
                        )}
                      </>
                    )}
                    {item.status === AudienceStatus.Entered && (
                      <span className="rounded bg-green-100 px-2 py-0.5 text-xs text-green-600">
                        Entered{" "}
                        {new Date(item.createdAt).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    )}
                    {item.status === AudienceStatus.Exited && (
                      <span className="rounded bg-orange-100 px-2 py-0.5 text-xs text-orange-500">
                        Exited{" "}
                        {new Date(item.createdAt).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    )}
                  </div>
                )}
              />
            </Drilldown.Body>
          </>
        )}
      </Drilldown>
    </>
  );
};
