interface IPageNameCellProps {
  url?: string;
}

export const PageNameCell: React.FC<{
  props: IPageNameCellProps;
}> = ({ props: { url } }) => {
  return (
    <div className="inline-flex max-w-full rounded-md border border-gray-50 bg-white p-1 px-2 text-sm font-medium text-primary shadow-sm">
      <p className="max-w-md truncate text-green-500" title={url}>
        {url}
      </p>
    </div>
  );
};
