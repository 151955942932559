import { useNavigate, useParams } from "react-router-dom";
import { ObjectList } from "modules/People/ObjectList";
import { ExportCsv } from "modules/People/ExportCsv";
import { EMPTY_FILTER_GROUPS } from "helpers/filters";
import { IAudience } from "core/types/Audience";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { useGroupContactsList } from "core/hooks/useGroupContactsList";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { ErrorMessage } from "core/components/List/ErrorMessage";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Button, Flex, Stack } from "@chakra-ui/react";

export const GroupContacts: React.FC<{
  showListActions?: boolean;
  useInternalUsers?: boolean;
}> = ({ showListActions, useInternalUsers }) => {
  const { groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const { id: appId, audience: internalUsersAudience } = useCurrentApp();
  const navigate = useNavigate();
  const { hasHubspotInstalled } = useHubspotSettings();
  const { hasAttioInstalled } = useAttioSettings();

  const groupId = decodeURIComponent(encodedGroupId);

  function onSyncToCrm() {
    if (hasAttioInstalled) {
      navigate(`/a/${appId}/integrations/attio/${userAppObject.objectType}`);
    } else if (hasHubspotInstalled) {
      navigate(`/a/${appId}/integrations/hubspot/${userAppObject.objectType}`);
    } else {
      navigate(`/a/${appId}/integrations/attio`);
    }
  }

  const audience = useInternalUsers
    ? internalUsersAudience
    : (EMPTY_FILTER_GROUPS as unknown as IAudience);

  const {
    userAppObject,
    listConfig,
    audienceFilters,
    onSetAudienceFilters,
    searchQuery,
    setSearchQuery,
    pagy,
    contacts,
    isLoading,
    error,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    totalCount,
    matchedCount,
  } = useGroupContactsList({ groupId, audience });

  return (
    <div className="flex h-full flex-col justify-start ">
      {showListActions && (
        <Stack justify="flex-start" align="flex-start">
          <Flex
            data-id-contacts-list-container
            w="full"
            justify="space-between"
          >
            <Flex justifyContent="space-between" align="center" mb={4}>
              <AudienceFilters
                key={userAppObject?.slug}
                audienceFilters={audienceFilters}
                setAudienceFilters={onSetAudienceFilters}
                view={userAppObject?.slug}
              />
            </Flex>
            <div className="flex gap-2">
              {userAppObject && (
                <ExportCsv
                  appObject={userAppObject}
                  audienceFilters={audienceFilters}
                  searchQuery={searchQuery}
                  groupId={groupId}
                />
              )}
              <Button onClick={onSyncToCrm} colorScheme="purple">
                <div className="flex px-2">
                  <div className="flex items-center gap-2">
                    <ArrowPathIcon className="h-4 w-4" />
                    <p>Sync to CRM</p>
                  </div>
                </div>
              </Button>
            </div>
          </Flex>
        </Stack>
      )}
      <div className="h-full pb-10">
        {userAppObject && error && <ErrorMessage />}
        {userAppObject && !error && (
          <ObjectList
            appObject={userAppObject}
            listConfig={listConfig}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            entities={contacts}
            isLoading={isLoading}
            pagy={pagy}
            currentPage={currentPage}
            previousPage={previousPage}
            nextPage={nextPage}
            audienceFilters={audience}
            showSearchBar={false}
            showAddTraitButton={false}
            showExportCsvButton={false}
            skipPagination={skipPagination}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            totalCount={totalCount}
            matchedCount={matchedCount}
          />
        )}
      </div>
    </div>
  );
};
