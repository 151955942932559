import { cx } from "helpers/cx";
import { motion } from "framer-motion";

export const Shadow: React.FC<{
  top?: string;
  right?: string;
  height?: string;
}> = ({ top = "0", right = "10", height = "36" }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        style={{ top, right: "-20px", height: `${height}px`, width: "20px" }}
        className={cx(
          right &&
            `absolute w-[20px] bg-gradient-to-r from-gray-100 to-transparent`,
        )}
      ></div>
    </motion.div>
  );
};
