import { getFilterCount } from "routes/View";
import React, { useEffect, useState } from "react";
import { AudienceHeader } from "modules/Audiences/Audience/AudienceHeader";
import { AnimatePresence, motion } from "framer-motion";
import { IAudience } from "core/types/Audience";
import { AppObjectType } from "core/types/AppObject";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { Level } from "core/models/people";
import { useUpdateAudienceMutation } from "core/models/audiences";
import { useToast } from "core/hooks/useToast";
import { usePeopleList } from "core/hooks/usePeopleList";
import { usePeopleCount } from "core/hooks/usePeopleCount";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAudienceAlert } from "core/hooks/useAudienceAlert";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { PageContainer } from "core/components/PageContainer";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { Badge, Button } from "@chakra-ui/react";
import { PeopleSelect } from "./People/Select";
import { ObjectList } from "./People/ObjectList";
import { AudienceAlertButton } from "./Audiences/Alerts/Button";

export enum People {
  Contacts = "contacts",
  Groups = "groups",
}

const Audience: React.FC<{ audience: IAudience }> = ({ audience }) => {
  const toast = useToast();
  const { id: appId } = useCurrentApp();
  const { setActiveAppObject } = useAppObjects();
  const [showAudienceFilters, setShowAudienceFilters] = useState(() => {
    const stored = localStorage.getItem("showAudienceFiltersOnAudience");
    return stored !== null ? JSON.parse(stored) : true;
  });

  const {
    activeAppObject,
    audienceFilters,
    searchQuery,
    setPage,
    setSearchQuery,
    onSetAudienceFilters,
    listConfig,
    entities,
    pagy,
    isLoading,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = usePeopleList({ skipUrlHashing: true });
  const { matchedCount, totalCount, isLoadingCount } = usePeopleCount({
    audienceFilters,
    searchQuery,
    level:
      activeAppObject?.objectType === AppObjectType.User
        ? Level.User
        : Level.Group,
    groupType:
      activeAppObject?.objectType === AppObjectType.Group
        ? GroupType.Group
        : activeAppObject?.objectType === AppObjectType.Company
          ? GroupType.Company
          : undefined,
  });
  const { alert, isEnabled } = useAudienceAlert({
    audienceId: Number(audience?.id),
  });

  useEffect(() => {
    if (audience?.appObject) {
      setActiveAppObject(audience.appObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audience?.appObject?.id]);

  useEffect(() => {
    localStorage.setItem(
      "showAudienceFiltersOnAudience",
      JSON.stringify(showAudienceFilters),
    );
  }, [showAudienceFilters]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | null>(null);

  const audienceFiltersLength = audienceFilters?.filterGroups?.length || 0;

  const hasUnsavedChanges =
    JSON.stringify(audienceFilters?.filterGroups) !==
      JSON.stringify(audience?.filterGroups) ||
    audience?.name !== name ||
    audience?.description !== description ||
    audienceFilters?.joinOperator !== audience?.joinOperator ||
    audience?.appObject?.id !== activeAppObject?.id;

  const [updateAudience] = useUpdateAudienceMutation();
  const handleSave = () => {
    if (!name && !audienceFilters) return;
    if (alert && isEnabled) {
      toast({
        title: "Failed to save audience",
        description: `This audience has an active alert for ${alert?.appObject?.pluralName?.toLocaleLowerCase()}. Please disable the alert before making changes.`,
        status: "error",
      });
      return;
    }

    setIsEditing(false);
    updateAudience({
      audienceId: audience.id!,
      appId,
      name,
      description: description?.length ? description : null,
      audienceFilters,
      appObjectId: activeAppObject?.id,
    });
  };

  useEffect(() => {
    if (audience) {
      setName(audience.name ?? "");
      setDescription(audience.description ?? null);
      if (audience.filterGroups?.length > 0) {
        onSetAudienceFilters({
          filterGroups: audience.filterGroups,
          joinOperator: audience.joinOperator,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audience]);

  const audienceHeaderProps = {
    isEditing,
    setIsEditing,
    name,
    setName,
    description,
    setDescription,
    audience,
    audienceFiltersLength,
    audienceFilters,
    setAudienceFilters: onSetAudienceFilters,
  };

  const pages = [
    { name: "Audiences", path: `/a/${appId}/audiences`, current: false },
    { name: audience?.name ?? "Audience", path: "#", current: true },
  ];

  return (
    <div className="flex gap-1">
      <div className="w-full">
        <PageContainer
          className="w-full min-w-full max-w-full"
          px="60px"
          maxW="full"
          minW="container.lg"
          overflow="overflow-y-auto"
        >
          <div className="mb-2 flex justify-between">
            <Breadcrumbs pages={pages} />
          </div>
          <div className="mt-6 flex items-start justify-between">
            <div className="flex flex-col">
              <AudienceHeader {...audienceHeaderProps} audience={audience!} />
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                leftIcon={
                  <AdjustmentsVerticalIcon className="h-[17px] w-[17px]" />
                }
                onClick={() => setShowAudienceFilters(!showAudienceFilters)}
              >
                {showAudienceFilters
                  ? "Hide audience filters"
                  : "Show audience filters"}{" "}
                <Badge
                  ml={2}
                  variant="subtle"
                  colorScheme="purple"
                  rounded="md"
                  fontSize="xs"
                >
                  {getFilterCount(audienceFilters)}
                </Badge>
              </Button>
              <AudienceAlertButton />
              <Button
                colorScheme="purple"
                onClick={handleSave}
                isDisabled={!hasUnsavedChanges}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="flex items-start justify-between">
            <AnimatePresence>
              {showAudienceFilters && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.1, ease: "easeInOut" }}
                  className="flex w-full"
                >
                  <div className="mb-2 flex w-full items-start justify-between">
                    <div className="mr-2">
                      <PeopleSelect
                        audienceFilters={audienceFilters}
                        searchQuery={searchQuery}
                        setPage={setPage}
                      />
                    </div>
                    <AudienceFilters
                      audienceFilters={audienceFilters}
                      setAudienceFilters={onSetAudienceFilters}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {activeAppObject && (
            <ObjectList
              appObject={activeAppObject}
              listConfig={listConfig}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              entities={entities}
              isLoading={isLoading}
              pagy={pagy}
              currentPage={currentPage}
              previousPage={previousPage}
              nextPage={nextPage}
              audienceFilters={audienceFilters}
              skipPagination={skipPagination}
              sortBy={sortBy}
              sortOrder={sortOrder}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              maxHeight={showAudienceFilters ? "max-h-[75vh]" : "max-h-[80vh]"}
              matchedCount={matchedCount}
              totalCount={totalCount}
              isLoadingCount={isLoadingCount}
            />
          )}
        </PageContainer>
      </div>
    </div>
  );
};

export default Audience;
