import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ViewContainer } from "modules/ViewInsight/ViewInsightsContainer";
import { Overview } from "modules/Groups/Group/Overview";
import { GroupContacts } from "modules/Contacts/GroupContacts";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { cx } from "helpers/cx";
import { ViewLocation } from "core/hooks/useViews";
import { useGroup } from "core/hooks/useGroup";
import { useCompanyView } from "core/hooks/useCompanyView";
import { useCompanyColumns } from "core/hooks/useCompanyColumns";
import { PageContainer } from "core/components/PageContainer";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import {
  BanknotesIcon,
  BellAlertIcon,
  BugAntIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  HomeIcon,
  PresentationChartLineIcon,
  QueueListIcon,
  TrophyIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon,
  PaperClipIcon,
  TagIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Divider,
} from "@chakra-ui/react";

const assignees = [
  { name: "Unassigned", value: null },
  {
    name: "Wade Cooper",
    value: "wade-cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More items...
];
const labels = [
  { name: "Unlabelled", value: null },
  { name: "Engineering", value: "engineering" },
  // More items...
];
const dueDates = [
  { name: "No due date", value: null },
  { name: "Today", value: "today" },
  // More items...
];

enum Tab {
  Overview = "Overview",
  ProductUsage = "Product usage",
  Users = "Users",
  Feed = "Feed",
  Notes = "Notes",
  Alerts = "Alerts",
  Issues = "Issues",
  Goals = "Goals",
  Billing = "Billing",
  Conversations = "Conversations",
}

const TABS = [
  { name: Tab.Overview, icon: HomeIcon },
  {
    name: Tab.ProductUsage,

    icon: PresentationChartLineIcon,
  },
  { name: Tab.Users, icon: UsersIcon },
  { name: Tab.Feed, icon: QueueListIcon },
  { name: Tab.Notes, icon: DocumentTextIcon },
  {
    name: Tab.Conversations,

    icon: ChatBubbleBottomCenterTextIcon,
  },
  { name: Tab.Alerts, icon: BellAlertIcon },
  { name: Tab.Issues, icon: BugAntIcon },
  { name: Tab.Goals, icon: TrophyIcon },
  { name: Tab.Billing, icon: BanknotesIcon },
] as {
  name: Tab;
  icon: React.ElementType;
}[];

export const NewGroupPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(Tab.Overview);
  const { view } = useCompanyView();
  const { columns } = useCompanyColumns();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const [assigned, setAssigned] = useState(assignees[0]);
  const [labelled, setLabelled] = useState(labels[0]);
  const [dated, setDated] = useState(dueDates[0]);
  const { appId, groupId: encodedGroupId } = useParams();
  const groupId = decodeURIComponent(encodedGroupId);
  const { fetchGroup, group } = useGroup({
    id: groupId,
  });

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  return (
    <PageContainer px="0">
      <div className="flex flex-col gap-4">
        <div>
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon className="h-3 w-3 text-gray-500" />}
          >
            <BreadcrumbItem className="text-sm text-gray-500">
              <BreadcrumbLink
                href="#"
                className="no-underline hover:no-underline"
              >
                Workspaces
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem className="text-sm text-gray-500" isCurrentPage>
              <BreadcrumbLink
                href="#"
                className="no-underline hover:no-underline"
              >
                Ethnio
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Card shadow="none">
            <CardBody px="0">
              <div className="flex w-full justify-between">
                <div className="flex gap-4">
                  <div className="flex gap-5">
                    <span className="relative inline-block h-24 w-24">
                      <img
                        alt=""
                        src="https://assets.ethn.io/assets/logo-94c4bf01.svg"
                        className="size-24 rounded-md bg-gray-200 p-3"
                      />
                      <span className="absolute bottom-0 right-0 block translate-x-1/2 translate-y-1/2 transform rounded-full border-2 border-white">
                        <span className="block size-4 rounded-full bg-green-300" />
                      </span>
                    </span>
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-3">
                        <p className="text-2xl font-bold">Ethnio</p>
                        <div className="flex items-center gap-2">
                          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                            <button
                              type="button"
                              className="group relative mr-1 size-3.5 rounded-sm"
                            >
                              <BanknotesIcon className="size-3.5" />
                            </button>
                            $10k
                          </span>
                          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                            <button
                              type="button"
                              className="group relative mr-1 size-3.5 rounded-sm"
                            >
                              <UsersIcon className="size-3.5" />
                            </button>
                            5 users
                          </span>
                          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-600 ring-1 ring-inset ring-yellow-500/10">
                            <button
                              type="button"
                              className="group relative mr-1 size-3.5 rounded-sm font-bold"
                            >
                              S
                            </button>
                            Pro customer
                          </span>
                          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-600 ring-1 ring-inset ring-purple-500/10">
                            <button
                              type="button"
                              className="group relative mr-1 size-3.5 rounded-sm"
                            >
                              <TrophyIcon className="size-3.5" />
                            </button>
                            4/4
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-6">
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Domain</p>
                          <div className="flex items-center gap-1">
                            <p className="text-sm font-medium">ethn.io</p>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Health</p>
                          <div className="flex items-center gap-1">
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              Great
                            </span>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Last seen</p>
                          <div className="flex items-center gap-1">
                            <p className="text-sm font-medium">5 hours ago</p>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Signed up</p>
                          <div className="flex items-center gap-1">
                            <p className="text-sm font-medium">2 months ago</p>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Time zone</p>
                          <div className="flex items-center gap-1">
                            <p className="text-sm font-medium">PST (UTC-8)</p>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Slack Channel</p>
                          <div className="flex items-center gap-1">
                            <a
                              target="_blank"
                              // Channel ID: C07URKHK9S5
                              // team name: june-siege.slack.com
                              href="https://june-siege.slack.com/archives/C07URKHK9S5"
                              rel="noreferrer"
                            >
                              <p className="text-sm font-medium underline">
                                #ethnio-x-june
                              </p>
                            </a>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Renewal date</p>
                          <div className="flex items-center gap-1">
                            <p className="text-sm font-medium">June 1, 2025</p>
                          </div>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-gray-600">Account owner</p>
                          <div className="flex items-center gap-1">
                            <img
                              alt=""
                              src="https://ca.slack-edge.com/T01AS9R1RDL-U026XH5L6KD-ded1cda4afdc-512"
                              className="inline-block size-5 rounded-full"
                            />
                            <p className="text-sm font-medium">Alberto</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2">
                  <Button colorScheme="purple">Edit</Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div>
          <div>
            <div className="hidden sm:block">
              <nav aria-label="Tabs" className="flex space-x-4">
                {TABS.map((tab) => (
                  <div
                    onClick={() => setActiveTab(tab.name)}
                    key={tab.name}
                    aria-current={activeTab === tab.name ? "page" : undefined}
                    className={cx(
                      activeTab === tab.name
                        ? "bg-gray-100 text-gray-700"
                        : "text-gray-500 hover:text-gray-700",
                      "cursor-pointer rounded-md px-3 py-2 text-sm font-medium",
                    )}
                  >
                    <div className="flex items-center gap-1">
                      <tab.icon className="size-5" />
                      {tab.name}
                      {tab.name === Tab.Issues && (
                        <span
                          className={cx(
                            "bg-red-100 text-red-600",
                            "ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block",
                          )}
                        >
                          2
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          {activeTab === Tab.Overview && <Overview group={group} />}
          {activeTab === Tab.ProductUsage && (
            <div className="flex flex-col">
              {view && (
                <ViewContainer
                  view={view}
                  location={ViewLocation.Company}
                  viewId={Number(view.id)}
                  columns={columns}
                  width={columns === 3 ? "315px" : "385px"}
                  paywallInsights
                  showAddModal={showAddModal}
                  setShowAddModal={setShowAddModal}
                  showSQLModal={showSQLModal}
                  setShowSQLModal={setShowSQLModal}
                />
              )}
            </div>
          )}
          {activeTab === Tab.Users && (
            <div className="flex flex-col">
              <GroupContacts
                key={JSON.stringify(view)}
                showListActions={false}
                useInternalUsers={view?.useInternalUsers}
              />
            </div>
          )}
          {activeTab === Tab.Feed && (
            <div className="flex flex-col">
              {group && (
                <ContactEventLog
                  key={JSON.stringify(view)}
                  appId={Number(appId)}
                  groupId={groupId}
                  groupType={GroupType.Group}
                  useInternalUsers={view?.useInternalUsers}
                />
              )}
            </div>
          )}
          {activeTab === Tab.Notes && (
            <div className="flex w-full max-w-2xl flex-col justify-center">
              <form action="#" className="relative">
                <div className="border-1 rounded-lg border-gray-200 bg-white outline outline-0 -outline-offset-1 outline-gray-100 focus-within:outline-none focus-within:outline focus-within:outline-0 focus-within:-outline-offset-2">
                  <label htmlFor="title" className="sr-only">
                    Title
                  </label>
                  <input
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Title"
                    className="block w-full border-none px-3 pt-2.5 text-lg font-medium text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0"
                  />
                  <label htmlFor="description" className="sr-only">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows={2}
                    placeholder="Write a description..."
                    className="block w-full resize-none border-none px-3 py-1.5 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                    defaultValue={""}
                  />

                  {/* Spacer element to match the height of the toolbar */}
                  <div aria-hidden="true">
                    <div className="py-2">
                      <div className="h-9" />
                    </div>
                    <div className="h-px" />
                    <div className="py-2">
                      <div className="py-px">
                        <div className="h-9" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-px bottom-0">
                  {/* Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. */}
                  <div className="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
                    <Listbox
                      as="div"
                      value={assigned}
                      onChange={setAssigned}
                      className="shrink-0"
                    >
                      <Label className="sr-only">Assign</Label>
                      <div className="relative">
                        <ListboxButton className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                          {assigned.value === null ? (
                            <UserCircleIcon
                              aria-hidden="true"
                              className="size-5 shrink-0 text-gray-300 sm:-ml-1"
                            />
                          ) : (
                            <img
                              alt=""
                              src={assigned.avatar}
                              className="size-5 shrink-0 rounded-full"
                            />
                          )}

                          <span
                            className={cx(
                              assigned.value === null ? "" : "text-gray-900",
                              "hidden truncate sm:ml-2 sm:block",
                            )}
                          >
                            {assigned.value === null ? "Assign" : assigned.name}
                          </span>
                        </ListboxButton>

                        <ListboxOptions
                          transition
                          className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow outline outline-1 outline-black/5 data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                          {assignees.map((assignee) => (
                            <ListboxOption
                              key={assignee.value}
                              value={assignee}
                              className="cursor-default select-none bg-white px-3 py-2 data-[focus]:relative data-[focus]:bg-gray-100 data-[focus]:hover:outline-none"
                            >
                              <div className="flex items-center">
                                {assignee.avatar ? (
                                  <img
                                    alt=""
                                    src={assignee.avatar}
                                    className="size-5 shrink-0 rounded-full"
                                  />
                                ) : (
                                  <UserCircleIcon
                                    aria-hidden="true"
                                    className="size-5 shrink-0 text-gray-400"
                                  />
                                )}

                                <span className="ml-3 block truncate font-medium">
                                  {assignee.name}
                                </span>
                              </div>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    </Listbox>

                    <Listbox
                      as="div"
                      value={labelled}
                      onChange={setLabelled}
                      className="shrink-0"
                    >
                      <Label className="sr-only">Add a label</Label>
                      <div className="relative">
                        <ListboxButton className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                          <TagIcon
                            aria-hidden="true"
                            className={cx(
                              labelled.value === null
                                ? "text-gray-300"
                                : "text-gray-500",
                              "size-5 shrink-0 sm:-ml-1",
                            )}
                          />
                          <span
                            className={cx(
                              labelled.value === null ? "" : "text-gray-900",
                              "hidden truncate sm:ml-2 sm:block",
                            )}
                          >
                            {labelled.value === null ? "Label" : labelled.name}
                          </span>
                        </ListboxButton>

                        <ListboxOptions
                          transition
                          className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow outline outline-1 outline-black/5 data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                          {labels.map((label) => (
                            <ListboxOption
                              key={label.value}
                              value={label}
                              className="cursor-default select-none bg-white px-3 py-2 data-[focus]:relative data-[focus]:bg-gray-100 data-[focus]:hover:outline-none"
                            >
                              <div className="flex items-center">
                                <span className="block truncate font-medium">
                                  {label.name}
                                </span>
                              </div>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    </Listbox>

                    <Listbox
                      as="div"
                      value={dated}
                      onChange={setDated}
                      className="shrink-0"
                    >
                      <Label className="sr-only">Add a due date</Label>
                      <div className="relative">
                        <ListboxButton className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                          <CalendarIcon
                            aria-hidden="true"
                            className={cx(
                              dated.value === null
                                ? "text-gray-300"
                                : "text-gray-500",
                              "size-5 shrink-0 sm:-ml-1",
                            )}
                          />
                          <span
                            className={cx(
                              dated.value === null ? "" : "text-gray-900",
                              "hidden truncate sm:ml-2 sm:block",
                            )}
                          >
                            {dated.value === null ? "Due date" : dated.name}
                          </span>
                        </ListboxButton>

                        <ListboxOptions
                          transition
                          className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow outline outline-1 outline-black/5 data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                          {dueDates.map((dueDate) => (
                            <ListboxOption
                              key={dueDate.value}
                              value={dueDate}
                              className="cursor-default select-none bg-white px-3 py-2 data-[focus]:relative data-[focus]:bg-gray-100 data-[focus]:hover:outline-none"
                            >
                              <div className="flex items-center">
                                <span className="block truncate font-medium">
                                  {dueDate.name}
                                </span>
                              </div>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    </Listbox>
                  </div>
                  <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
                    <div className="flex">
                      <button
                        type="button"
                        className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
                      >
                        <PaperClipIcon
                          aria-hidden="true"
                          className="-ml-1 mr-2 size-5 group-hover:text-gray-500"
                        />
                        <span className="text-sm italic text-gray-500 group-hover:text-gray-600">
                          Attach a file
                        </span>
                      </button>
                    </div>
                    <div className="shrink-0">
                      <button
                        type="submit"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeTab === Tab.Conversations && (
            <div className="flex flex-col">Conversations</div>
          )}
          {activeTab === Tab.Alerts && (
            <div className="flex flex-col">Alerts</div>
          )}
          {activeTab === Tab.Issues && (
            <div className="flex flex-col">Issues</div>
          )}
          {activeTab === Tab.Goals && (
            <div className="flex flex-col">Goals</div>
          )}
          {activeTab === Tab.Billing && (
            <div className="flex flex-col">Billing</div>
          )}
        </div>
      </div>
    </PageContainer>
  );
};
