export enum TextAlign {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export interface Column {
  name: string;
}

export interface DataRow {
  [key: string]: unknown;
}

export interface IDataTable {
  data: DataRow[];
  columns: Column[];
  page?: number;
  setPage?: (page: number) => void;
  hasMore?: boolean;
}
