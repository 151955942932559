import humps from "humps";
import { transformFilterGroups } from "helpers/params";
import { IAudienceFilters } from "core/types/Audience";
import { baseQuery } from "core/initializers/rtk";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { createApi } from "@reduxjs/toolkit/query/react";
import type { SnakeCasedPropertiesDeep } from "type-fest";

export enum Level {
  User = "user",
  Company = "company",
  Group = "group",
}

export interface IGetCountArgs {
  appId: number;
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  groupId?: string;
  level: Level;
  groupType?: GroupType;
}

export type IGetCountResponse = {
  matchedCount: number;
  totalCount: number;
};

export const peopleApi = createApi({
  baseQuery,
  reducerPath: "peopleApi",
  tagTypes: ["People"],
  endpoints: (builder) => ({
    getCount: builder.query<IGetCountResponse, IGetCountArgs>({
      query: ({
        appId,
        audienceFilters,
        searchQuery,
        groupId,
        level,
        groupType,
      }) => ({
        url: "/people/count",
        params: humps.decamelizeKeys({
          appId,
          filterGroups: JSON.stringify(
            transformFilterGroups(audienceFilters.filterGroups),
          ),
          joinOperator: audienceFilters.joinOperator,
          query: searchQuery,
          groupId,
          level,
          groupType,
        }),
      }),
      providesTags: ["People"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetCountResponse>,
      ): IGetCountResponse => humps.camelizeKeys(response) as IGetCountResponse,
    }),
  }),
});

export const { useGetCountQuery } = peopleApi;
