import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ThresholdType } from "modules/Events/List/EventAlertEditor";
import { isEqual } from "lodash";
import { AppObjectType } from "core/types/AppObject";
import { IAlert, ICreateAlert, IUpdateAlert } from "core/types/Alert";
import {
  useCreateAlertMutation,
  useUpdateAlertMutation,
} from "core/models/alerts";
import { useToast } from "core/hooks/useToast";
import { useAppObjects } from "core/hooks/useAppObjects";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import { IViewInsight } from "../models/viewInsights";

const STATE = { enabled: 1, disabled: 0 };

export function useEditSlackViewInsightAlert({
  viewInsight,
  alert,
}: {
  viewInsight: IViewInsight;
  alert?: IAlert;
}) {
  const toast = useToast();
  const { appId } = useParams();
  const { activeAppObject } = useAppObjects();
  const [updateAlert] = useUpdateAlertMutation();
  const [createAlert] = useCreateAlertMutation();

  const [state, setState] = useState("SAVE");
  const [hasChanges, setHasChanges] = useState(false);
  const [alertChanges, setAlertChanges] = useState<IAlert>({
    id: 0,
    created: false,
    setup: {},
    alertableId: Number(viewInsight.id),
    appId: Number(appId),
    frequency: 0,
    alertableType: "ViewInsight",
    state: STATE.enabled,
    enrichmentEnabled: false,
    thresholdType: ThresholdType.Recurring,
    threshold: 0,
  });

  useEffect(() => {
    if (alert?.created) setAlertChanges(alert);
  }, [alert]);

  useEffect(() => {
    setHasChanges(!isEqual(alert, alertChanges));
  }, [alert, alertChanges]);

  useEffect(() => {
    switch (true) {
      case alert?.created === false:
        return setState("SAVE");
      case alert?.state === STATE.disabled:
        return setState("ENABLE");
      case alert?.created === true && hasChanges:
        return setState("UPDATE");
      case alert?.created === true &&
        alert?.state === STATE.enabled &&
        !hasChanges:
        return setState("PAUSE");
      default:
        return setState("SAVE");
    }
  }, [alert, hasChanges]);

  const onCancel = (onClose: () => void) => {
    if (alert?.created) {
      setAlertChanges(alert);
    } else {
      onClose();
    }
  };

  const onSave = () => {
    window.analytics.track(
      "slack_view_insight_alert_editor_save_clicked",
      {},
      { context: { groupId: appId } },
    );

    if (!alertChanges.slackChannelId) {
      return toast({
        title: "Select a Slack channel",
        description:
          "You need to Select a Slack channel to continue to enable the alert",
        status: "error",
      });
    }

    const alertToCreate: ICreateAlert = {
      appId: String(alertChanges.appId),
      viewInsightId: String(alertChanges.alertableId),
      objectType: activeAppObject
        ? activeAppObject.objectType
        : AppObjectType.User,
      frequency: alertChanges.frequency,
      slackChannelId: alertChanges.slackChannelId,
      insightTimerange: alertChanges.insightTimerange,
    };

    createAlert(alertToCreate)
      .unwrap()
      .then(() => {
        toast({
          title: "Alert created",
          description: "You will now receive this alert",
        });
      });
  };

  const onEnable = () => {
    window.analytics.track(
      "slack_view_insight_alert_editor_set_live_clicked",
      {},
      { context: { groupId: appId } },
    );

    if (!alertChanges.slackChannelId) {
      return toast({
        title: "Select a Slack channel",
        description:
          "You need to Select a Slack channel to continue to enable the alert",
        status: "error",
      });
    } else {
      const alertToUpdate: IUpdateAlert = {
        appId: String(alertChanges.appId),
        viewInsightId: String(alertChanges.alertableId),
        objectType: activeAppObject
          ? activeAppObject.objectType
          : AppObjectType.User,
        frequency: alertChanges.frequency,
        slackChannelId: alertChanges.slackChannelId,
        state: STATE.enabled,
        insightTimerange: alertChanges.insightTimerange,
      };
      updateAlert({ id: Number(alert?.id), alert: alertToUpdate })
        .unwrap()
        .then(() => {
          toast({
            title: "Saved successfully",
            description: "Your slack notification was updated successfully.",
          });
        });
    }
  };

  const onPause = () => {
    window.analytics.track(
      "slack_view_insight_alert_editor_pause_clicked",
      {},
      { context: { groupId: appId } },
    );

    const alertToUpdate: IUpdateAlert = {
      appId: String(alertChanges.appId),
      viewInsightId: String(alertChanges.alertableId),
      objectType: viewInsight.appObject
        ? viewInsight.appObject.objectType
        : AppObjectType.User,
      frequency: alertChanges.frequency,
      slackChannelId: alertChanges.slackChannelId,
      insightTimerange: alertChanges.insightTimerange,
      state: STATE.disabled,
    };

    updateAlert({ id: Number(alert?.id), alert: alertToUpdate })
      .unwrap()
      .then(() => {
        toast({
          title: "Paused successfully",
          description: "You will no longer receive this alert",
        });
      });
  };

  const onUpdate = () => {
    window.analytics.track(
      "slack_view_insight_alert_editor_update_clicked",
      {},
      { context: { groupId: appId } },
    );

    const alertToUpdate: IUpdateAlert = {
      appId: String(alertChanges.appId),
      viewInsightId: String(alertChanges.alertableId),
      objectType: activeAppObject
        ? activeAppObject.objectType
        : AppObjectType.User,
      frequency: alertChanges.frequency,
      slackChannelId: alertChanges.slackChannelId,
      insightTimerange: alertChanges.insightTimerange,
    };
    updateAlert({ id: Number(alert?.id), alert: alertToUpdate })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your slack notification was updated successfully.",
        });
      });
  };

  function onConnectSlack() {
    localStorage.setItem(
      SLACK_REDIRECT_URL_KEY,
      `/a/${appId}/home/${viewInsight.viewId}`,
    );
    window.location.replace(`${import.meta.env.VITE_API_HOST}/auth/slack`);
  }

  return {
    state,
    hasChanges,
    alertChanges,
    setAlertChanges,
    onCancel,
    onSave,
    onEnable,
    onPause,
    onUpdate,
    onConnectSlack,
  };
}
