import { Avatar, AvatarImage, AvatarFallback } from "Components/ui/avatar";
import { ITrait } from "core/models/objects/traits";
import { HeaderPinnedTraits } from "./Header/PinnedTraits";
import { useAppObjects } from "core/hooks/useAppObjects";
import { AppObjectType } from "core/types";

export const ObjectHeader: React.FC<{ traits: ITrait[] }> = ({ traits }) => {
  const { activeAppObject } = useAppObjects();

  const avatar = traits.find((trait) => trait.name === "avatar");
  const name = traits.find((trait) => trait.name === "name");
  const email = traits.find((trait) => trait.name === "email");
  const domain = traits.find((trait) => trait.name === "domain");

  function visibleName() {
    if (activeAppObject?.objectType === AppObjectType.User) {
      return name?.value || email?.value || "Unknown";
    }

    return name?.value || domain?.value || "Unknown";
  }

  return (
    <div className="flex min-w-0 items-center gap-4">
      <div className="mr-4 flex shrink-0 items-center gap-4">
        <Avatar className="h-[48px] w-[48px] rounded-lg">
          <AvatarImage src={avatar?.value} />
          <AvatarFallback className="h-[48px] w-[48px] rounded-lg capitalize">
            {visibleName().charAt(0)}
          </AvatarFallback>
        </Avatar>
        <h1 className="max-w-[200px] truncate text-xl font-semibold">
          {visibleName()}
        </h1>
      </div>
      <div className="min-w-0 flex-1">
        <HeaderPinnedTraits traits={traits} />
      </div>
    </div>
  );
};
