import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import {
  CommandLineIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import { AUDIENCE_VIEW_INSIGHTS } from "core/constants/features";
import useFlag from "core/hooks/useFlag";

export const AddMenu: React.FC<{
  onAddInsightClick: () => void;
  onAddSQLClick: () => void;
  onAddAudienceClick?: () => void;
}> = ({ onAddInsightClick, onAddSQLClick, onAddAudienceClick }) => {
  const { groupId } = useParams();

  const isAudienceViewInsightsEnabled = useFlag(AUDIENCE_VIEW_INSIGHTS);
  return (
    <Menu>
      <MenuButton
        leftIcon={<SquaresPlusIcon className="h-4 w-4" />}
        variant="ghost"
        color="gray.600"
        as={Button}
      >
        Add
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<PresentationChartLineIcon className="h-4 w-4" />}
          onClick={onAddInsightClick}
          as={Button}
          variant="ghost"
        >
          Add graph
        </MenuItem>
        <MenuItem
          icon={<CommandLineIcon className="h-4 w-4" />}
          onClick={onAddSQLClick}
          as={Button}
          variant="ghost"
        >
          Add SQL
        </MenuItem>
        {isAudienceViewInsightsEnabled && !groupId && onAddAudienceClick && (
          <MenuItem
            icon={<UserGroupIcon className="h-4 w-4" />}
            onClick={onAddAudienceClick}
            as={Button}
            rightIcon={
              <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                New
              </span>
            }
            variant="ghost"
          >
            Add audience
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
