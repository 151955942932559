import React, { useEffect, useState } from "react";
import AddEventMenu from "modules/ComputedTrait/AddEventMenu";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IEventProperty } from "core/types/EventProperty";
import { IEvent } from "core/types/Event";
import TrackEventDropdown from "core/modules/reports/setup/Menu/TrackEventDropdown";
import PageEventDropdown from "core/modules/reports/setup/Menu/PageEvent";
import { PropertyEditor } from "core/modules/reports/setup/Editor/PropertyEditor";
import { useReportSetup } from "core/hooks/useReportSetup";
import { uuid } from "core/helpers/uuid";
import { IS } from "core/constants/traitFilterComponents";
import { ANY, FIRST, PAGE, TRACK } from "core/constants/report-setup";
import { PROPERTY } from "core/constants/filters";
import { createEvent } from "core/constants/events";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@chakra-ui/react";
import { WrapArrow } from "./WrapArrow";
import { PropertySelectMenu } from "./PropertySelectMenu";
import { PropertyBreakdownMenu } from "./PropertyBreakdownMenu";
import { FrequencySelectMenu } from "./FrequencySelectMenu";
import { FilterSelectMenu } from "./FilterSelect";

export const MultiEventPicker: React.FC<{
  section: ITemplateConfigSetupSection;
  setup: ITemplateConfigSetup;
  singleEvent?: boolean;
  onUpdateReport?: () => void;
}> = ({ section, singleEvent = false, setup, onUpdateReport }) => {
  const {
    currentReport,
    addTrackEvent,
    selectTrackEvent,
    updateTrackEvent,
    removeTrackEvent,
  } = useReportSetup();

  const eventsFromConfig = React.useMemo(() => {
    if (singleEvent) return currentReport.config[section.configKey];

    return (
      currentReport.config[section.configKey]?.map((e: IEvent) => ({ ...e })) ||
      []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport.config, section.configKey]);

  const [events, setEvents] = useState<IEvent[]>(
    eventsFromConfig
      ? Array.isArray(eventsFromConfig)
        ? eventsFromConfig
        : [eventsFromConfig]
      : [],
  );

  useEffect(() => {
    setEvents(
      eventsFromConfig
        ? Array.isArray(eventsFromConfig)
          ? eventsFromConfig
          : [eventsFromConfig]
        : [],
    );
  }, [eventsFromConfig]);

  const [propertyFilterEventId, setPropertyFilterEventId] =
    useState<string>("");
  const [frequencyFilterEventId, setFrequencyFilterEventId] =
    useState<string>("");

  function onAddEvent(type: number) {
    addTrackEvent({
      event: createEvent(type) as IEvent,
      configKey: section.configKey,
    });
    onUpdateReport && onUpdateReport();
  }

  function onRemoveEvent(event: IEvent) {
    removeTrackEvent({ event, configKey: section.configKey });
    onUpdateReport && onUpdateReport();
  }

  function onSelectEvent(event: IEvent) {
    const _event = {
      ...event,
      properties: [],
    };
    selectTrackEvent({ event: _event, configKey: section.configKey });
    onUpdateReport && onUpdateReport();
  }

  function onSelectedEventPropertyBreakdown(
    event: IEvent,
    property: string,
    value: string,
  ) {
    const _event = {
      ...event,
      uuid: uuid(),
      name: event.name,
      properties: [
        {
          body: {
            uuid: uuid(),
            property,
            comparisonType: IS.toString(),
            value,
          },
          type: PROPERTY,
          adding: false,
        },
      ],
      id: event.id,
      type: event.type,
    };
    addTrackEvent({ event: _event as IEvent, configKey: section.configKey });
    onUpdateReport && onUpdateReport();
  }

  function onSelectProperty({
    event,
    property,
  }: {
    event: IEvent;
    property: string;
  }) {
    const existingProperties =
      event.properties.map((property) => {
        return {
          body: { ...property.body, adding: false },
          adding: false,
          type: property.type,
        };
      }) || [];

    const _event: IEvent = {
      ...event,
      properties: [
        ...existingProperties,
        {
          body: {
            uuid: uuid(),
            property,
            comparisonType: IS.toString(),
            value: "",
            adding: true,
          },
          type: PROPERTY,
          adding: true,
        },
      ],
    };

    setPropertyFilterEventId("");
    updateTrackEvent({ event: _event, configKey: section.configKey });
    onUpdateReport && onUpdateReport();
  }

  function onRemoveProperty({
    event,
    property,
  }: {
    event: IEvent;
    property: IEventProperty;
  }) {
    updateTrackEvent({
      event: {
        ...event,
        properties: event.properties.filter(
          (p) => p.body.uuid !== property.body.uuid,
        ),
      },
      configKey: section.configKey,
    });
    onUpdateReport && onUpdateReport();
  }

  function onChangeTrackProperty({
    key,
    value,
    event,
    property,
    selected,
  }: {
    key: string;
    value: string;
    event: IEvent;
    property: IEventProperty;
    selected?: boolean;
  }) {
    const _updatedProperties = event.properties.map((p) => {
      if (p.body.uuid === property.body.uuid)
        return { ...p, body: { ...p.body, [key]: value }, adding: false };
      return { ...p, adding: false };
    });

    setEvents(
      events.map((e) =>
        e.uuid === event.uuid ? { ...e, properties: _updatedProperties } : e,
      ),
    );

    if (selected) {
      updateTrackEvent({
        event: { ...event, properties: _updatedProperties },
        configKey: section.configKey,
      });
    }
  }

  function onChangeTrackPropertyValue(
    inputEvent: React.ChangeEvent<HTMLInputElement>,
    event: IEvent,
    property: IEventProperty,
    selected: boolean,
  ) {
    const value = inputEvent.currentTarget.value;
    onChangeTrackProperty({ event, property, key: "value", value, selected });
    onUpdateReport && onUpdateReport();
  }

  function onChangePropComparison({
    event,
    property,
    newComparison,
  }: {
    event: IEvent;
    property: IEventProperty;
    newComparison: string;
  }) {
    onChangeTrackProperty({
      event,
      property,
      key: "comparisonType",
      value: newComparison,
      selected: true,
    });
  }

  function onUpdateEventFrequency(event: IEvent) {
    updateTrackEvent({ event, configKey: section.configKey });
    onUpdateReport && onUpdateReport();
  }

  function onRemoveEventFrequency(event: IEvent) {
    updateTrackEvent({
      event: {
        ...event,
        occurrences: undefined,
      },
      configKey: section.configKey,
    });
    onUpdateReport && onUpdateReport();
  }

  return (
    <div
      data-testid="event-editor"
      className="flex flex-wrap items-center gap-2"
    >
      <div className="flex flex-wrap items-center gap-1">
        {events?.map((event: IEvent, index: number) => {
          const isAddingPropertyFilter =
            event?.properties?.length > 0 &&
            event?.properties?.filter((p) => p.adding).length > 0;

          return (
            <div className="flex flex-wrap gap-1">
              <div key={index} className="flex flex-wrap items-center">
                {event?.id && (
                  <div data-testid="track-event" className="flex">
                    {(event?.type === TRACK ||
                      event?.type === FIRST ||
                      event?.type === ANY) && (
                      <div className="flex flex-wrap gap-y-1">
                        <div className="relative flex items-center">
                          <TrackEventDropdown
                            label={event.name}
                            eventPosition={event.uuid ?? `${index}`}
                            onSelectEvent={(selectedEvent) => {
                              onSelectEvent(selectedEvent);
                            }}
                            defaultIsOpen={Boolean(event?.adding)}
                            hasSmartEvents
                            section={section}
                            borderLeftRadius="md"
                            borderRightRadius="none"
                            areSmartEventsDisabled={events.length > 1}
                          />
                          <WrapArrow position="20.5" />
                        </div>

                        <PropertyEditor
                          event={event}
                          onRemoveProperty={onRemoveProperty}
                          onChangePropertyComparison={(newComparison, prop) =>
                            onChangePropComparison({
                              event,
                              property: prop,
                              newComparison,
                            })
                          }
                          onChangeTrackPropertyValue={
                            onChangeTrackPropertyValue
                          }
                          onClose={() => {
                            updateTrackEvent({
                              event,
                              configKey: section.configKey,
                            });
                          }}
                        />

                        <div className="flex flex-row">
                          {propertyFilterEventId === event.uuid &&
                            !isAddingPropertyFilter && (
                              <PropertySelectMenu
                                section={section}
                                event={event}
                                onSelectProperty={onSelectProperty}
                                setPropertyFilterEventId={
                                  setPropertyFilterEventId
                                }
                              />
                            )}

                          {(frequencyFilterEventId === event.uuid ||
                            event?.occurrences) && (
                            <FrequencySelectMenu
                              event={event}
                              setFrequencyFilterEventId={
                                setFrequencyFilterEventId
                              }
                              onUpdateEventFrequency={onUpdateEventFrequency}
                              onRemoveEventFrequency={onRemoveEventFrequency}
                            />
                          )}
                          {section?.eventOccurrences ? (
                            <FilterSelectMenu
                              event={event}
                              setPropertyFilterEventId={
                                setPropertyFilterEventId
                              }
                              setFrequencyFilterEventId={
                                setFrequencyFilterEventId
                              }
                              section={section}
                              borderRadius="none"
                            />
                          ) : (
                            <PropertySelectMenu
                              section={section}
                              event={event}
                              onSelectProperty={onSelectProperty}
                              setPropertyFilterEventId={
                                setPropertyFilterEventId
                              }
                              borderRadius="none"
                            />
                          )}
                          {events.length === 1 && (
                            <div className="relative flex h-full items-center bg-gray-50 hover:bg-gray-100">
                              <WrapArrow direction="left" />
                              <PropertyBreakdownMenu
                                section={section}
                                event={event}
                                onSelectProperty={
                                  onSelectedEventPropertyBreakdown
                                }
                                setPropertyFilterEventId={
                                  setPropertyFilterEventId
                                }
                              />
                              <WrapArrow direction="right" />
                            </div>
                          )}
                          <div className="relative flex items-center">
                            <Tooltip
                              label="Remove event"
                              hasArrow
                              placement="bottom"
                            >
                              <div
                                className="flex cursor-pointer items-center rounded-r-md bg-gray-50 px-2 py-[8.5px] text-gray-500 hover:bg-gray-100 hover:text-black"
                                onClick={() => onRemoveEvent(event)}
                              >
                                <XMarkIcon className="h-4 w-4" />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {event.type === PAGE && (
                  <div data-testid="page-event" className="flex">
                    <PageEventDropdown section={section} event={event} />
                    <Tooltip label="Remove event" hasArrow placement="bottom">
                      <div
                        className="flex cursor-pointer items-center rounded-r-md bg-gray-50 px-2 py-[8.5px] text-gray-500 hover:bg-gray-100 hover:text-black"
                        onClick={() => onRemoveEvent(event)}
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {event?.adding && event?.uuid && event?.type === TRACK && (
                  <div data-testid="track-event-adding" className="flex">
                    <TrackEventDropdown
                      label={event.name}
                      eventPosition={event.uuid}
                      onSelectEvent={(event) => onSelectEvent(event)}
                      defaultIsOpen={event?.adding}
                      hasSmartEvents
                      section={section}
                      areSmartEventsDisabled={events.length > 1}
                    />
                    <Tooltip label="Remove event" hasArrow placement="bottom">
                      <div
                        className="flex cursor-pointer items-center rounded-r-md bg-gray-50 px-2 py-[8.5px] text-gray-500 hover:bg-gray-100 hover:text-black"
                        onClick={() => onRemoveEvent(event)}
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              {setup && events.length > 1 && index !== events.length - 1 && (
                <p className="p-2 text-sm font-medium lowercase text-gray-900">
                  {setup.eventOperator}
                </p>
              )}
            </div>
          );
        })}
      </div>
      <div>
        <AddEventMenu
          supportedEventTypes={[ANY, FIRST, TRACK, PAGE]}
          availableEventTypes={section.supportedEventTypes}
          onAddEvent={(type) => onAddEvent(type)}
          isDisabled={
            events?.filter((e: IEvent) => e?.adding).length > 0 ||
            (singleEvent && events.filter((x: IEvent) => x).length > 0) ||
            events.map((e: IEvent) => e?.type).includes(ANY)
          }
          disableSmartEvents={events.length > 0}
        />
      </div>
    </div>
  );
};
