import { CSVLink } from "react-csv";
import { Plan } from "core/types/App";
import {
  IViewInsight,
  useDeleteViewInsightMutation,
  useDuplicateViewInsightMutation,
} from "core/models/viewInsights";
import { IQueryResult } from "core/models/sql";
import { ViewLocation } from "core/hooks/useViews";
import { useSqlCsvExport } from "core/hooks/useSqlCsvExport";
import { PaywallPopover } from "core/components/Paywall/Popover";
import {
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/16/solid";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ShareModal } from "./Share/ShareModal";
import { AlertMenuItem } from "./Overflow/AlertMenuItem";

export const OverflowMenu: React.FC<{
  viewInsight: IViewInsight;
  result?: IQueryResult;
}> = ({ viewInsight, result = { columns: [], data: [] } }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [duplicate] = useDuplicateViewInsightMutation();
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const isHomeView = viewInsight.view.location === ViewLocation.Home;
  const isCompanyView = viewInsight.view.location === ViewLocation.Company;
  const isSQL = viewInsight.query !== undefined && viewInsight.query !== null;
  const isCompanyViewSQL = isCompanyView && isSQL;

  const { data, headers } = useSqlCsvExport({ result });

  return (
    <>
      <Menu>
        <MenuButton
          data-testid="overflow-menu-button"
          onClick={(e) => {
            e.stopPropagation();
          }}
          as={Button}
          variant="ghost"
          size="xs"
        >
          <EllipsisVerticalIcon className="h-3 w-3 text-gray-500" />
        </MenuButton>
        <MenuList minW="100px">
          <MenuItem
            fontSize="xs"
            onClick={(e) => {
              e.stopPropagation();
              duplicate({
                id: viewInsight.id,
                appId: viewInsight.appId,
              });
            }}
          >
            <DocumentDuplicateIcon className="mr-1 h-3.5 w-3.5" />
            Duplicate
          </MenuItem>
          <MenuItem
            fontSize="xs"
            onClick={(e) => {
              e.stopPropagation();
              deleteViewInsight({
                id: viewInsight.id,
                appId: viewInsight.appId,
              });
            }}
          >
            <TrashIcon className="mr-1 h-3.5 w-3.5" />
            Delete
          </MenuItem>
          {(isHomeView || isCompanyViewSQL) && (
            <MenuItem
              fontSize="xs"
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
            >
              <ShareIcon className="mr-1 h-3.5 w-3.5" />
              Share
            </MenuItem>
          )}
          {isSQL && (
            <CSVLink
              filename={
                viewInsight?.title?.split(" ").join(`_`).toLowerCase() || "data"
              }
              data={data}
              headers={headers}
              target="_blank"
            >
              <PaywallPopover
                feature="CSV downloads"
                redirect={`app/${viewInsight.appId}/home/${viewInsight.viewId}`}
                plan={Plan.Pro}
              >
                <MenuItem
                  fontSize="xs"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ArrowDownTrayIcon className="mr-1 h-3.5 w-3.5" />
                  Download CSV
                </MenuItem>
              </PaywallPopover>
            </CSVLink>
          )}
          <AlertMenuItem viewInsight={viewInsight} />
        </MenuList>
      </Menu>
      {isOpen && (
        <ShareModal
          viewInsight={viewInsight}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
