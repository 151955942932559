import { Navigate } from "react-router-dom";
import { ObjectPage } from "modules/Object/Page";
import useFlag from "core/hooks/useFlag";
import { NEW_PROFILE } from "core/constants/features";

export const ObjectRoute: React.FC = () => {
  const hasNewProfileEnabled = useFlag(NEW_PROFILE);

  if (!hasNewProfileEnabled) {
    return <Navigate to="/people" />;
  }
  return (
    <div className="h-screen w-full overflow-hidden">
      <ObjectPage />
    </div>
  );
};
