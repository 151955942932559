import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface IAttioSettings {
  installed: boolean;
  syncWorkspaces: boolean;
}

export interface IAttioObject {
  objectSlug: string;
  objectName: string;
}

export interface IAttioObjectResponse {
  values: { [key: string]: string };
  id: {
    workspaceId: string;
    recordId: string;
    objectId: string;
  };
  createdAt: string;
}

export interface IAttioUsersResponse {
  objects: IAttioObjectResponse[];
  lastSyncedAt: string;
  workspaceName: string;
  objectSlug: string;
  custom: boolean;
  objectRoute: string;
}

export const attioApi = createApi({
  baseQuery,
  reducerPath: "attioApi",
  tagTypes: ["AttioSettings", "DataMappings", "AttioObjects"],
  endpoints: (builder) => ({
    getSettings: builder.query<IAttioSettings, { appId: number }>({
      query: ({ appId }) => ({
        url: "/attio/settings",
        params: { app_id: appId },
      }),
      providesTags: ["AttioSettings"],
      transformResponse: (
        response: SnakeCasedProperties<IAttioSettings>,
      ): IAttioSettings => humps.camelizeKeys(response) as IAttioSettings,
    }),
    deleteSettings: builder.mutation<
      void,
      {
        appId: number;
      }
    >({
      query: ({ appId }) => ({
        url: "/attio/destroy_settings",
        method: "DELETE",
        params: { app_id: appId },
      }),
      invalidatesTags: ["AttioSettings"],
    }),
    getObjects: builder.query<IAttioObject[], { appId: number }>({
      query: ({ appId }) => ({
        url: "/attio/objects",
        params: humps.decamelizeKeys({ appId }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<IAttioObject[]>,
      ): IAttioObject[] => humps.camelizeKeys(response) as IAttioObject[],
    }),
    getFetchObjects: builder.query<
      IAttioUsersResponse,
      { appId: number; entityId: string; appObjectId: number }
    >({
      query: ({ appId, entityId, appObjectId }) => ({
        url: "/attio/fetch_objects",
        params: humps.decamelizeKeys({
          appId,
          entityId,
          appObjectId,
        }),
      }),
      providesTags: ["AttioObjects"],
      transformResponse: (
        response: SnakeCasedProperties<IAttioUsersResponse>,
      ): IAttioUsersResponse =>
        humps.camelizeKeys(response) as IAttioUsersResponse,
    }),
    createObject: builder.mutation<
      IAttioUsersResponse,
      { appId: number; entityId: string; appObjectId: number }
    >({
      query: ({ appId, entityId, appObjectId }) => ({
        url: "/attio/create_object",
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          entityId,
          appObjectId,
        }),
      }),
      invalidatesTags: ["AttioObjects"],
    }),
    updateObject: builder.mutation<
      IAttioUsersResponse,
      { appId: number; entityId: string; appObjectId: number }
    >({
      query: ({ appId, entityId, appObjectId }) => ({
        url: "/attio/update_object",
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          entityId,
          appObjectId,
        }),
      }),
      invalidatesTags: ["AttioObjects"],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useDeleteSettingsMutation,
  useGetObjectsQuery,
  useLazyGetFetchObjectsQuery,
  useCreateObjectMutation,
  useUpdateObjectMutation,
} = attioApi;
