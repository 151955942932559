import { Tooltip } from "recharts";
import { useParams } from "react-router-dom";
import React, { Fragment, useEffect } from "react";
import { IContactPage } from "core/types/Contact";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useContact } from "core/hooks/useContact";
import { EventBlock } from "core/components/EventBlock";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  StyleProps,
  Text,
} from "@chakra-ui/react";

const Percentage: React.FC<{ page: IContactPage }> = ({ page }) => {
  const percent = Math.round((page.count / page.total) * 100);
  const explanation = `${page.count > 1 ? `${page.count}` : "once"} out of the ${
    page.total
  } pages triggered by this company`;
  return (
    <Tooltip label={explanation}>
      <Flex
        position="relative"
        w="100%"
        h="100%"
        justify="flex-end"
        alignItems="center"
      >
        <Box
          background="purple.50"
          w={`${percent}%`}
          h="100%"
          borderLeftRadius={"lg"}
        ></Box>
        <Text
          cursor="default"
          position="absolute"
          color={"primary"}
          fontSize="sm"
        >
          {percent}%
        </Text>
      </Flex>
    </Tooltip>
  );
};

interface ContactPagesProps {
  contactId?: string;
}

export const ContactPages: React.FC<ContactPagesProps & StyleProps> = ({
  contactId,
  ...styleProps
}) => {
  const { id: appId } = useCurrentApp();
  const { contactId: encodedContactId } = useParams<{
    contactId: string;
  }>() as {
    contactId: string;
  };
  const id = decodeURIComponent(contactId || encodedContactId);
  const { fetchContactPages, contactPages, isLoadingContactPages } = useContact(
    {
      appId,
      id,
    },
  );

  useEffect(() => {
    fetchContactPages();
  }, [fetchContactPages]);

  const pages = contactPages?.mostVisitedPages;

  if (isLoadingContactPages) {
    return <Skeleton borderRadius="lg" height="300px" w="100%" mb={4} />;
  }
  return (
    <Flex
      direction="column"
      borderRadius="lg"
      bg="white"
      w="100%"
      padding={6}
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.50"
      {...styleProps}
    >
      <Flex justify={"space-between"} mb={6}>
        <Text fontWeight={"medium"}>Most visited pages</Text>
        <Text color="gray.500" fontSize="sm">
          All time
        </Text>
      </Flex>
      {pages && pages.length > 0 ? (
        <Grid templateColumns="3fr 1fr 2fr" gap={2}>
          {pages.map((page, index) => (
            <Fragment key={index}>
              <GridItem w="100%">
                <EventBlock
                  mr={2}
                  mb={1}
                  key={index}
                  name={page.name}
                  color={"turquoise"}
                />
              </GridItem>
              <GridItem w="100%" display="flex" alignItems="center">
                <Text position="relative" fontSize="sm" color={"gray.500"}>
                  {page.count}
                </Text>
              </GridItem>
              <GridItem w="100%">
                <Percentage page={page} />
              </GridItem>
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Flex textAlign="center" width="100%" justify="center" padding={4}>
          <Text fontSize="sm" color="gray.500">
            No pages found
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
