import React from "react";
import _ from "lodash";
import { IGroup } from "core/types/Group";
import { AppObjectType } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import {
  HubspotObjectTypes,
  useHubspotObjects,
} from "core/hooks/useHubspotObjects";
import { useHubspotCompany } from "core/hooks/useHubspotCompany";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { UninstalledCard } from "core/components/Hubspot/UninstalledCard";
import { Profile } from "core/components/Hubspot/Profile";

export interface IHubspotProfileProps {
  appId: string;
  company: IGroup;
  objectType: AppObjectType;
  hubspotId?: string;
  showSettings?: boolean;
}

const LoadProfile: React.FC<IHubspotProfileProps> = ({
  appId,
  company,
  hubspotId,
  objectType,
  showSettings = true,
}) => {
  const { setting } = useHubspotSettings();
  const { groupCrmSyncSettings } = useCrmSyncSettings({
    integration: Integration.Hubspot,
    objectType,
  });

  const {
    companies,
    lastSyncedAt: lastSyncedAtCompany,
    isLoadingCompanies,
    createHubspotCompany,
    updateHubspotCompany,
    isCreatedCompanyLoading,
    isUpdatedCompanyLoading,
    refetchCompanies,
    fetchCompaniesError,
    createCompanyError,
    updateCompanyError,
    canCreate: canCreateCompany,
  } = useHubspotCompany({ appId, company, objectType });

  const {
    objects,
    lastSyncedAt: lastSyncedAtObject,
    isLoadingObjects,
    createHubspotObject,
    updateHubspotObject,
    isCreatedObjectLoading,
    isUpdatedObjectLoading,
    refetchObjects,
    fetchObjectsError,
    createObjectError,
    updateObjectError,
    canCreate: canCreateObject,
  } = useHubspotObjects({
    appId,
    company,
    objectType,
  });

  const GROUP_IDENTIFIERS: { [key in AppObjectType]?: string } = {
    [AppObjectType.Group]: setting?.companyIdentifier,
    [AppObjectType.Company]: "domain",
  };

  return (
    <>
      {objectType === AppObjectType.Group &&
      !_.isEmpty(groupCrmSyncSettings?.objectSlug) ? (
        <Profile
          objects={objects}
          lastSyncedAt={lastSyncedAtObject}
          isLoading={isLoadingObjects}
          isCreating={isCreatedObjectLoading}
          isUpdating={isUpdatedObjectLoading}
          onCreate={createHubspotObject}
          onUpdate={updateHubspotObject}
          type={groupCrmSyncSettings?.objectSlug as HubspotObjectTypes}
          typeName={groupCrmSyncSettings?.objectName as string}
          hubspotId={hubspotId}
          refetch={refetchObjects}
          fetchError={fetchObjectsError}
          createError={createObjectError}
          updateError={updateObjectError}
          isCreatingDisabled={!canCreateObject}
          objectType={objectType}
          identifier={GROUP_IDENTIFIERS[objectType] as string}
          showSettings={showSettings}
        />
      ) : (
        <Profile
          companies={companies}
          lastSyncedAt={lastSyncedAtCompany}
          isLoading={isLoadingCompanies}
          isCreating={isCreatedCompanyLoading}
          isUpdating={isUpdatedCompanyLoading}
          onCreate={createHubspotCompany}
          onUpdate={updateHubspotCompany}
          type={
            (groupCrmSyncSettings?.objectSlug as HubspotObjectTypes) ||
            HubspotObjectTypes.Companies
          }
          typeName={(groupCrmSyncSettings?.objectName as string) || "Company"}
          hubspotId={hubspotId}
          refetch={refetchCompanies}
          fetchError={fetchCompaniesError}
          createError={createCompanyError}
          updateError={updateCompanyError}
          isCreatingDisabled={!canCreateCompany}
          objectType={objectType}
          identifier={GROUP_IDENTIFIERS[objectType] as string}
          showSettings={showSettings}
        />
      )}
    </>
  );
};

export const HubspotProfileContainer: React.FC<{
  appId: string;
  company: IGroup;
  objectType: AppObjectType;
  showSettings?: boolean;
}> = ({ appId, company, objectType, showSettings = true }) => {
  const { setting, hasHubspotInstalled } = useHubspotSettings();

  if (!hasHubspotInstalled) return <UninstalledCard />;

  return (
    <LoadProfile
      appId={appId}
      company={company}
      hubspotId={setting.hubspotId}
      objectType={objectType}
      showSettings={showSettings}
    />
  );
};
