import {
  BuildingOfficeIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  BreadcrumbSeparator,
  BreadcrumbPage,
  BreadcrumbList,
} from "Components/ui/breadcrumb";
import { useAppObjects } from "core/hooks/useAppObjects";
import { ITrait } from "core/models/objects/traits";
import { useNavigate, useParams } from "react-router-dom";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { FavouriteObjectType } from "core/models/favourites";
import { FavouritesMenuItem } from "../Company/FavouritesMenuItem";
import { OverflowMenu } from "./Nav/OverflowMenu";
import { ObjectDigestButton } from "./Nav/ObjectDigestButton";

const ICON_MAP = {
  [AppObjectType.User]: UsersIcon,
  [AppObjectType.Company]: BuildingOfficeIcon,
  [AppObjectType.Group]: UserGroupIcon,
};

function getObjectType(appObject: IAppObject): FavouriteObjectType {
  if (appObject.objectType === AppObjectType.Group)
    return FavouriteObjectType.Group;

  if (appObject.objectType === AppObjectType.Company)
    return FavouriteObjectType.Company;

  return FavouriteObjectType.User;
}

export const ObjectNav: React.FC<{ traits: ITrait[] }> = ({ traits }) => {
  const { appId, appObjectId, objectId } = useParams();
  const name = traits.find((trait) => trait.name === "name");
  const domain = traits.find((trait) => trait.name === "domain");
  const { appObjects } = useAppObjects();
  const appObject = appObjects.find(
    (appObject) => appObject.id === Number(appObjectId),
  );
  const navigate = useNavigate();

  if (!appObject) return null;

  const Icon = ICON_MAP[appObject?.objectType || AppObjectType.User];

  return (
    <div className="flex w-full items-center justify-between pr-1">
      <Breadcrumb>
        <BreadcrumbList className="flex items-center gap-1">
          <BreadcrumbItem>
            <Icon className="size-4 text-gray-500" />
            <BreadcrumbLink
              onClick={() => navigate(`/a/${appId}/people`)}
              className="cursor-pointer"
            >
              <p className="text-sm font-medium text-gray-500 hover:text-gray-700">
                {appObject?.pluralName}
              </p>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator className="text-gray-500" />
          <BreadcrumbItem>
            <BreadcrumbPage className="flex items-center gap-1">
              <p className="text-sm font-medium text-gray-500">
                {name?.value || domain?.value || "Unknown"}
              </p>
              <FavouritesMenuItem
                menuItem={false}
                objectType={getObjectType(appObject)}
                objectId={String(objectId)}
                appId={Number(appId)}
              />
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="flex items-center">
        <ObjectDigestButton />
        <OverflowMenu />
      </div>
    </div>
  );
};
