import React, { useState } from "react";
import CopyIcon from "core/design-system/components/Icon/Interface/CopyIcon";
import { Code, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { ShareBox } from "./ShareBox";

interface IEmbeddedCode {
  sharingEnabled: boolean;
  url: string;
}

export default function EmbeddedCode({ sharingEnabled, url }: IEmbeddedCode) {
  const [copyCode, showCopyCode] = useState(false);
  const embedCode = `<iframe width="1000" height="600" src="${url}" title="June" frameborder="0" allowfullscreen></iframe>`;
  const { onCopy, hasCopied } = useClipboard(embedCode);

  return (
    <ShareBox sharingEnabled={sharingEnabled}>
      <Text color="gray.800" fontWeight="medium">
        Copy embed code
      </Text>
      <Text color="gray.600" fontSize="sm" fontWeight="medium" mb={2}>
        Use the code to embed this insight
      </Text>
      <Code
        w="100%"
        onMouseEnter={() => showCopyCode(true)}
        onMouseLeave={() => showCopyCode(false)}
        py={8}
        paddingRight={12}
        paddingLeft={6}
        borderRadius="md"
        position="relative"
        backgroundColor="gray.50"
      >
        {sharingEnabled && copyCode && (
          <IconButton
            aria-label="Copy code"
            icon={
              hasCopied ? (
                <CheckIcon color="green.300" />
              ) : (
                <CopyIcon color="gray.600" />
              )
            }
            borderColor="gray.500"
            onClick={onCopy}
            position="absolute"
            top={2}
            variant="outline"
            right={2}
            size="md"
            _hover={{ bg: "gray.200" }}
          />
        )}
        {embedCode}
      </Code>
    </ShareBox>
  );
}
