import { useParams } from "react-router-dom";
import React from "react";
import { IView, usePinViewMutation } from "core/models/views";
import { Button } from "@june-so/ui";
import { BookmarkSlashIcon } from "@heroicons/react/24/solid";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@chakra-ui/react";

export const PinCell: React.FC<{ props: IView }> = ({ props: view }) => {
  const { appId } = useParams();
  const [pinView] = usePinViewMutation();

  function handlePin() {
    if (view?.id === undefined) return null;

    pinView({
      appId: Number(appId),
      viewId: Number(view.id),
      pinned: !view.pinned,
    });
  }

  return (
    <Tooltip label={view.pinned ? "Unpin dashboard" : "Pin dashboard"}>
      <Button variant="ghost" onClick={handlePin} className="text-gray-600">
        {view.pinned ? (
          <BookmarkSlashIcon className="h-4 w-4" />
        ) : (
          <BookmarkIcon className="h-4 w-4" />
        )}
      </Button>
    </Tooltip>
  );
};
