import { Tooltip } from "recharts";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { IGroupEvent } from "core/types/Group";
import { IContactEvent } from "core/types/Contact";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { EventBlock } from "./EventBlock";

const EventPercentage: React.FC<{ event: IGroupEvent | IContactEvent }> = ({
  event,
}) => {
  const percent = Math.round((event.count / event.total) * 100);
  const explanation = `${event.count > 1 ? `${event.count}` : "once"} out of the ${
    event.total
  } events triggered by this company`;
  return (
    <Tooltip label={explanation}>
      <div className="relative flex h-full w-full items-center justify-end">
        <Box
          background="purple.50"
          w={`${percent}%`}
          h="100%"
          borderLeftRadius={"lg"}
        ></Box>
        <Text
          cursor="default"
          position="absolute"
          color={"primary"}
          fontSize="sm"
        >
          {percent}%
        </Text>
      </div>
    </Tooltip>
  );
};

export const MostTriggeredEvents = ({
  events,
}: {
  events: IGroupEvent[] | IContactEvent[];
}) => {
  const { id: appId } = useCurrentApp();
  return (
    <Flex
      direction="column"
      borderRadius="lg"
      bg="white"
      w="100%"
      border="1px solid"
      borderColor="gray.50"
      padding={6}
      boxShadow="sm"
    >
      <Flex justify={"space-between"} mb={6}>
        <Text fontWeight={"medium"}>Most triggered events</Text>
        <Text color="gray.500" fontSize="sm">
          All time
        </Text>
      </Flex>
      {events && events.length > 0 ? (
        <Grid templateColumns="3fr 1fr 2fr" gap={2}>
          {events.map((event, index) => (
            <Fragment key={index}>
              <GridItem w="100%">
                <Link to={`/a/${appId}/event/${event.id}`}>
                  <EventBlock
                    _hover={{ bg: "purple.50" }}
                    name={event.name}
                    maxWidth={"240px"}
                    tooltipText={event.name}
                  />
                </Link>
              </GridItem>
              <GridItem w="100%" display="flex" alignItems="center">
                <Text position="relative" fontSize="sm" color={"gray.500"}>
                  {event.count}
                </Text>
              </GridItem>
              <GridItem w="100%">
                <EventPercentage event={event} />
              </GridItem>
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Flex textAlign="center" width="100%" justify="center" padding={4}>
          <Text fontSize="sm" color="gray.500">
            No events found
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
