import { MappingData } from "modules/Settings/Crm/SyncSettings/MappingData";
import { IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { SelectObject } from "./SelectObject";

export const WorkspaceData: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  const {
    onUpdateCrmSyncSetting,
    crmSyncSetting: setting,
    isUpdatingCrmSyncSetting,
  } = useCrmSyncSettings({
    integration: Integration.Attio,
    objectType: appObject.objectType,
  });

  return (
    <>
      <SelectObject
        appObject={appObject}
        isLoading={isUpdatingCrmSyncSetting}
        setting={setting}
        onUpdateCrmSyncSetting={onUpdateCrmSyncSetting}
      />
      <MappingData appObject={appObject} integration={Integration.Attio} />
    </>
  );
};
