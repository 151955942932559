import { Skeleton } from "Components/ui/skeleton";
import { CompanyAvatar } from "core/components/Avatars/CompanyAvatar";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useGetAssociationListQuery } from "core/models/objects/associations";
import { Link, useParams } from "react-router-dom";

export const UserAssociations = () => {
  const { appId, appObjectId, objectId } = useParams();
  const { groupAppObject, companyAppObject } = useAppObjects();
  const { data, isLoading } = useGetAssociationListQuery({
    appId: Number(appId),
    appObjectId: String(appObjectId),
    objectId: String(objectId),
    page: 1,
  });
  const groups = data?.groups;
  const companies = data?.companies;

  if (isLoading) {
    return (
      <div className="flex flex-col gap-2 border-b border-gray-300 px-3 py-4">
        <Skeleton className="h-[20px] w-full rounded-md" />
      </div>
    );
  }

  return (
    <div className="flex flex-col border-b border-gray-300">
      <div className="px-3 py-4">
        <p className="mb-2 font-medium">Groups</p>
        <div className="flex flex-col gap-2">
          {groups?.map((group) => (
            <div key={group.id} className="flex items-center gap-1">
              <CompanyAvatar group={{ id: group.id }} size="xs" />
              <Link
                to={`/a/${appId}/objects/${groupAppObject.id}/object/${group.id}`}
                className="rounded-md px-1 text-sm hover:bg-gray-100"
              >
                {group.name}
              </Link>
            </div>
          ))}
        </div>
        {groups?.length === 0 && (
          <p className="text-sm text-muted-foreground">No groups</p>
        )}
      </div>

      <div className="mb-4 border-t border-gray-300">
        <div className="px-3 py-4">
          <p className="mb-2 font-medium">Companies</p>
          <div className="flex flex-col gap-2">
            {companies?.map((company) => (
              <div key={company.id} className="flex items-center gap-1">
                <CompanyAvatar group={{ id: company.id }} size="xs" />
                <Link
                  to={`/a/${appId}/objects/${companyAppObject.id}/object/${company.id}`}
                  className="rounded-md px-1 text-sm hover:bg-gray-100"
                >
                  {company.name}
                </Link>
              </div>
            ))}
          </div>
          {companies?.length === 0 && (
            <p className="text-sm text-muted-foreground">No companies</p>
          )}
        </div>
      </div>
    </div>
  );
};
