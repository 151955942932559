import { SnakeCasedProperties } from "type-fest";
import { IPagy } from "core/types/Pagy";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IAudienceFilter, JoinOperatorValue } from "core/types/Filters";
import { transformFilterGroups } from "helpers/params";

export interface IAssociationListResponse {
  contacts: IContact[];
  groups: IGroup[];
  companies: IGroup[];
  pagy?: IPagy;
}

export const objectAssociationsApi = createApi({
  baseQuery,
  reducerPath: "objectAssociationsApi",
  tagTypes: ["Association"],
  endpoints: (builder) => ({
    getAssociationList: builder.query<
      IAssociationListResponse,
      {
        appId: number;
        appObjectId: string;
        objectId: string;
        page?: number;
        searchTerm?: string;
        sortBy?: string;
        sortOrder?: string;
        filterGroups?: IAudienceFilter[];
        joinOperator?: JoinOperatorValue;
      }
    >({
      query: ({
        appId,
        appObjectId,
        objectId,
        page,
        searchTerm,
        sortBy,
        sortOrder,
        filterGroups,
        joinOperator,
      }) => ({
        url: `objects/associations`,
        params: humps.decamelizeKeys({
          appId,
          appObjectId,
          objectId,
          page,
          searchTerm,
          sortBy,
          sortOrder,
          filterGroups: JSON.stringify(
            transformFilterGroups(filterGroups || []),
          ),
          joinOperator,
        }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<IAssociationListResponse>,
      ): IAssociationListResponse => {
        return {
          contacts: (response.contacts || []).map((contact) => {
            const { traits, ...rest } = contact;
            return {
              ...humps.camelizeKeys(rest),
              traits,
            };
          }) as IContact[],
          groups: (response.groups || []).map((group) => {
            const { traits, ...rest } = group;
            return {
              ...humps.camelizeKeys(rest),
              traits,
            };
          }) as IGroup[],
          companies: (response.companies || []).map((company) => {
            const { traits, ...rest } = company;
            return {
              ...humps.camelizeKeys(rest),
              traits,
            };
          }) as IGroup[],
          pagy: response.pagy,
        };
      },
      providesTags: (result) => {
        if (result?.contacts?.length) {
          return result.contacts.map((contact) => ({
            type: "Association" as const,
            id: contact.id,
          }));
        } else if (result?.groups?.length) {
          return result.groups.map((group) => ({
            type: "Association" as const,
            id: group.id,
          }));
        } else if (result?.companies?.length) {
          return result.companies.map((company) => ({
            type: "Association" as const,
            id: company.id,
          }));
        }
        return [{ type: "Association", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetAssociationListQuery } = objectAssociationsApi;
