import { ITrait } from "core/models/objects/traits";
import { TraitsList } from "./Sections/Traits/List";
import { HubSpot } from "./Sections/Crm/HubSpot";
import { Attio } from "./Sections/Crm/Attio";
import { useAppObjects } from "core/hooks/useAppObjects";
import { AppObjectType } from "core/types/AppObject";
import { UserAssociations } from "./Sections/Users/Associations";
import { useParams } from "react-router-dom";
import { ContactAISummary } from "modules/Contacts/Contact/ContactAISummary";
import useFlag from "core/hooks/useFlag";
import { AI_SUMMARY } from "core/constants/features";

export const ObjectSideSection: React.FC<{ traits: ITrait[] }> = ({
  traits,
}) => {
  const { objectId } = useParams();
  const { activeAppObject } = useAppObjects();
  const hasAISummary = useFlag(AI_SUMMARY);

  return (
    <div className="flex flex-col">
      <TraitsList traits={traits} />
      {activeAppObject?.objectType === AppObjectType.User &&
        objectId &&
        hasAISummary && <ContactAISummary contactId={objectId} />}
      <HubSpot />
      <Attio />
      {activeAppObject?.objectType === AppObjectType.User && (
        <UserAssociations />
      )}
    </div>
  );
};
