import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { AppObjectType } from "core/types/AppObject";
import { useHubspotProfile } from "core/hooks/useHubspotProfile";
import {
  HubspotObjectTypes,
  IHubspotObject,
} from "core/hooks/useHubspotObjects";
import { IHubspotContact } from "core/hooks/useHubspotContact";
import { IHubspotCompany } from "core/hooks/useHubspotCompany";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { ArrowPathIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Link,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export interface IHubspotProfileProps {
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  onCreate: () => void;
  onUpdate: (entityId: string) => void;
  contacts?: IHubspotContact[];
  companies?: IHubspotCompany[];
  objects?: IHubspotObject[];
  type: HubspotObjectTypes;
  typeName: string;
  hubspotId?: string;
  lastSyncedAt?: string;
  refetch: () => void;
  fetchError: FetchBaseQueryError | SerializedError | undefined;
  createError: FetchBaseQueryError | SerializedError | undefined;
  updateError: FetchBaseQueryError | SerializedError | undefined;
  isCreatingDisabled: boolean;
  objectType?: AppObjectType;
  identifier?: string;
  showSettings?: boolean;
  showConnect?: boolean;
}

export const Profile: React.FC<IHubspotProfileProps> = ({
  isLoading,
  isCreating,
  isUpdating,
  contacts,
  companies,
  objects,
  onCreate,
  onUpdate,
  type,
  typeName,
  hubspotId,
  lastSyncedAt,
  refetch,
  fetchError,
  createError,
  updateError,
  isCreatingDisabled,
  objectType,
  identifier,
  showSettings,
  showConnect,
}) => {
  const currentApp = useCurrentApp();

  function onHandleError(entity?: any) {
    if (fetchError) {
      refetch();
    } else if (createError) {
      onCreate();
    } else if (updateError && entity) {
      onUpdate(entity?.id);
    }
  }

  function actionErrorMessage() {
    if (fetchError) return "loading";
    if (createError) return "creating";
    if (updateError) return "updating";
    return "";
  }

  function getLink(entity: any) {
    if (
      type === HubspotObjectTypes.Contacts ||
      type === HubspotObjectTypes.Companies ||
      type === HubspotObjectTypes.Deals
    ) {
      return `https://app.hubspot.com/contacts/${hubspotId}/${type}/${entity.id}`;
    }

    return `https://app.hubspot.com/contacts/${hubspotId}/record/${type}/${entity.id}`;
  }

  function getLinkQuery(entity: any) {
    let query = "";

    if (type === HubspotObjectTypes.Contacts) {
      query = entity.properties.email;
    } else if (type === HubspotObjectTypes.Companies) {
      query = entity.properties.domain;
    } else if (type === HubspotObjectTypes.Deals) {
      query = entity.properties.name;
    }

    return `https://app-eu1.hubspot.com/contacts/${hubspotId}/objects/0-2/views/all/list?query=${query}`;
  }

  const hasDataInHubSpot =
    (contacts?.length || companies?.length || objects?.length || 0) > 0;

  const { shouldCreateEntity, shouldUpdateEntity, hasError } =
    useHubspotProfile({
      isLoading,
      isCreating,
      hasDataInHubSpot,
      lastSyncedAt,
      fetchError,
      createError,
      updateError,
    });

  return (
    <div data-testid="hubspot-profile-container">
      <div className="flex items-center justify-between">
        <div className="flex-0">
          <HubspotIcon color="#f57722" h={4} />
        </div>
        <div className="flex flex-1">
          {shouldCreateEntity() && (
            <div
              className="flex flex-col"
              data-testid="hubspot-no-matches-found"
            >
              <Tooltip
                label={
                  isCreatingDisabled
                    ? `Creating is disabled because of missing "${identifier}" trait`
                    : ""
                }
                fontSize="xs"
                hasArrow
                shouldWrapChildren
              >
                <Button
                  data-testid="hubspot-create-button"
                  onClick={onCreate}
                  variant="link"
                  isDisabled={isCreatingDisabled}
                  ml={1}
                  px="4px"
                  color="gray.700"
                  fontWeight="normal"
                  textDecoration="none"
                  boxShadow="none"
                >
                  Create {typeName} in HubSpot
                </Button>
              </Tooltip>
            </div>
          )}

          {isCreating && (
            <Button variant="ghost" ml={1} color="gray.500">
              <Spinner mr={2} size="xs" /> Creating {typeName}
            </Button>
          )}

          {shouldUpdateEntity() && (
            <div className="ml-2 flex w-full items-center justify-between">
              <div className="flex w-full flex-col">
                {(
                  contacts?.slice(0, 1) ||
                  companies?.slice(0, 1) ||
                  objects?.slice(0, 1)
                )?.map((entity: any, index: number) => {
                  return (
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-gray-700">Last synced</p>
                        <Link
                          data-testid="hubspot-profile-link"
                          key={index}
                          color="purple.500"
                          _hover={{ textDecoration: "underline" }}
                          href={
                            (contacts?.length ||
                              companies?.length ||
                              objects?.length ||
                              0) > 1
                              ? getLinkQuery(entity)
                              : getLink(entity)
                          }
                          isExternal
                          fontSize="sm"
                          fontWeight="normal"
                          noOfLines={
                            contacts?.length ||
                            companies?.length ||
                            objects?.length ||
                            0
                          }
                          maxW="185px"
                        >
                          {lastSyncedAt}
                        </Link>
                      </div>

                      {!hasError() && (
                        <Tooltip
                          hasArrow
                          label={`Sync to HubSpot`}
                          placement="top"
                          fontSize="xs"
                        >
                          <Button
                            data-testid="hubspot-profile-resync-button"
                            size="xs"
                            onClick={() => onUpdate(entity.id)}
                            variant="link"
                            color="gray.700"
                            fontWeight="normal"
                            textDecoration="none"
                            boxShadow="none"
                          >
                            {isUpdating ? (
                              <Spinner size="xs" />
                            ) : (
                              <ArrowPathIcon className="h-4" />
                            )}
                          </Button>
                        </Tooltip>
                      )}
                      {updateError && !isUpdating && (
                        <Button
                          data-testid="hubspot-retry-button"
                          onClick={() => onHandleError(entity)}
                          variant="ghost"
                          size="xs"
                          px={2}
                        >
                          Try again
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {(fetchError || createError) && (
            <Button
              data-testid="hubspot-retry-button"
              onClick={onHandleError}
              variant="ghost"
              size="xs"
              px={2}
            >
              Try again
            </Button>
          )}

          {isLoading && (
            <Skeleton
              ml={2}
              data-testid="hubspot-skeleton"
              h="20px"
              w="200px"
              bg="gray.100"
              borderRadius="md"
            />
          )}
        </div>

        {showSettings && (
          <div className="flex items-center">
            <Button
              as={RouterLink}
              variant="link"
              size="xs"
              data-testid="hubspot-settings-link"
              to={`/a/${currentApp.id}/integrations/hubspot/${objectType}`}
              px="4px"
              color="gray.700"
              fontWeight="normal"
              textDecoration="none"
              boxShadow="none"
            >
              <Tooltip
                as="div"
                className="flex items-center"
                placement="top"
                label="HubSpot settings"
                shouldWrapChildren
                fontSize="xs"
                hasArrow
              >
                <Cog6ToothIcon className="h-4 w-4" />
              </Tooltip>
            </Button>
          </div>
        )}
      </div>
      {hasError() && (
        <Text
          ml={6}
          data-testid="hubspot-error-message"
          fontSize="xs"
          color="gray.600"
        >
          Something went wrong while {actionErrorMessage()} {typeName}
        </Text>
      )}
    </div>
  );
};
