import {
  FavouriteObjectType,
  useGetFavouriteQuery,
} from "core/models/favourites";
import { useFavourites } from "core/hooks/useFavourites";
import { StarIcon as FilledStarIcon } from "@heroicons/react/24/solid";
import { StarIcon } from "@heroicons/react/24/outline";
import { MenuItem, Text } from "@chakra-ui/react";
import { Button } from "Components/ui/button";

interface FavouritesMenuItemProps {
  appId: number;
  objectId: string;
  objectType: FavouriteObjectType;
  menuItem?: boolean;
}

export const FavouritesMenuItem: React.FC<FavouritesMenuItemProps> = ({
  appId,
  objectId,
  objectType,
  menuItem = true,
}) => {
  const { data: favourite } = useGetFavouriteQuery({
    appId,
    objectId,
    objectType,
  });

  const { onDeleteFavourite, onCreateFavourite } = useFavourites(appId, [
    objectType,
  ]);

  if (!menuItem) {
    return (
      <Button
        variant="ghost"
        size="sm"
        className="h-full p-1"
        onClick={() => {
          if (favourite) {
            onDeleteFavourite(favourite);
          } else {
            onCreateFavourite({
              objectId,
              objectType,
            });
          }
        }}
      >
        {favourite ? (
          <FilledStarIcon className="h-4 text-yellow-400" />
        ) : (
          <StarIcon className="h-4 text-gray-500" />
        )}
      </Button>
    );
  }

  return (
    <MenuItem
      icon={
        favourite ? (
          <FilledStarIcon className="h-4" />
        ) : (
          <StarIcon className="h-4" />
        )
      }
      onClick={() => {
        if (favourite) {
          onDeleteFavourite(favourite);
        } else {
          onCreateFavourite({
            objectId,
            objectType,
          });
        }
      }}
    >
      <Text fontSize="sm">
        {favourite ? "Remove from favourites" : "Add to favourites"}
      </Text>
    </MenuItem>
  );
};
