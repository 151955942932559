import { Button } from "Components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "Components/ui/dialog";
import { useMergeObjectMutation } from "core/models/objects/object";
import { IAppObject } from "core/types/AppObject";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  ArrowSmallRightIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { cx } from "helpers/cx";
import { useState } from "react";
import { SearchCompanyAction } from "modules/Company/SearchCompanyAction";

interface MergeObjectDialogProps {
  onClose: () => void;
  appObject: IAppObject;
}

export const MergeObjectDialog = ({
  onClose,
  appObject,
}: MergeObjectDialogProps) => {
  const { appId, objectId } = useParams();
  const [mergeObject] = useMergeObjectMutation();
  const navigate = useNavigate();
  const [sourceObjectId, setSourceObjectId] = useState<string | undefined>();

  function onMerge() {
    if (!appId || !objectId || !sourceObjectId) return;

    mergeObject({
      appId: Number(appId),
      appObjectId: Number(appObject.id),
      targetObjectId: objectId,
      sourceObjectId,
    });

    toast.success("Merged successfully", {
      description: "It might take some minutes for the merge to finish.",
    });

    navigate(`/a/${appId}/people`);
  }

  return (
    <DialogContent className="sm:max-w-[800px]">
      <DialogHeader>
        <DialogTitle>Merge {objectId}</DialogTitle>
        <DialogDescription>
          <div className="flex items-center rounded-xl border border-gray-100 bg-gray-100 p-2">
            <InformationCircleIcon className="mr-2 size-5 text-gray-500" />
            <div className="text-sm text-gray-500">
              Our system intelligently combines your data. Note that{" "}
              <div className="inline font-medium">{objectId}</div> holds higher
              priority.
            </div>
          </div>
        </DialogDescription>
      </DialogHeader>

      <div className={cx("mt-4 flex items-center justify-between")}>
        <div className={cx("flex w-full items-center justify-between")}>
          <div
            className={cx(
              "flex h-32 w-5/12 items-center justify-center rounded-xl border p-10",
            )}
          >
            {sourceObjectId ? (
              <div className="truncate text-ellipsis font-medium">
                {sourceObjectId}
              </div>
            ) : (
              <SearchCompanyAction
                targetGroupId={objectId || ""}
                setSourceGroupId={setSourceObjectId}
              />
            )}
          </div>
          <ArrowSmallRightIcon className="size-5 text-gray-400" />
          <div
            className={cx(
              "flex h-32 w-5/12 items-center justify-center rounded-xl border p-10",
            )}
          >
            <div className="truncate text-ellipsis font-medium">{objectId}</div>
          </div>
        </div>
      </div>

      <DialogFooter>
        <div className="flex w-full items-center justify-between">
          {sourceObjectId ? (
            <div className="flex items-center p-2">
              <ExclamationTriangleIcon className="mr-2 size-5 text-yellow-500" />
              <div className="text-sm text-gray-500">
                This action can't be undone.
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={() => {
                setSourceObjectId(undefined);
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button onClick={onMerge} disabled={!sourceObjectId}>
              Merge {appObject.pluralName.toLowerCase()}
            </Button>
          </div>
        </div>
      </DialogFooter>
    </DialogContent>
  );
};
