import { useState } from "react";
import { pageConfig, trackConfig } from "modules/Events/List/config";
import { useGetPagesQuery } from "core/models/pages";
import { useGetEventsQuery } from "core/models/events";
import usePagination from "core/hooks/usePagination";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { PAGE, TRACK } from "core/constants/report-setup";

export const useEventList = ({
  eventType,
}: {
  eventType: typeof TRACK | typeof PAGE;
}) => {
  const { id: appId } = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState("");
  const { currentPage, previousPage, nextPage, setPage } = usePagination({
    withURL: false,
    searchQuery,
  });
  const skipPagination = false;

  const getParams = {
    appId,
    page: currentPage,
    query: searchQuery,
    skipPagination,
  };

  const {
    data: eventsData,
    isFetching: isLoadingEvents,
    error: eventsError,
  } = useGetEventsQuery(getParams, {
    skip: eventType !== TRACK,
  });

  const {
    data: pagesData,
    isFetching: isLoadingPages,
    error: pagesError,
  } = useGetPagesQuery(getParams, {
    skip: eventType !== PAGE,
  });

  const listConfig = {
    [TRACK]: trackConfig,
    [PAGE]: pageConfig,
  };

  const objectEvents = {
    [TRACK]: eventsData?.events,
    [PAGE]: pagesData?.pages,
  };

  const isLoadingObject = {
    [TRACK]: isLoadingEvents,
    [PAGE]: isLoadingPages,
  };

  const objectErrors = {
    [TRACK]: eventsError,
    [PAGE]: pagesError,
  };

  const objectPagy = {
    [TRACK]: eventsData?.pagy,
    [PAGE]: pagesData?.pagy,
  };

  return {
    listConfig: listConfig[eventType],
    events: objectEvents[eventType] || [],
    pagy: objectPagy[eventType],
    isLoading: isLoadingObject[eventType] || false,
    error: objectErrors[eventType] || null,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    setPage,
    searchQuery,
    setSearchQuery,
  };
};
