import humps from "humps";
import { GraphType, Interval, Measure, State } from "core/types/ViewInsight";
import { IReport } from "core/types/Report";
import { IQuery } from "core/types/Query";
import { IAppObject } from "core/types/AppObject";
import { baseQuery } from "core/initializers/rtk";
import { ABSOLUTE, RELATIVE } from "core/constants/timerange";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IView } from "./views";
import { Aggregation } from "../types/ViewInsight";
import { IAudience } from "../types/Audience";

import type { SnakeCasedPropertiesDeep } from "type-fest";
export interface IViewInsight {
  id: number;
  appId: number;
  reportId: number;
  viewId: number;
  reportType: number;
  insightId?: number;
  queryId?: number;
  order: number;
  isAutoGenerated: boolean;
  level: "user" | "group" | "company";
  insight: {
    name: string;
    slug: string;
    reportType: number;
    reportName?: string;
    level?: "user" | "group";
    defaultParams?: object;
    hasAggregations?: boolean;
    defaultAggregation?: Aggregation;
  };
  query?: IQuery;
  timeRange?: number;
  startDate: string;
  endDate: string;
  timerangeType: typeof RELATIVE | typeof ABSOLUTE;
  timerangeValue: number;
  timerangeStartTimestamp?: string;
  timerangeEndTimestamp?: string;
  appObject?: IAppObject;
  report: IReport;
  graphType: GraphType;
  color: string;
  gridWidth: number;
  gridHeight: number;
  persisted: boolean;
  measure: Measure;
  interval?: Interval;
  title?: string;
  useViewAudience: boolean;
  view: IView;
  state: State;
  updatedAt: string;
  audience?: IAudience;
  aggregation: Aggregation;
}

export const viewInsightsApi = createApi({
  baseQuery,
  reducerPath: "viewInsightsApi",
  tagTypes: ["ViewInsight", "ViewInsights"],
  endpoints: (builder) => ({
    getViewInsight: builder.query<
      IViewInsight,
      {
        appId: number;
        viewId: number;
        id: number;
        sharingSecretToken?: string | null;
        groupId?: string | null;
      }
    >({
      query: ({
        appId,
        viewId,
        id,
        sharingSecretToken = null,
        groupId = null,
      }) => ({
        url: `/view_insights/${id}`,
        params: humps.decamelizeKeys({
          appId,
          viewId,
          id,
          sharingSecretToken,
          groupId,
        }),
      }),
      providesTags: ["ViewInsight"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight>,
      ): IViewInsight => humps.camelizeKeys(response) as IViewInsight,
    }),
    getViewInsights: builder.query<
      IViewInsight[],
      {
        appId: number;
        viewId: number;
        sharingSecretToken?: string | null;
        groupId?: string | null;
        timestamp?: number;
      }
    >({
      query: ({
        appId,
        viewId,
        sharingSecretToken = null,
        groupId = null,
      }) => ({
        url: `/view_insights`,
        params: humps.decamelizeKeys({
          appId,
          viewId,
          sharingSecretToken,
          groupId,
        }),
      }),
      providesTags: ["ViewInsights"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight[]>,
      ): IViewInsight[] => humps.camelizeKeys(response) as IViewInsight[],
    }),
    getViewInsightPreview: builder.query<
      IViewInsight,
      {
        appId: number;
        viewId: number;
        measure: Measure;
        graphType: GraphType;
        interval?: Interval;
        objectType?: string;
        uuid?: string;
      }
    >({
      query: ({
        appId,
        viewId,
        measure,
        graphType,
        interval,
        objectType,
        uuid,
      }) => ({
        url: `/view_insights/preview`,
        params: humps.decamelizeKeys({
          appId,
          viewId,
          measure,
          graphType,
          interval,
          objectType,
          uuid,
        }),
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight>,
      ): IViewInsight => humps.camelizeKeys(response) as IViewInsight,
    }),
    deleteViewInsight: builder.mutation<{}, { appId: number; id: number }>({
      query: ({ appId, id }) => ({
        url: `/view_insights/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, id }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["ViewInsights"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight[]>,
      ): IViewInsight[] => humps.camelizeKeys(response) as IViewInsight[],
    }),
    addViewInsight: builder.mutation<
      {},
      {
        appId: number;
        viewId: number;
        reportId?: number;
        insightId?: number;
        queryId?: number;
        objectType?: string;
        title?: string;
        graphType?: string;
        color?: string;
        interval?: string;
        measure?: string;
        gridWidth?: number;
        gridHeight?: number;
      }
    >({
      query: ({
        appId,
        viewId,
        reportId,
        insightId,
        queryId,
        objectType,
        title,
        graphType,
        color,
        interval,
        measure,
        gridWidth,
        gridHeight,
      }) => ({
        url: `/view_insights`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          reportId,
          viewId,
          insightId,
          queryId,
          objectType,
          title,
          graphType,
          color,
          interval,
          measure,
          gridWidth,
          gridHeight,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["ViewInsights"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight[]>,
      ): IViewInsight[] => humps.camelizeKeys(response) as IViewInsight[],
    }),
    updateViewInsight: builder.mutation<
      void,
      {
        appId: number;
        id: number;
        order?: number;
        timerangeType?: typeof RELATIVE | typeof ABSOLUTE;
        timerangeValue?: number;
        timerangeStartTimestamp?: string;
        timerangeEndTimestamp?: string;
        title?: string;
        graphType?: string;
        objectType?: string;
        color?: string;
        interval?: string;
        measure?: string;
        gridWidth?: number;
        gridHeight?: number;
        state?: number;
        useViewAudience?: boolean;
        audienceId?: number;
        aggregation?: Aggregation;
      }
    >({
      query: ({
        appId,
        id,
        order,
        timerangeType,
        timerangeValue,
        timerangeStartTimestamp,
        timerangeEndTimestamp,
        objectType,
        title,
        graphType,
        color,
        interval,
        measure,
        gridWidth,
        gridHeight,
        state,
        useViewAudience,
        audienceId,
        aggregation,
      }) => ({
        url: `/view_insights/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          id,
          order,
          timerangeType,
          timerangeValue,
          timerangeStartTimestamp,
          timerangeEndTimestamp,
          title,
          graphType,
          color,
          interval,
          measure,
          gridWidth,
          gridHeight,
          state,
          objectType,
          useViewAudience,
          audienceId,
          aggregation,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["ViewInsight"],
    }),
    updateOrder: builder.mutation<
      void,
      {
        appId: number;
        viewInsights: { id: number; order: number }[];
      }
    >({
      query: ({ appId, viewInsights }) => ({
        url: `/view_insights/update_order`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          viewInsights: JSON.stringify(viewInsights),
        }),
        credentials: "include",
        mode: "cors",
      }),
    }),
    duplicateViewInsight: builder.mutation<void, { appId: number; id: number }>(
      {
        query: ({ appId, id }) => ({
          url: `/view_insights/duplicate`,
          method: "POST",
          params: humps.decamelizeKeys({ appId, id }),
        }),
        invalidatesTags: ["ViewInsights"],
      },
    ),

    // Audience view insights
    createAudienceViewInsight: builder.query<
      IViewInsight,
      { appId: number; viewId: number }
    >({
      query: ({ appId, viewId }) => ({
        url: `/view_insights_audience`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          viewId,
        }),
      }),
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight>,
      ): IViewInsight => humps.camelizeKeys(response) as IViewInsight,
    }),
    updateAudienceViewInsight: builder.mutation<
      void,
      { appId: number; id: number; title: string; gridHeight: number }
    >({
      query: ({ appId, id, title, gridHeight }) => ({
        url: `/view_insights_audience/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId, id, title, gridHeight }),
      }),
    }),
  }),
});

export const {
  useGetViewInsightQuery,
  useLazyGetViewInsightQuery,
  useLazyGetViewInsightsQuery,
  useGetViewInsightsQuery,
  useDeleteViewInsightMutation,
  useUpdateViewInsightMutation,
  useAddViewInsightMutation,
  useGetViewInsightPreviewQuery,
  useLazyGetViewInsightPreviewQuery,
  useUpdateOrderMutation,
  useDuplicateViewInsightMutation,
  useCreateAudienceViewInsightQuery,
  useUpdateAudienceViewInsightMutation,
} = viewInsightsApi;
