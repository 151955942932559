import { Button } from "@chakra-ui/react";
import { Center } from "@chakra-ui/react";
import Particles from "core/design-system/components/Particles";
import { PageContainer } from "core/components/PageContainer";
import colors from "core/design-system/constants/theme/colors";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const NotFound: React.FC<{
  title: string;
  description: string;
  buttonText: string;
  breadcrumbs: ReactNode;
}> = ({ title, description, buttonText, breadcrumbs }) => {
  const { appId } = useParams();
  const navigate = useNavigate();
  return (
    <PageContainer
      className="flex h-full flex-col bg-gray-50"
      minW="2xl"
      maxH="full"
    >
      <div className="mx-4 mt-2 h-full w-full">
        <Particles
          className="absolute inset-0"
          quantity={200}
          ease={100}
          color={colors.purple[300]}
          refresh
        />
        {breadcrumbs}
        <Center h="full">
          <div className="flex flex-col items-center gap-4">
            <div className="flex items-center justify-center rounded-md bg-purple-100 p-2">
              <MagnifyingGlassIcon className="size-6 text-purple-400" />
            </div>
            <div className="flex flex-col items-center">
              <p className="font-medium">{title}</p>
              <p className="text-sm text-gray-500">{description}</p>
            </div>
            <Button
              variant="ghost"
              bg="purple.50"
              _hover={{ bg: "purple.100" }}
              colorScheme="purple"
              mt={2}
              onClick={() => navigate(`/a/${appId}/people`)}
            >
              {buttonText}
            </Button>
          </div>
        </Center>
      </div>
    </PageContainer>
  );
};
