import { useMauThreshold } from "./useMauThreshold";
import useFlag from "./useFlag";
import { useCurrentApp } from "./useCurrentApp";
import { PAYWALLED } from "../constants/features";

export const usePaywallModal = () => {
  const currentApp = useCurrentApp();
  const isPaywalled = useFlag(PAYWALLED);
  const {
    isApproachingLimit: isApproachingMauLimit,
    isAboveLimit,
    isTrialEnded,
  } = useMauThreshold();

  const isTrialFinished =
    currentApp.isTrialing && (currentApp.noCardTrialEnded || isTrialEnded);

  if (isPaywalled) return { isModalClosable: false, isTrialFinished };
  if (isTrialFinished) return { isModalClosable: false, isTrialFinished };
  if (isAboveLimit) return { isModalClosable: false, isTrialFinished };
  if (isApproachingMauLimit) return { isModalClosable: true, isTrialFinished };

  return { isModalClosable: true, isTrialFinished };
};
