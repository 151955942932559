import React from "react";
import { IAppObject } from "core/types/AppObject";
import { Integration } from "core/models/dataMappings";
import { CrmSyncStatus, ICrmSync } from "core/models/crmSyncs";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Spinner, Tooltip } from "@chakra-ui/react";
import { LastSyncStatusDot } from "./LastSyncStatusDot";
import { LastSyncStatusButton } from "./LastSyncStatusButton";

interface LastSyncStatusProps {
  appObject: IAppObject;
  lastCrmSync?: ICrmSync;
  status?: CrmSyncStatus;
  integration: Integration;
  lastSyncedAt?: string;
  isLoading?: boolean;
  onRefresh?: () => void;
}

export const LastSyncStatus: React.FC<LastSyncStatusProps> = ({
  appObject,
  lastCrmSync,
  status,
  lastSyncedAt,
  isLoading,
  onRefresh,
  integration,
}) => {
  if (!lastCrmSync) return null;

  const { objectsSyncedCount, totalObjectsCount } = lastCrmSync;

  if (status === CrmSyncStatus.Enqueued)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={status} />
        <p className="text-xs text-gray-600">
          {objectsSyncedCount} of {totalObjectsCount}{" "}
          {appObject.pluralName.toLocaleLowerCase()} have been enqueued
        </p>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Tooltip label="Refresh status" hasArrow>
            <ArrowPathIcon
              className="h-3 w-4 cursor-pointer text-gray-600"
              onClick={onRefresh}
            />
          </Tooltip>
        )}
      </div>
    );

  if (status === CrmSyncStatus.Started)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={status} />
        <p className="text-xs text-gray-600">
          {objectsSyncedCount} of {totalObjectsCount}{" "}
          {appObject.pluralName.toLocaleLowerCase()} syncing
        </p>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Tooltip label="Refresh status" hasArrow>
            <ArrowPathIcon
              className="h-3 w-4 cursor-pointer text-gray-600"
              onClick={onRefresh}
            />
          </Tooltip>
        )}
      </div>
    );

  if (status === CrmSyncStatus.Succeeded)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={status} />
        <p className="text-xs text-gray-600">
          <LastSyncStatusButton
            appObject={appObject}
            count={objectsSyncedCount}
            text={
              objectsSyncedCount === 1
                ? "with updated traits was synced"
                : "with updated traits were synced"
            }
            lastSyncedAt={lastSyncedAt}
            crmSync={lastCrmSync}
            integration={integration}
          />
        </p>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Tooltip label="Refresh status" hasArrow>
            <ArrowPathIcon
              className="h-3 w-4 cursor-pointer text-gray-600"
              onClick={onRefresh}
            />
          </Tooltip>
        )}
      </div>
    );

  if (status === CrmSyncStatus.Failed)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={status} />
        <p className="text-xs text-gray-600">Synced failed {lastSyncedAt}</p>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Tooltip label="Refresh status" hasArrow>
            <ArrowPathIcon
              className="h-3 w-4 cursor-pointer text-gray-600"
              onClick={onRefresh}
            />
          </Tooltip>
        )}
      </div>
    );

  return (
    <div className="flex items-center gap-2" data-testid="last-sync-status">
      <LastSyncStatusDot status={status} />
      <p className="text-xs text-gray-600">
        <LastSyncStatusButton
          appObject={appObject}
          count={totalObjectsCount}
          text={
            objectsSyncedCount === 1
              ? "with updated traits is ready to be synced"
              : "with updated traits are ready to be synced"
          }
          lastSyncedAt={lastSyncedAt}
          crmSync={lastCrmSync}
          integration={integration}
        />
      </p>
      {isLoading ? (
        <Spinner size="xs" />
      ) : (
        <Tooltip label="Refresh status" hasArrow>
          <ArrowPathIcon
            className="h-3 w-4 cursor-pointer text-gray-600"
            onClick={onRefresh}
          />
        </Tooltip>
      )}
    </div>
  );
};
