import humps from "humps";
import { SnakeCasedProperties } from "type-fest";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IPagy } from "core/types/Pagy";
import { JoinOperatorValue } from "core/types/Audience";
import { transformFilterGroups } from "helpers/params";
import { IFilterGroup } from "core/types/Filters";

// TODO: Configure interface later
export interface IEvent {
  id: string;
  name: string;
}

export interface IEventListResponse {
  events: IEvent[];
  pagy: IPagy;
}

export const objectEventsApi = createApi({
  baseQuery,
  reducerPath: "objectEventsApi",
  tagTypes: ["Event"],
  endpoints: (builder) => ({
    getEventList: builder.query<
      IEventListResponse,
      {
        appId: number;
        appObjectId: string;
        objectId: string;
        page?: number;
        items?: number;
        searchTerm?: string;
        filterGroups?: IFilterGroup[];
        joinOperator?: JoinOperatorValue;
      }
    >({
      query: ({
        appId,
        appObjectId,
        objectId,
        page = 1,
        items = 20,
        searchTerm,
        filterGroups,
        joinOperator,
      }: {
        appId: number;
        appObjectId: string;
        objectId: string;
        page?: number;
        items?: number;
        searchTerm?: string;
        filterGroups?: IFilterGroup[];
        joinOperator?: JoinOperatorValue;
      }) => ({
        url: `objects/events`,
        params: humps.decamelizeKeys({
          appId,
          appObjectId,
          objectId,
          page,
          items,
          searchTerm,
          filterGroups: filterGroups
            ? JSON.stringify(transformFilterGroups(filterGroups))
            : undefined,
          joinOperator,
        }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<{ events: IEvent[]; pagy: IPagy }>,
      ): IEventListResponse => {
        return {
          events: humps.camelizeKeys(response.events) as IEvent[],
          pagy: response.pagy,
        };
      },
      providesTags: (result) =>
        result?.events
          ? result.events.map((event) => ({ type: "Event", id: event.id }))
          : [],
    }),
  }),
});

export const { useGetEventListQuery } = objectEventsApi;
