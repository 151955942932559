import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { ITraitKeyResponse } from "core/models/traits";
import { Integration } from "core/models/dataMappings";
import { useTraits } from "core/hooks/useTraits";
import { useToast } from "core/hooks/useToast";
import {
  IDataMapping,
  ITempDataMapping,
  useDataMappings,
} from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { TraitOption } from "core/components/Traits/TraitOption";
import { BoltIcon } from "@heroicons/react/24/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, ComponentDefaultProps, Tooltip } from "@chakra-ui/react";

interface IEditableSourceMenu extends ComponentDefaultProps {
  dataMapping?: IDataMapping | ITempDataMapping;
  integration: Integration;
  appObject: IAppObject;
}

const GROUP_ID_TRAIT_OPTION: ITraitKeyResponse = {
  trait: "group_id",
  valueCount: 0,
};
const USER_ID_TRAIT_OPTION: ITraitKeyResponse = {
  trait: "user_id",
  valueCount: 0,
};

export const EditableSourceMenu: React.FC<IEditableSourceMenu> = ({
  dataMapping,
  integration,
  appObject,
}) => {
  const { appId } = useParams();
  const toast = useToast();
  const { updateMapping } = useDataMapping({
    appId: Number(appId),
    integration,
    appObject,
  });
  const { dataMappings } = useDataMappings({
    appId: Number(appId),
    integration,
    appObject,
  });
  const usedTraits = dataMappings.map((dm) => dm.source);

  function onSelect(source: string) {
    if (!dataMapping || !dataMapping.id || !dataMapping.destination) return;

    updateMapping({
      id: dataMapping.id,
      source,
      destination: dataMapping.destination,
    });
    toast({
      title: "Updated successfully",
    });
  }

  const { traits } = useTraits(appObject.objectType);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredTraits, setFilteredTraits] = useState(traits);

  function isTraitDisabled(trait: ITraitKeyResponse): boolean {
    return usedTraits.includes(trait.trait);
  }

  useEffect(() => {
    setFilteredTraits(traits);
    setSearchQuery("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traits?.length]);

  useEffect(() => {
    if (searchQuery) {
      const results = traits?.filter(({ trait }) =>
        trait.toLowerCase().includes(searchQuery),
      );
      setFilteredTraits(results);
    } else {
      setFilteredTraits(traits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="max-w-full">
      <Popover className="relative">
        <PopoverButton className="max-w-full">
          <Tooltip
            shouldWrapChildren
            label={
              dataMapping?.isUnique
                ? `The trait needs to be unique`
                : dataMapping?.isRequired
                  ? `This mapping is required by ${integration}`
                  : ""
            }
          >
            <Button
              variant="ghost"
              size="sm"
              borderRadius="lg"
              bg="gray.50"
              color={dataMapping?.source ? "black" : "red.500"}
              disabled={!filteredTraits?.length}
              maxW="full"
              minW="88px"
            >
              <div className="flex w-full items-center justify-center gap-1">
                <p className="truncate">
                  {dataMapping?.source || "Select trait"}
                </p>
                {dataMapping?.isComputed && (
                  <Tooltip label="Computed trait" hasArrow placement="right">
                    <BoltIcon className="h-3 w-3 text-purple-500" />
                  </Tooltip>
                )}
              </div>
            </Button>
          </Tooltip>
        </PopoverButton>
        <PopoverPanel className="flex flex-col">
          {({ close }) => (
            <>
              <Lifecycle onUnmount={() => setSearchQuery("")} />
              <Command>
                <CommandInput
                  placeholder="Search traits..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CommandList>
                  {filteredTraits?.length === 0 &&
                    appObject.objectType !== AppObjectType.Group && (
                      <span className="px-2 py-1 text-sm sm:leading-4">
                        No traits found
                      </span>
                    )}
                  {dataMapping?.isUnique &&
                    appObject.objectType === AppObjectType.User && (
                      <>
                        <CommandOption
                          value={() => {
                            onSelect(USER_ID_TRAIT_OPTION.trait);
                            close();
                          }}
                        >
                          <TraitOption trait={USER_ID_TRAIT_OPTION} />
                        </CommandOption>
                        <CommandOption
                          value={() => {
                            onSelect("email");
                            close();
                          }}
                        >
                          <TraitOption
                            trait={{ trait: "email", valueCount: 0 }}
                          />
                        </CommandOption>
                      </>
                    )}
                  {appObject.objectType === AppObjectType.Group && (
                    <Tooltip
                      label={
                        isTraitDisabled(GROUP_ID_TRAIT_OPTION)
                          ? "This trait has already been used"
                          : ""
                      }
                      hasArrow
                      placement="right"
                      shouldWrapChildren
                    >
                      <CommandOption
                        value={() => {
                          if (isTraitDisabled(GROUP_ID_TRAIT_OPTION)) return;
                          onSelect(GROUP_ID_TRAIT_OPTION.trait);
                          close();
                        }}
                      >
                        <TraitOption
                          trait={GROUP_ID_TRAIT_OPTION}
                          isDisabled={isTraitDisabled(GROUP_ID_TRAIT_OPTION)}
                        />
                      </CommandOption>
                    </Tooltip>
                  )}
                  {filteredTraits?.map((trait) => {
                    if (dataMapping?.isUnique) return null;
                    return (
                      <Tooltip
                        label={
                          isTraitDisabled(trait)
                            ? "This trait has already been used"
                            : ""
                        }
                        hasArrow
                        placement="right"
                        shouldWrapChildren
                      >
                        <CommandOption
                          value={() => {
                            if (isTraitDisabled(trait)) return;
                            onSelect(trait.trait);
                            close();
                          }}
                        >
                          <TraitOption
                            trait={trait}
                            isDisabled={isTraitDisabled(trait)}
                          />
                        </CommandOption>
                      </Tooltip>
                    );
                  })}
                </CommandList>
              </Command>
            </>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
