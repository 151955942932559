import React from "react";
import { LastSyncStatusDot } from "modules/Settings/Crm/SyncSettings/LastSyncStatusDot";
import { formatDistanceToNow } from "date-fns";
import { useGetLastStripeSyncQuery } from "core/models/stripeApps";
import { CrmSyncStatus } from "core/models/crmSyncs";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { InformationCircleIcon } from "@heroicons/react/16/solid";
import { Spinner, Tooltip } from "@chakra-ui/react";

export const LastSyncStatus: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const {
    data: lastStripeSync,
    isLoading,
    isFetching,
  } = useGetLastStripeSyncQuery({
    appId,
  });

  if (isLoading || isFetching) return <Spinner size="xs" />;
  if (!lastStripeSync) return null;

  if (lastStripeSync.status === CrmSyncStatus.Started)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={lastStripeSync.status} />
        <p className="text-xs text-gray-600">
          Sync started{" "}
          {formatDistanceToNow(new Date(lastStripeSync.createdAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    );

  if (lastStripeSync.status === CrmSyncStatus.Succeeded)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={lastStripeSync.status} />
        <p className="text-xs text-gray-600">
          Last sync succeeded{" "}
          {formatDistanceToNow(new Date(lastStripeSync.createdAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    );

  if (lastStripeSync.status === CrmSyncStatus.Failed)
    return (
      <div className="flex items-center gap-2" data-testid="last-sync-status">
        <LastSyncStatusDot status={lastStripeSync.status} />
        <p className="text-xs text-gray-600">
          Last sync failed{" "}
          {formatDistanceToNow(new Date(lastStripeSync.createdAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    );

  return (
    <div className="flex items-center gap-2" data-testid="last-sync-status">
      <LastSyncStatusDot status={lastStripeSync.status} />
      <p className="text-xs text-gray-600">Never synced</p>
      <Tooltip label="Sync runs every day at midnight">
        <InformationCircleIcon className="h-3 w-3 text-gray-400" />
      </Tooltip>
    </div>
  );
};
