import { useState } from "react";
import { useReportSetup } from "core/hooks/useReportSetup";
import { usePaywall } from "core/hooks/usePaywall";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { baseUrl } from "core/constants/paywall";
import {
  PaywallUpgradeHeader,
  PaywallUpgradeSubHeader,
} from "core/components/SavedAudiences/PaywallHeaders";
import { PaywallModal } from "core/components/Paywall/Modal";
import AnimatedToggle from "core/components/AnimatedToggle";
import { LockIcon } from "@chakra-ui/icons";
import { SavedAudiences } from "./SavedAudiences";
import { RecommendedFilters } from "./RecommendedFilters";

enum SuggestedAudienceTypes {
  AUDIENCES = "audiences",
  FILTERS = "filters",
}

export const SuggestedAudiences: React.FC<{
  onUpdateReport?: () => void;
}> = ({ onUpdateReport }) => {
  const currentApp = useCurrentApp();
  const { currentReport } = useReportSetup();
  const [suggestedAudienceType, setSuggestedAudienceType] =
    useState<SuggestedAudienceTypes>(SuggestedAudienceTypes.FILTERS);
  const { shouldBePaywalled } = usePaywall();
  const [shouldShowPaywallModal, setShouldShowPaywallModal] =
    useState<boolean>(false);

  return (
    <div className="mt-8 flex max-w-full flex-col rounded-md border bg-white p-4">
      <div className="mb-7 flex w-full items-start justify-between">
        <p className="mb-4 text-sm font-medium">
          {suggestedAudienceType === SuggestedAudienceTypes.FILTERS
            ? "Recently used filters"
            : "Audiences"}
        </p>
        <div className="flex">
          <PaywallModal
            // @ts-ignore
            HeaderTextComponent={PaywallUpgradeHeader}
            SubheaderTextComponent={PaywallUpgradeSubHeader}
            redirectUrl={`${baseUrl}/a/${currentApp.id}/report/${currentReport.id}`}
          >
            {(paywall: any) => (
              <paywall.Modal
                onClose={() => setShouldShowPaywallModal(false)}
                isOpen={shouldShowPaywallModal}
              />
            )}
          </PaywallModal>
          <AnimatedToggle
            items={[
              {
                value: SuggestedAudienceTypes.AUDIENCES,
                label: SuggestedAudienceTypes.AUDIENCES,
                endIcon: shouldBePaywalled && <LockIcon ml={1} />,
              },
              {
                value: SuggestedAudienceTypes.FILTERS,
                label: SuggestedAudienceTypes.FILTERS,
              },
            ]}
            value={suggestedAudienceType}
            onChange={(val) => {
              const value = val as SuggestedAudienceTypes;
              if (value === "audiences" && shouldBePaywalled) {
                return setShouldShowPaywallModal(true);
              }
              setSuggestedAudienceType(value);
            }}
          />
        </div>
      </div>
      <div>
        {suggestedAudienceType === SuggestedAudienceTypes.AUDIENCES ? (
          <SavedAudiences onUpdateReport={onUpdateReport} />
        ) : (
          <RecommendedFilters onUpdateReport={onUpdateReport} />
        )}
      </div>
    </div>
  );
};
