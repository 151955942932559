import { IAudienceFilters } from "core/types/Audience";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { Level } from "core/models/people";
import { usePeopleCount } from "core/hooks/usePeopleCount";
import { Tab } from "core/components/Tabs/Tab";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { Skeleton } from "@chakra-ui/react";

export const OBJECT_TYPE_TO_LEVEL = {
  [AppObjectType.User]: Level.User,
  [AppObjectType.Group]: Level.Group,
  [AppObjectType.Company]: Level.Group,
};

export const OBJECT_TYPE_TO_GROUP_TYPE: {
  [key in AppObjectType]?: GroupType | null;
} = {
  [AppObjectType.User]: null,
  [AppObjectType.Company]: GroupType.Company,
  [AppObjectType.Group]: GroupType.Group,
};

export const PeopleTab: React.FC<{
  appObject: IAppObject;
  activeAppObject: IAppObject;
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  onLevelChange: (appObject: IAppObject) => void;
}> = ({
  appObject,
  activeAppObject,
  audienceFilters,
  searchQuery,
  onLevelChange,
}) => {
  const groupType = OBJECT_TYPE_TO_GROUP_TYPE[
    appObject.objectType
  ] as GroupType;
  const level = OBJECT_TYPE_TO_LEVEL[appObject.objectType] as Level;

  const { matchedCount, totalCount, isLoadingCount } = usePeopleCount({
    audienceFilters,
    searchQuery,
    level,
    groupType,
  });

  return (
    <Tab
      key={appObject.objectType}
      tooltipLabel={`Out of ${totalCount?.toLocaleString()} ${appObject.pluralName.toLowerCase()}`}
      isSelected={activeAppObject?.objectType === appObject.objectType}
      onTabChange={() => onLevelChange(appObject)}
      pl={0}
    >
      <div className="flex gap-1">
        {isLoadingCount && (
          <div>
            <Skeleton h="20px" w="40px" />
          </div>
        )}
        {!isLoadingCount && <div>{matchedCount?.toLocaleString()} </div>}
        {appObject.pluralName.toLowerCase()}
      </div>
    </Tab>
  );
};
