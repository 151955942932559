import { AddMenu } from "modules/Views/AddMenu";
import { Button } from "Components/ui/button";
import { Squares2X2Icon } from "@heroicons/react/24/solid";
import { useIsEditingView } from "core/contexts/EditingViewContext";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { UseInternalUsersToggle } from "core/components/App/UseInternalUsersToggle";
import { IView } from "core/models/views";

export const ObjectContentActions: React.FC<{
  view: IView;
  isLoadingView: boolean;
  updateView: (view: IView) => void;
  isUpdatingView: boolean;
  showAddModal: boolean;
  setShowAddModal: (show: boolean) => void;
  showSQLModal: boolean;
  setShowSQLModal: (show: boolean) => void;
}> = ({
  view,
  isLoadingView,
  updateView,
  isUpdatingView,
  showAddModal,
  setShowAddModal,
  showSQLModal,
  setShowSQLModal,
}) => {
  const isEditing = useIsEditingView();
  const { toggleEditDashboard } = useEditDashboard();

  return (
    <div className="flex items-center gap-1">
      {view && (
        <UseInternalUsersToggle
          isEnabled={view.useInternalUsers}
          isLoading={isLoadingView || isUpdatingView}
          onChange={(useInternalUsers) => {
            updateView({
              ...view,
              useInternalUsers,
            });
          }}
        />
      )}
      {!isEditing && (
        <AddMenu
          onAddInsightClick={() => {
            setShowAddModal(!showAddModal);
            toggleEditDashboard();
          }}
          onAddSQLClick={() => {
            setShowSQLModal(!showSQLModal);
            toggleEditDashboard();
          }}
        />
      )}
      <Button
        key={isEditing ? "edit" : "done"}
        variant={isEditing ? "default" : "ghost"}
        size="sm"
        className="text-sm"
        onClick={() => {
          toggleEditDashboard();
        }}
      >
        {isEditing ? (
          <div className="flex items-center gap-1">Done</div>
        ) : (
          <div className="flex items-center gap-1 text-gray-600">
            <Squares2X2Icon className="size-4" />
            Edit
          </div>
        )}
      </Button>
    </div>
  );
};
