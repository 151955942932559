import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";

interface IColorPickerProps {
  color: IColorPalette;
  setColor: (color: IColorPalette) => void;
  colors?: IColorPalette[];
  shade?: 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  isDisabled?: boolean;
  disabledTooltip?: string;
  showChevron?: boolean;
}

export const DEFAULT_COLORS = [
  colors.red,
  colors.purple,
  colors.blue,
  colors.cyan,
  colors.yellow,
  colors.orange,
  colors.pink,
  colors.teal,
];

export const ColorPicker: React.FC<IColorPickerProps> = ({
  color,
  setColor,
  colors = DEFAULT_COLORS,
  shade = 500,
  isDisabled = false,
  disabledTooltip = "This graph type does not support colors",
  showChevron = true,
}) => {
  const sortedColors = colors.sort();

  return (
    <Menu>
      <Tooltip
        label={isDisabled ? disabledTooltip : ""}
        hasArrow
        shouldWrapChildren
        placement="top"
      >
        <MenuButton
          w="full"
          as={Button}
          rightIcon={showChevron ? <ChevronDownIcon /> : <></>}
          isDisabled={isDisabled}
        >
          <Box
            w={4}
            h={4}
            mr={2}
            borderRadius="full"
            bg={color && color[shade]}
          />
        </MenuButton>
      </Tooltip>
      <MenuList>
        <SimpleGrid columns={5} spacing={0}>
          {sortedColors.map((color) => (
            <MenuItem onClick={() => setColor(color)} _hover={{ bg: "white" }}>
              <Flex
                role="group"
                align="center"
                justify="center"
                _hover={{ cursor: "pointer", transform: "scale(1.2)" }}
              >
                <Flex
                  w={4}
                  h={4}
                  align="center"
                  justify="center"
                  borderRadius="50%"
                  bg={color[shade]}
                >
                  <Text
                    color="white"
                    fontSize="xs"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    transition="all 0.2s"
                  >
                    +
                  </Text>
                </Flex>
              </Flex>
            </MenuItem>
          ))}
        </SimpleGrid>
      </MenuList>
    </Menu>
  );
};
