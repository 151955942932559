import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { UserCombobox } from "core/UserCombobox";
import { DataTypeString } from "core/models/traits";
import { ITrait } from "core/models/objects/traits";
import { useTimeZone } from "core/hooks/useCurrentApp";
import { ComputationType } from "core/constants/traits";
import { ClipboardButton } from "core/components/ClipboardButton";
import { Tooltip, TooltipContent, TooltipTrigger } from "Components/ui/tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "Components/ui/select";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/16/solid";
import { cn } from "../../../../lib/utils";

function isValidUrl(string: string): boolean {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

const ChangeIcon: React.FC<{ value: string }> = ({ value }) => {
  if (Number(value) >= 0) {
    return <ArrowLongUpIcon className="h-4 text-green-500" />;
  }

  return <ArrowLongDownIcon className="h-4 text-red-500" />;
};

export const FormattedValue: React.FC<{
  trait: ITrait;
  value: string;
  onUpdateTrait: (value: string) => void;
}> = ({ trait, value, onUpdateTrait }) => {
  const isLink = value && isValidUrl(value);
  const isTimestamp =
    trait?.trait?.config?.computationType &&
    [ComputationType.FirstSeen, ComputationType.LastSeen].includes(
      trait?.trait?.config?.computationType,
    ) &&
    (moment(value).isValid() || trait?.trait?.dataType === DataTypeString.DATE);
  const timezone = useTimeZone();
  const formattedDate = moment.utc(value).tz(timezone).format("DD MMM YY");
  const formattedTime = moment.utc(value).tz(timezone).format("hh:mm a");
  const timeValue = `${formattedDate}`;
  const timeTooltip = `${formattedDate}, ${formattedTime}`;
  const isWorkspaceMember =
    trait?.trait?.dataType === DataTypeString.WORKSPACE_MEMBER;
  const isBoolean =
    value === "true" ||
    value === "false" ||
    trait?.trait?.config?.computationType === ComputationType.Boolean ||
    trait?.trait?.dataType === DataTypeString.BOOLEAN;
  const isOption = trait?.trait?.dataType === DataTypeString.OPTIONS;

  if (isOption) {
    const option = trait?.trait?.traitOptions?.find(
      (option) => option.value === value,
    );
    return (
      <Select key={value} onValueChange={onUpdateTrait}>
        <SelectTrigger
          className="h-full max-h-full min-h-[20px] border-none p-0"
          showChevron={false}
        >
          <div className="flex items-center gap-1">
            {option?.label ? (
              <>
                {option?.color && (
                  <div
                    className={cn("h-2.5 w-2.5 rounded-full", {
                      "bg-red-400": option?.color === "red",
                      "bg-blue-400": option?.color === "blue",
                      "bg-green-400": option?.color === "green",
                      "bg-yellow-400": option?.color === "yellow",
                      "bg-purple-400": option?.color === "purple",
                      "bg-pink-400": option?.color === "pink",
                      "bg-orange-400": option?.color === "orange",
                      "bg-white": !option?.color,
                    })}
                  />
                )}
                <p className="text-sm">{option?.label || "Unassigned"}</p>
              </>
            ) : (
              <p className="text-sm text-gray-500">Unassigned</p>
            )}
          </div>
        </SelectTrigger>
        <SelectContent>
          {trait?.trait?.traitOptions?.map((option) => (
            <SelectItem className="text-xs" value={option.value}>
              <div className="flex items-center gap-2">
                <div
                  className={cn("h-2.5 w-2.5 rounded-full", {
                    "bg-red-400": option?.color === "red",
                    "bg-blue-400": option?.color === "blue",
                    "bg-green-400": option?.color === "green",
                    "bg-yellow-400": option?.color === "yellow",
                    "bg-purple-400": option?.color === "purple",
                    "bg-pink-400": option?.color === "pink",
                    "bg-orange-400": option?.color === "orange",
                    "bg-white": !option?.color,
                  })}
                />
                <p className="text-sm">{option.label}</p>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }

  if (isBoolean) {
    return (
      <Select key={value} onValueChange={onUpdateTrait}>
        <SelectTrigger
          className="h-full max-h-full border-none p-0 shadow-none"
          showChevron={false}
        >
          {value === "-" ? (
            <div className="flex items-center gap-1">
              <p className="text-sm text-gray-500">Unassigned</p>
            </div>
          ) : (
            <>
              {value === "true" ? (
                <CheckCircleIcon className="h-4 w-4 text-green-400" />
              ) : (
                <XCircleIcon className="h-4 w-4 text-red-400" />
              )}
            </>
          )}
        </SelectTrigger>
        <SelectContent className="min-w-[10px]">
          <SelectItem className="text-xs" value="true">
            <CheckCircleIcon className="h-4 w-4 text-green-400" />
          </SelectItem>
          <SelectItem className="text-xs" value="false">
            <XCircleIcon className="h-4 w-4 text-red-400" />
          </SelectItem>
        </SelectContent>
      </Select>
    );
  }

  if (isTimestamp) {
    return (
      <Tooltip>
        <TooltipTrigger className="cursor-default">
          {timeValue === "-" ? "Select date" : timeValue}
        </TooltipTrigger>
        <TooltipContent className="px-2 py-1 pr-0">
          <div className="flex items-center gap-2">
            <p>{timeTooltip}</p>
            <ClipboardButton
              value={value}
              showLabel={false}
              bg="black"
              mr={0}
              color="white"
              _hover={{ bg: "gray.800" }}
            />
          </div>
        </TooltipContent>
      </Tooltip>
    );
  }

  if (isWorkspaceMember) {
    return <UserCombobox value={value} onValueChange={onUpdateTrait} />;
  }

  if (isLink)
    return (
      <Tooltip>
        <TooltipTrigger>
          <Link
            className="text-blue-500 hover:underline hover:[text-decoration-thickness:1px] hover:[text-underline-offset:2px]"
            to={value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </Link>
        </TooltipTrigger>
        <TooltipContent>
          <div className="flex items-center gap-2">
            <p>{value}</p>
            <ClipboardButton
              value={value}
              showLabel={false}
              bg="black"
              mr={0}
              color="white"
              _hover={{ bg: "gray.800" }}
            />
          </div>
        </TooltipContent>
      </Tooltip>
    );

  if (
    [ComputationType.Ratio, ComputationType.EventCountChange].includes(
      trait.trait?.config?.computationType ?? 0,
    )
  )
    return (
      <div className="flex items-center gap-0">
        <p className="flex-shrink-0 whitespace-nowrap">
          <ChangeIcon value={value} />
        </p>
        <p className="truncate text-right">{value}</p>
        <p className="flex-shrink-0 whitespace-nowrap">%</p>
      </div>
    );

  return (
    <Tooltip>
      <TooltipTrigger
        className={cn(
          "cursor-default",
          !trait?.trait?.isComputed && "cursor-pointer",
        )}
      >
        <div className="flex items-center gap-1">
          {trait.trait?.prefix && (
            <p className="flex-shrink-0 whitespace-nowrap">
              {trait.trait?.prefix}
            </p>
          )}
          <p className="truncate text-right">{value}</p>
          {trait.trait?.suffix && (
            <p className="flex-shrink-0 whitespace-nowrap">
              {trait.trait?.suffix}
            </p>
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <div className="flex items-center gap-2">
          {trait.trait?.prefix && (
            <p className="flex-shrink-0 whitespace-nowrap">
              {trait.trait?.prefix}
            </p>
          )}
          <p className="truncate text-right">{value}</p>
          {trait.trait?.suffix && (
            <p className="flex-shrink-0 whitespace-nowrap">
              {trait.trait?.suffix}
            </p>
          )}
          <ClipboardButton
            value={value}
            showLabel={false}
            bg="black"
            mr={0}
            color="white"
            _hover={{ bg: "gray.800" }}
          />
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
