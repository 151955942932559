import React, { useEffect } from "react";
import { AddMapping } from "modules/Integrations/AddMapping";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { Integration, integrationNames } from "core/models/dataMappings";
import { useTraits } from "core/hooks/useTraits";
import { ITempDataMapping, useDataMappings } from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useCrmProperties } from "core/hooks/useCrmProperties";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Divider, Text, Tooltip } from "@chakra-ui/react";
import { MappingRow } from "./MappingRow";

interface IMappingTableProps {
  appObject: IAppObject;
  integration: Integration;
  isLoading: boolean;
}

export const MappingTable: React.FC<IMappingTableProps> = ({
  appObject,
  integration,
  isLoading,
}) => {
  const { id: appId } = useCurrentApp();
  const singularExtTraitWord =
    integration === Integration.Hubspot ? "property" : "attribute";
  const pluralExtTraitWord =
    integration === Integration.Hubspot ? "properties" : "attributes";
  const integrationName = integrationNames[integration];

  const { traits } = useTraits(appObject.objectType);
  const {
    properties,
    currentProperties,
    page,
    search,
    hasMore,
    setPage,
    setSearch,
    onGetProperties,
  } = useCrmProperties({
    appId,
    integration,
    objectType: appObject.objectType,
  });
  const { dataMappings, tempMappings, setTempMappings } = useDataMappings({
    appId,
    integration,
    appObject,
  });

  const { matchingIdentifierDataMapping } = useDataMapping({
    appId,
    integration,
    appObject,
  });

  const selectedTraits = dataMappings.map((mapping) => mapping.source);
  const availableTraits = traits?.filter(
    (trait) =>
      !selectedTraits.includes(trait.trait) &&
      !tempMappings.map((m) => m.source).includes(trait.trait) &&
      matchingIdentifierDataMapping?.source !== trait.trait,
  );

  useEffect(() => {
    window.addEventListener("visibilitychange", onGetProperties);

    return () => {
      window.removeEventListener("visibilitychange", onGetProperties);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <p className="text-sm">
          Which June {appObject.pluralName?.toLowerCase()} traits should we sync
          to <p className="inline-block capitalize">{integrationName}</p>{" "}
          {appObject.pluralName?.toLowerCase()}?
        </p>
        <p className="my-2 text-sm text-gray-500">
          These are the June {appObject.pluralName?.toLowerCase()} traits which
          we will sync to{" "}
          <p className="inline-block capitalize">{integrationName}</p>
          {` `}
          {appObject.pluralName?.toLowerCase()}
        </p>
      </div>
      <div className="mt-6 flex w-full flex-row items-center justify-between">
        <div>
          <p className=" text-sm  text-gray-500">June traits</p>
        </div>
        <div>
          <div className="flex w-full flex-row items-center justify-end gap-1">
            <p className=" text-sm capitalize text-gray-500">
              {integrationName}{" "}
              <Text as="span" textTransform="lowercase">
                {pluralExtTraitWord}
              </Text>
            </p>
            <Tooltip
              hasArrow
              label={`Don't see a ${integrationName} ${singularExtTraitWord}? Create one in ${integrationName}`}
              placement="top"
            >
              <InformationCircleIcon className="h-4 text-gray-500" />
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider my={3} />
      <div className="flex flex-col gap-4">
        {[...dataMappings, ...tempMappings].map((mapping, index) => {
          return (
            <MappingRow
              key={JSON.stringify(mapping) + index}
              dataMappings={dataMappings}
              dataMapping={mapping}
              appObject={appObject}
              integration={integration}
              isLoading={isLoading}
              properties={properties}
              currentProperties={currentProperties}
              page={page}
              search={search}
              hasMore={hasMore}
              setPage={setPage}
              setSearch={setSearch}
              deleteTempMapping={(trait: string) =>
                setTempMappings(tempMappings.filter((m) => m.source !== trait))
              }
            />
          );
        })}
      </div>
      <div className="flex">
        {availableTraits && (
          <AddMapping
            addMapping={(tmpDataMapping: ITempDataMapping) => {
              setTempMappings([...tempMappings, tmpDataMapping]);
            }}
            traits={availableTraits}
            showGroupIdOption={appObject.objectType === AppObjectType.Group}
            groupIdOptionDisabled={
              tempMappings.some((m) => m.source === "group_id") ||
              dataMappings.some((m) => m.source === "group_id")
            }
            juneProfileUrlOptionDisabled={
              tempMappings.some((m) => m.source === "june_profile_url") ||
              dataMappings.some((m) => m.source === "june_profile_url")
            }
          />
        )}
      </div>
    </>
  );
};
