import { IEvent, useGetEventListQuery } from "core/models/objects/events";
import { useParams } from "react-router-dom";
import ContactEventLogGroups from "modules/Contacts/ContactEventLogGroups";
import { Skeleton } from "Components/ui/skeleton";
import { SearchBar } from "core/components/SearchBar";
import { useEffect, useState } from "react";
import { Button } from "Components/ui/button";
import { ButtonLoading } from "Components/ui/buttonLoading";
import { IAudience, JoinOperatorValue } from "core/types/Audience";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { EMPTY_FILTER_GROUPS } from "helpers/filters";

export const ObjectFeedList = ({
  useInternalUsers,
}: {
  useInternalUsers: boolean;
}) => {
  const { appId, appObjectId, objectId } = useParams();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const [events, setEvents] = useState<IEvent[]>([]);
  const { audience: internalUsersAudience } = useCurrentApp();

  const audience = useInternalUsers
    ? internalUsersAudience
    : (EMPTY_FILTER_GROUPS as unknown as IAudience);

  const { data, isLoading, isFetching } = useGetEventListQuery({
    appId: Number(appId),
    appObjectId: String(appObjectId),
    objectId: String(objectId),
    page,
    searchTerm,
    filterGroups: audience.filterGroups,
    joinOperator: audience.joinOperator as JoinOperatorValue,
  });

  const onSearch = (query: string) => {
    setSearchTerm(query);
    setPage(1);
  };

  useEffect(() => {
    if (!data?.events) return;

    const isFirstPage = page === 1;
    const isSearching = Boolean(searchTerm);
    const searchCleared = Boolean(previousSearchTerm) && !isSearching;

    if (isFirstPage && (isSearching || searchCleared)) {
      setEvents(data.events);
    } else {
      setEvents((prev) => [...prev, ...data.events]);
    }

    setPreviousSearchTerm(searchTerm);
  }, [data, searchTerm]);

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 px-4 py-4">
        <div className="flex items-center space-x-2">
          <Skeleton className="h-2 w-2 rounded-full" />
          <Skeleton className="h-2 w-[250px]" />
        </div>
        <div className="flex items-center space-x-2">
          <Skeleton className="h-2 w-2 rounded-full" />
          <Skeleton className="h-2 w-[250px]" />
        </div>
        <div className="flex items-center space-x-2">
          <Skeleton className="h-2 w-2 rounded-full" />
          <Skeleton className="h-2 w-[250px]" />
        </div>
      </div>
    );
  }

  if (!events) {
    return <div className="flex flex-col gap-4 px-4 py-4">No events found</div>;
  }

  return (
    <div className="px-4">
      <SearchBar
        mb={-4}
        bg="white"
        searchQuery={searchTerm}
        onSearch={onSearch}
        placeholder="Search your events by name..."
      />
      <ContactEventLogGroups
        key={events.length}
        eventLogs={events}
        showUser={true}
      />
      {data?.pagy && data.pagy.last > data.pagy.page && (
        <div className="mt-6 flex w-full justify-center">
          {isLoading || isFetching ? (
            <ButtonLoading />
          ) : (
            <Button variant="outline" onClick={() => setPage(page + 1)}>
              Load more..
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
