import { GraphType, Interval, Measure, Size } from "core/types/ViewInsight";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { IAppObject } from "core/types/AppObject";
import { IViewInsight } from "core/models/viewInsights";
import { IUpdateInsightPayload } from "core/hooks/useEditViewInsight";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { SetupContainer } from "./Setup/Container";
import { PreviewContainer } from "./Preview/Container";

export const Sizes = {
  [Size.Small]: { width: "315px", height: "315px" },
  [Size.Medium]: { width: "630px", height: "315px" },
  [Size.Large]: { width: "945px", height: "315px" },
};

export const SIZE_TO_INDEX = {
  [Size.Small]: 1,
  [Size.Medium]: 2,
  [Size.Large]: 3,
};

export const GRID_TO_PX = ["0px", "315px", "650px", "985px"];

export const LayoutContainer: React.FC<{
  viewInsight: IViewInsight | null;
  report: IReport;
  templateConfig: ITemplateConfig;
  measure: Measure;
  appObject: IAppObject;
  graphType: GraphType;
  size: Size;
  colorPalette: IColorPalette;
  colors: IColorPalette[];
  title: string;
  interval: Interval;
  isAddingNewInsight?: boolean;
  useViewAudience: boolean;
  onUpdateReport: () => void;
  onUpdateInsight: (payload: IUpdateInsightPayload) => void;
  isUpdatingViewInsight: boolean;
}> = ({
  viewInsight,
  report,
  templateConfig,
  measure,
  graphType,
  size,
  colorPalette,
  colors,
  title,
  interval,
  isAddingNewInsight,
  appObject,
  useViewAudience,
  onUpdateReport,
  onUpdateInsight,
  isUpdatingViewInsight,
}) => {
  return (
    <div className="grid h-full grid-cols-6 items-center gap-x-5">
      <div className="col-span-4 h-full">
        {viewInsight && (
          <PreviewContainer
            viewInsight={viewInsight}
            report={report}
            measure={measure}
            size={size}
            width={Sizes[size].width}
            height={Sizes[size].height}
            graphType={graphType}
            colorPalette={colorPalette}
            colors={colors}
            title={title}
            interval={interval}
            appObject={appObject}
            isAddingNewInsight={isAddingNewInsight}
            onUpdateInsight={onUpdateInsight}
            isUpdatingViewInsight={isUpdatingViewInsight}
          />
        )}
      </div>
      <div className="col-span-2 h-full overflow-y-auto">
        {viewInsight && report && (
          <SetupContainer
            report={report}
            templateConfig={templateConfig}
            measure={measure}
            appObject={appObject}
            isAddingNewInsight={isAddingNewInsight}
            useViewAudience={useViewAudience}
            onUpdateReport={onUpdateReport}
            onUpdateInsight={onUpdateInsight}
          />
        )}
      </div>
    </div>
  );
};
