import { Link } from "react-router-dom";
import { ITrait } from "core/types/Trait";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { isValidUrl } from "core/helpers/isValidUrl";
import { avatarFromTraits } from "core/helpers/contactAvatar";
import { getCompanyName } from "core/helpers/companyName";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { NEW_PROFILE } from "core/constants/features";
import useFlag from "core/hooks/useFlag";
import { useAppObjects } from "core/hooks/useAppObjects";

interface IGroupNameCell {
  props: {
    appId?: string | number;
    id: string;
    name?: string;
    traits: ITrait;
  };
}

function GroupNameCell({ props: { appId, id, name, traits } }: IGroupNameCell) {
  const hasNewProfile = useFlag(NEW_PROFILE);
  const { groupAppObject } = useAppObjects();
  const companyName = getCompanyName({ name, traits });
  const avatar = avatarFromTraits(traits);
  const logoURL = useClearbitLogo({ domain: name });
  const url = isValidUrl(avatar) ? avatar : logoURL;

  if (!id) {
    return (
      <Flex align="flex-start">
        <Tooltip label="Pass the group_id to your track calls in order to link an event with a company.">
          <Text fontSize="sm">-</Text>
        </Tooltip>
      </Flex>
    );
  }

  if (!companyName) {
    return (
      <Link
        to={
          hasNewProfile
            ? `/a/${appId}/objects/${groupAppObject?.id}/object/${id}`
            : `/a/${appId}/group/${id}`
        }
      >
        <Flex align="center">
          <CompanyAvatar size="sm" group={{ id }} logoURL={url} />
          <Tooltip label="The name trait is missing. This is caused by not passing a company name as part of your group calls.">
            <Flex ml={3} align="flex-start" direction="column">
              <Text fontSize="sm" color="gray.600">
                {id}
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      </Link>
    );
  }

  return (
    <Link
      to={
        hasNewProfile
          ? `/a/${appId}/objects/${groupAppObject?.id}/object/${id}`
          : `/a/${appId}/group/${id}`
      }
    >
      <div className="flex w-full items-center gap-2">
        <div className="min-w-[30px]">
          <CompanyAvatar size="sm" group={{ id }} logoURL={url} />
        </div>
        <Tooltip label={companyName}>
          <p className="truncate text-sm">{companyName}</p>
        </Tooltip>
      </div>
    </Link>
  );
}

export default GroupNameCell;
