import { Button } from "@chakra-ui/react";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import {
  InfoBody,
  InfoCard,
  InfoHeader,
  InfoReadMoreLink,
} from "core/design-system/components/InfoCard";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAppObjectQuery } from "core/models/appObjects";
import DefaultHeader from "modules/Pages/DefaultHeader";
import AppObjectTraits from "modules/Settings/Objects/Object/AppObjectTraits";
import { Link, useParams } from "react-router-dom";
import { AddTraitsModal } from "./AddTraitsModal";

export const ViewObjectTraits: React.FC = () => {
  const currentApp = useCurrentApp();
  const { objectId } = useParams();
  const { data: appObject, isLoading } = useGetAppObjectQuery({
    appId: currentApp.id,
    id: Number(objectId),
  });

  const section = {
    name: "Traits",
    title: "Traits",
    description: `Manage ${appObject?.singularName} traits.`,
  };

  const pages = [
    { name: "Objects", path: "../objects", current: false },
    {
      name: appObject?.pluralName ?? "Object",
      path: `../objects/${objectId}`,
      current: false,
    },
    {
      name: "Traits",
      path: "#",
      current: true,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex w-[985px] flex-col">
      <div className="flex flex-col gap-4">
        <Breadcrumbs pages={pages} />
        <DefaultHeader
          w="inherit"
          section={{
            ...section,
            sectionRight: () => (
              <div className="flex items-center gap-2">
                <AddTraitsModal />
                <Button>
                  <Link className="flex items-center gap-2" to={`./archived`}>
                    <ArchiveBoxIcon className="h-4 w-4" />
                    Deleted
                  </Link>
                </Button>
              </div>
            ),
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <InfoCard>
          <InfoHeader>What is a trait?</InfoHeader>
          <InfoBody>
            Traits can be Computed or Non-Computed. Computed traits are computed
            based on events. They are computed daily and useful to understand
            product usage, both in June and your
            <strong> CRM</strong>. Non-computed traits mostly have static values
            that don’t change often and sent in your tracking event or created
            in profiles or audiences in June.
          </InfoBody>
          <InfoReadMoreLink
            articleId="8394436"
            href="https://help.june.so/en/articles/8394436-computed-traits"
          />
        </InfoCard>
      </div>
      <div className="mt-4 flex flex-col gap-4">
        {appObject && <AppObjectTraits appObject={appObject} />}
      </div>
    </div>
  );
};
