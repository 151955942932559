import { ITrait, usePinTraitMutation } from "core/models/objects/traits";
import { Button } from "Components/ui/button";
import {
  Dialog,
  DialogDescription,
  DialogTitle,
  DialogHeader,
  DialogContent,
  DialogTrigger,
} from "Components/ui/dialog";
import { Label } from "Components/ui/label";
import { Separator } from "Components/ui/separator";
import { useParams } from "react-router-dom";
import { Value } from "../Sections/Traits/Value";
import { BoltIcon, PencilIcon } from "@heroicons/react/16/solid";
import { Trait } from "../Sections/Traits/Trait";
import { PinIcon, PinOffIcon } from "lucide-react";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Tooltip, TooltipTrigger, TooltipContent } from "Components/ui/tooltip";
import { useEffect, useRef } from "react";

export const HeaderPinnedTraits: React.FC<{
  traits: ITrait[];
}> = ({ traits }) => {
  const { appId, appObjectId, objectId } = useParams();
  const { appObjects } = useAppObjects();
  const appObject = appObjects.find(
    (appObject) => appObject.id === Number(appObjectId),
  );
  const [pinTrait] = usePinTraitMutation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  function checkOverflow() {
    const target = scrollContainerRef.current;
    if (!target) return;

    const scrollableWidth = target.scrollWidth - target.clientWidth;
    const hasRightContent = Math.ceil(target.scrollLeft) < scrollableWidth;
    const hasLeftContent = target.scrollLeft > 1;

    target.classList.toggle("border-r", hasRightContent);
    target.classList.toggle("border-l", hasLeftContent);
  }

  useEffect(() => {
    checkOverflow();

    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [traits]);

  return (
    <div className="flex min-w-0 items-center justify-between gap-2">
      <div
        ref={scrollContainerRef}
        onScroll={checkOverflow}
        className="min-w-0 flex-1 overflow-x-auto [&::-webkit-scrollbar]:hidden"
      >
        <div className="flex items-center gap-8 border-l-transparent px-4">
          {traits
            .filter((trait) => trait?.trait?.isPinned)
            .sort((a, b) => {
              const aCreatedAt = a?.trait?.createdAt || "";
              const bCreatedAt = b?.trait?.createdAt || "";
              return aCreatedAt.localeCompare(bCreatedAt);
            })
            .map((trait) => {
              return (
                <div
                  key={trait.name}
                  className="flex max-w-[200px] flex-shrink-0 flex-col justify-start gap-1 text-sm"
                >
                  <div className="flex h-[45px] flex-col justify-between">
                    <div className="flex items-center gap-x-1">
                      <Label className="truncate text-xs text-gray-500">
                        {trait.name}
                      </Label>
                      {trait.trait?.isComputed && (
                        <BoltIcon className="size-3 text-purple-400" />
                      )}
                    </div>
                    <Value
                      trait={trait}
                      className="-ml-1 w-full items-start truncate pl-1 pr-2 font-medium"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex-shrink-0">
        <Dialog>
          <DialogTrigger>
            <Tooltip>
              <TooltipTrigger>
                <Button variant="ghost" size="sm" className="text-sm">
                  <PencilIcon className="size-4 text-gray-500" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Edit header</p>
              </TooltipContent>
            </Tooltip>
          </DialogTrigger>
          <DialogContent className="max-h-[70vh] overflow-y-auto">
            <DialogHeader className="text-left">
              <DialogTitle>Edit header</DialogTitle>
              <DialogDescription>
                These are the traits that will be displayed in the header of all{" "}
                {appObject?.pluralName?.toLowerCase()}.
              </DialogDescription>
            </DialogHeader>
            <div>
              <Label>Pinned traits</Label>
              <div className="mt-2 flex flex-col gap-2">
                {traits
                  ?.filter((trait) => trait.trait?.isPinned)
                  .map((trait) => {
                    return (
                      <div
                        key={trait.name}
                        className="flex w-full flex-row items-center gap-2"
                      >
                        <div className="w-full rounded-md border border-gray-200 px-3">
                          <Trait trait={trait} />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() =>
                            pinTrait({
                              appId: Number(appId),
                              appObjectId: Number(appObjectId),
                              objectId: String(objectId),
                              traitId: trait?.trait?.id
                                ? Number(trait?.trait?.id)
                                : undefined,
                              traitName: trait?.name,
                              isPinned: false,
                            })
                          }
                        >
                          <PinOffIcon className="size-4 text-gray-500" />
                        </Button>
                      </div>
                    );
                  })}
              </div>
              <Separator className="my-4" />
              <Label>Traits</Label>
              <div className="mt-2 flex flex-col gap-2">
                {traits
                  ?.filter((trait) => !trait.trait?.isPinned)
                  .map((trait) => {
                    return (
                      <div
                        key={trait.name}
                        className="flex w-full flex-row items-center gap-2"
                      >
                        <div className="w-full rounded-md border border-gray-200 px-3">
                          <Trait trait={trait} />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() =>
                            pinTrait({
                              appId: Number(appId),
                              appObjectId: Number(appObjectId),
                              objectId: String(objectId),
                              traitId: trait?.trait?.id
                                ? Number(trait?.trait?.id)
                                : undefined,
                              traitName: trait?.name,
                              isPinned: true,
                            })
                          }
                        >
                          <PinIcon className="size-4 text-gray-500" />
                        </Button>
                      </div>
                    );
                  })}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
