import {
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "Components/ui/dropdown-menu";
import { DropdownMenu } from "Components/ui/dropdown-menu";
import { TrashIcon } from "lucide-react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import { Dialog } from "Components/ui/dialog";
import { useGetAppObjectQuery } from "core/models/appObjects";
import { AppObjectType } from "core/types/AppObject";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { DeleteObjectDialog } from "./DeleteObjectDialog";
import { MergeObjectDialog } from "./MergeObjectDialog";
import { useState } from "react";
import { Button } from "Components/ui/button";

export const OverflowMenu = () => {
  const { appId, appObjectId } = useParams();
  const { data: appObject } = useGetAppObjectQuery({
    appId: Number(appId),
    id: Number(appObjectId),
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [openMerge, setOpenMerge] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button variant="ghost" size="sm" className="px-2">
            <EllipsisVerticalIcon className="size-4 text-muted-foreground" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-2 w-48">
          <DropdownMenuItem onClick={() => setOpenDelete(true)}>
            <div className="flex cursor-pointer items-center gap-2">
              <TrashIcon className="size-4 text-muted-foreground" />
              Delete {appObject?.singularName}
            </div>
          </DropdownMenuItem>
          {appObject?.objectType === AppObjectType.Company && (
            <DropdownMenuItem onClick={() => setOpenMerge(true)}>
              <div className="flex cursor-pointer items-center gap-2">
                <ArrowsRightLeftIcon className="size-4 text-muted-foreground" />
                Merge company
              </div>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={openDelete} onOpenChange={setOpenDelete}>
        {appObject && <DeleteObjectDialog appObject={appObject} />}
      </Dialog>

      <Dialog open={openMerge} onOpenChange={setOpenMerge}>
        {appObject && (
          <MergeObjectDialog
            appObject={appObject}
            onClose={() => setOpenMerge(false)}
          />
        )}
      </Dialog>
    </>
  );
};
