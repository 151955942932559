import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  ComponentDefaultProps,
  Box,
  Text,
  Flex,
  Skeleton,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IView, useAddViewMutation } from "core/models/views";
import { useGetAudiencesQuery } from "core/models/audiences";
import {
  ArrowForwardIcon,
  CheckCircleIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

interface ITemplateProps extends ComponentDefaultProps {
  onClose: () => void;
  setStep: (step: string) => void;
  pin: boolean;
}

const CHOOSE_TEMPLATE = "choose_template";
const BLANK_TEMPLATE = "blank_template";
const COMPARISON_TEMPLATE = "comparison_template";
const AUDIENCE_TEMPLATE = "audience_template";
const ACTIVE_USERS_TEMPLATE = "active_users_template";

export const Card: React.FC<ComponentDefaultProps> = ({
  isSelected,
  children,
  onClick,
  ...props
}) => {
  return (
    <Box
      w="full"
      _hover={{ bg: isSelected ? "purple.100" : "gray.50" }}
      cursor="pointer"
      borderRadius="lg"
      p={3}
      border="1px solid"
      borderColor={isSelected ? "purple.400" : "gray.200"}
      onClick={onClick}
      bg={isSelected ? "purple.50" : "white"}
      color={isSelected ? "purple.500" : "gray.800"}
      fontWeight={isSelected ? "medium" : "normal"}
      mt={2}
    >
      <Flex align="center" justifyContent="space-between">
        <Flex align="center" gridGap={2}>
          {props.leftIcon && <Flex>{props.leftIcon}</Flex>}
          {children}
        </Flex>
        {props.rightIcon && <Flex>{props.rightIcon}</Flex>}
      </Flex>
    </Box>
  );
};

export const TemplateModal: React.FC<{
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
  setStep?: (step: string) => void;
}> = ({ header, body, footer, setStep, ...props }) => {
  return (
    <Box>
      <ModalHeader>
        <Flex align="center" gridGap={1}>
          {setStep && (
            <Flex>
              <Button variant="ghost" onClick={() => setStep(CHOOSE_TEMPLATE)}>
                <ArrowBackIcon />
              </Button>
            </Flex>
          )}
          <Flex>{header}</Flex>
          <Flex mt={2}>
            <ModalCloseButton />
          </Flex>
        </Flex>
      </ModalHeader>
      <ModalBody maxH="500px" overflowY="scroll">
        {body}
      </ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Box>
  );
};

export const ChooseTemplate: React.FC<ITemplateProps> = ({
  onClose,
  setStep,
  pin,
  ...props
}) => {
  return (
    <TemplateModal
      header={"Create a dashboard"}
      body={
        <Box>
          <Text color="gray.600" fontSize="sm">
            Choose from one of the following templates
          </Text>
          <Card
            rightIcon={<ArrowForwardIcon />}
            onClick={() => setStep(BLANK_TEMPLATE)}
          >
            <Text fontSize="sm">
              <Text fontSize="md" as="span" mr={1}>
                📄
              </Text>{" "}
              Start from scratch
            </Text>
          </Card>
          <Card
            rightIcon={<ArrowForwardIcon />}
            onClick={() => setStep(ACTIVE_USERS_TEMPLATE)}
          >
            <Text fontSize="sm">
              <Text fontSize="md" as="span" mr={1}>
                🏃
              </Text>{" "}
              Active users
            </Text>
          </Card>
          <Card
            rightIcon={<ArrowForwardIcon />}
            onClick={() => setStep(COMPARISON_TEMPLATE)}
          >
            <Text fontSize="sm">
              <Text fontSize="md" as="span" mr={1}>
                🤝
              </Text>{" "}
              Compare audiences
            </Text>
          </Card>
          <Card
            rightIcon={<ArrowForwardIcon />}
            onClick={() => setStep(AUDIENCE_TEMPLATE)}
          >
            <Text fontSize="sm">
              <Text fontSize="md" as="span" mr={1}>
                🔍
              </Text>{" "}
              Deep dive into an audience
            </Text>
          </Card>
        </Box>
      }
      footer={
        <Button variant="ghost" mr={3} onClick={onClose}>
          Cancel
        </Button>
      }
    />
  );
};

export const BlankTemplate: React.FC<ITemplateProps> = ({
  onClose,
  setStep,
  pin,
  ...props
}) => {
  const { id: appId } = useCurrentApp();
  const [name, setName] = useState<string>("Untitled");
  const [addView] = useAddViewMutation();
  const navigate = useNavigate();

  async function onCreate() {
    const { data } = (await addView({ appId, name, pin })) as { data: IView };
    navigate(`/a/${appId}/home/${data?.id}`);
    onClose();
  }
  return (
    <TemplateModal
      setStep={setStep}
      header="📄 Start from scratch"
      body={
        <Input value={name} onChange={(e) => setName(e.currentTarget.value)} />
      }
      footer={
        <>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="purple" onClick={onCreate}>
            Create
          </Button>
        </>
      }
    />
  );
};

export const ActiveUsersTemplate: React.FC<ITemplateProps> = ({
  onClose,
  setStep,
  pin,
}) => {
  const { id: appId } = useCurrentApp();
  const [created, setCreated] = useState<boolean>(false);
  const [addView, { isLoading }] = useAddViewMutation();

  useEffect(() => {
    addView({
      appId,
      template: ACTIVE_USERS_TEMPLATE,
      templateParams: {},
      pin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  useEffect(() => {
    if (isLoading && created) {
      onClose();
    }

    setCreated(!isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, created]);

  return (
    <TemplateModal
      setStep={setStep}
      header="🏃 Active users"
      body={
        <div className="flex h-full w-full items-center">
          <div>
            <Spinner />
            <p>Creating view...</p>
          </div>
        </div>
      }
      footer={<></>}
    />
  );
};

export const ComparisonTemplate: React.FC<ITemplateProps> = ({
  onClose,
  setStep,
  pin,
  ...props
}) => {
  const { id: appId } = useCurrentApp();
  const [audienceIds, setAudienceIds] = useState<number[]>([]);
  const { data, isLoading } = useGetAudiencesQuery({ appId });
  const audiences = data?.audiences || [];

  const [addView] = useAddViewMutation();
  function onCreate() {
    addView({
      appId,
      template: COMPARISON_TEMPLATE,
      templateParams: { audienceIds },
      pin,
    });
    onClose();
  }

  return (
    <TemplateModal
      setStep={setStep}
      header="🤝 Compare audiences"
      body={
        <>
          <Text mb={4} fontSize="sm" color="gray.600">
            Please select at least two audiences
          </Text>
          {isLoading ? (
            <Flex direction="column" gridGap={2}>
              <Skeleton h="45px" w="full" />
              <Skeleton h="45px" w="full" />
            </Flex>
          ) : (
            <Box>
              {audiences.map((audience) => {
                const isSelected =
                  audience.id && audienceIds.includes(audience.id);
                return (
                  <Card
                    isSelected={isSelected}
                    onClick={() =>
                      isSelected
                        ? setAudienceIds(
                            audienceIds.filter((id) => id !== audience.id),
                          )
                        : audience.id &&
                          setAudienceIds([...audienceIds, audience.id])
                    }
                    rightIcon={isSelected && <CheckCircleIcon />}
                  >
                    <Text fontSize="sm">{audience.name}</Text>
                  </Card>
                );
              })}
            </Box>
          )}
        </>
      }
      footer={
        <>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Tooltip
            shouldWrapChildren
            hasArrow
            label={
              audienceIds.length < 2
                ? "Please select at least two audiences"
                : "Please select max four audiences"
            }
          >
            <Button
              isDisabled={audienceIds.length < 2 || audienceIds.length > 3}
              colorScheme="purple"
              onClick={onCreate}
            >
              Create
            </Button>
          </Tooltip>
        </>
      }
    />
  );
};

export const AudienceTemplate: React.FC<ITemplateProps> = ({
  onClose,
  setStep,
  pin,
  ...props
}) => {
  const { id: appId } = useCurrentApp();
  const [audienceIds, setAudienceIds] = useState<number[]>([]);
  const { data, isLoading } = useGetAudiencesQuery({ appId });
  const audiences = data?.audiences || [];

  const [addView] = useAddViewMutation();
  function onCreate() {
    addView({
      appId,
      template: AUDIENCE_TEMPLATE,
      templateParams: { audienceIds },
      pin,
    });
    onClose();
  }

  return (
    <TemplateModal
      setStep={setStep}
      header="🔍 Deep dive into an audience"
      body={
        <>
          <Text mb={4} fontSize="sm" color="gray.600">
            Please select one audience
          </Text>
          {isLoading ? (
            <Flex direction="column" gridGap={2}>
              <Skeleton h="45px" w="full" />
              <Skeleton h="45px" w="full" />
            </Flex>
          ) : (
            <Box>
              {audiences.map((audience) => {
                const isSelected =
                  audience.id && audienceIds.includes(audience.id);
                return (
                  <Card
                    isSelected={isSelected}
                    onClick={() =>
                      isSelected
                        ? setAudienceIds(
                            audienceIds.filter((id) => id !== audience.id),
                          )
                        : audience.id && setAudienceIds([audience.id])
                    }
                    rightIcon={isSelected && <CheckCircleIcon />}
                  >
                    <Text fontSize="sm">{audience.name}</Text>
                  </Card>
                );
              })}
            </Box>
          )}
        </>
      }
      footer={
        <>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Tooltip
            shouldWrapChildren
            hasArrow
            label="Please select one audience"
          >
            <Button
              isDisabled={audienceIds.length !== 1}
              colorScheme="purple"
              onClick={onCreate}
            >
              Create
            </Button>
          </Tooltip>
        </>
      }
    />
  );
};

const STEPS = {
  [CHOOSE_TEMPLATE]: ChooseTemplate,
  [BLANK_TEMPLATE]: BlankTemplate,
  [COMPARISON_TEMPLATE]: ComparisonTemplate,
  [AUDIENCE_TEMPLATE]: AudienceTemplate,
  [ACTIVE_USERS_TEMPLATE]: ActiveUsersTemplate,
} as {
  [key: string]: React.FC<ITemplateProps>;
};

export const AddViewModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  pin: boolean;
}> = ({ isOpen, onClose, pin }) => {
  const [step, setStep] = useState<string>(CHOOSE_TEMPLATE);
  function handleClose() {
    setStep(CHOOSE_TEMPLATE);
    onClose();
  }

  const Step = STEPS[step] as React.FC<ITemplateProps>;

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <Step onClose={handleClose} setStep={setStep} pin={pin} />
      </ModalContent>
    </Modal>
  );
};
