import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AddMenu } from "modules/Views/AddMenu";
import { ViewContainer } from "modules/ViewInsight/ViewInsightsContainer";
import { GroupContacts } from "modules/Contacts/GroupContacts";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { Header as CompanyHeader } from "modules/Company/Header";
import { EnrichmentCard as CompanyEnrichmentCard } from "modules/Company/EnrichmentCard";
import { ITrait } from "core/types/Trait";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import { ViewLocation } from "core/hooks/useViews";
import { useRecentlyViewedPeople } from "core/hooks/useRecentlyViewedPeople";
import { useNavigation } from "core/hooks/useNavigation";
import { useCompanyView } from "core/hooks/useCompanyView";
import { useCompanyColumns } from "core/hooks/useCompanyColumns";
import { useCompany } from "core/hooks/useCompany";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from "core/design-system/components/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { EditingViewProvider } from "core/contexts/EditingViewContext";
import { TRAIT_LEVEL } from "core/constants/traits";
import { baseUrl } from "core/constants/paywall";
import WarningBanner from "core/components/WarningBanner";
import TraitsContainer from "core/components/Traits/Container";
import { PaywallModal } from "core/components/Paywall/Modal";
import { PageContainer } from "core/components/PageContainer";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import {
  BuildingOfficeIcon,
  ListBulletIcon,
  Squares2X2Icon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Divider,
  Flex,
  Skeleton,
  TabPanels,
} from "@chakra-ui/react";
import { NotFound } from "modules/Profiles/NotFound";
import { NEW_PROFILE } from "core/constants/features";
import useFlag from "core/hooks/useFlag";

function Company() {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const { save } = useRecentlyViewedPeople();
  const groupId = decodeURIComponent(encodedGroupId);
  const { company, isCompanyLoading, companyError } = useCompany({
    appId: Number(appId),
    id: groupId,
  });

  const { companyAppObject } = useAppObjects();
  const { view } = useCompanyView();
  const [showPaywall, setShowPaywall] = useState<boolean>(false);
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const redirectUrl = `${baseUrl}/a/${appId}/home`;
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const { columns } = useCompanyColumns();
  const { navigation, toggleSidebar } = useNavigation();
  const [showAudienceModal, setShowAudienceModal] = useState(false);
  const hasNewProfile = useFlag(NEW_PROFILE);

  company &&
    companyAppObject &&
    save(appId, company, companyAppObject.objectType);

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasNewProfile && companyAppObject) {
    return (
      <Navigate
        to={`/a/${appId}/objects/${companyAppObject.id}/object/${groupId}/`}
      />
    );
  }

  if (!groupId) return <Navigate to={`/a/${appId}/home`} />;

  const pages = [
    {
      name: companyAppObject?.pluralName ?? "Companies",
      path: `/a/${appId}/people`,
      current: false,
    },
    {
      name: company?.name || "Unknown",
      path: `/a/${appId}/company/${encodedGroupId}`,
      current: true,
    },
  ];

  if (companyError) {
    return (
      <NotFound
        title="Company not found"
        description="Looks like this company does not exist"
        buttonText="Return to companies"
        breadcrumbs={<Breadcrumbs pages={pages} />}
      />
    );
  }

  return (
    <EditingViewProvider>
      <PageContainer
        className="h-full bg-gray-50"
        bgColor="bg-gray-50"
        minW="2xl"
        maxW="full"
        shadow={false}
        h="full"
        py="4px"
        pr="0"
        pl="0"
      >
        <div className="min-w-screen-lg mx-auto flex h-full flex-row">
          <Box
            minW="340px"
            w="25%"
            opacity={isEditing ? 0.5 : 1}
            pointerEvents={isEditing ? "none" : "auto"}
            overflowY="auto"
            pr="16px"
          >
            <div className="flex max-h-full flex-col gap-5">
              {isCompanyLoading ? (
                <div className="px-4">
                  <Skeleton borderRadius="lg" height="264px" w="100%" />
                </div>
              ) : (
                <div className="mx-4 border-b border-gray-200 bg-gray-50 pb-4 opacity-100">
                  {companyError && (
                    <div className="flex pt-2">
                      <WarningBanner
                        title="This company does not exist"
                        description=""
                      />
                    </div>
                  )}
                  <div className="mb-6 mt-2">
                    <Breadcrumbs pages={pages} />
                  </div>
                  <CompanyHeader />
                </div>
              )}
              <div className="flex flex-1 flex-col gap-4 overflow-y-auto">
                {isCompanyLoading ? (
                  <div className="px-4">
                    <Divider mb="4" />
                    <Skeleton borderRadius="lg" height="300px" w="100%" />
                  </div>
                ) : (
                  <div className="px-4">
                    <TraitsContainer
                      id={groupId}
                      traitLevel={TRAIT_LEVEL.COMPANY}
                      traitKeyValues={company?.traits as ITrait[]}
                      showError={false}
                      allowComputedTrait={false}
                    />
                  </div>
                )}
                {isCompanyLoading ? (
                  <div className="px-4">
                    <Skeleton borderRadius="lg" height="300px" w="100%" />
                  </div>
                ) : (
                  <div className="px-4 pb-8">
                    <Divider mb="4" />
                    {company && <CompanyEnrichmentCard />}
                  </div>
                )}
              </div>
            </div>
          </Box>
          <div
            className={`flex max-w-full flex-1 overflow-y-auto rounded-lg border border-gray-100 px-4 pb-16 pt-4 shadow-sm ${
              isEditing ? "!border-none bg-gray-25 !shadow-none" : "bg-white"
            }`}
          >
            <div className="mx-auto">
              {isCompanyLoading ? (
                <>
                  <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
                  <Skeleton borderRadius="lg" height="300px" w="100%" mb={4} />
                  <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
                </>
              ) : (
                <StyledTabs isLazy defaultIndex={0} w="980px" maxW="full">
                  <StyledTabList
                    boxShadow="sm"
                    w="100%"
                    opacity={isEditing ? 0.5 : 1}
                  >
                    <StyledTab>
                      <div className="flex items-center gap-2">
                        <BuildingOfficeIcon className="h-4 text-gray-600" />
                        Overview
                      </div>
                    </StyledTab>
                    <StyledTab>
                      <div className="flex items-center gap-2">
                        <ListBulletIcon className="h-4 text-gray-600" />
                        Feed
                      </div>
                    </StyledTab>
                    <StyledTab>
                      <div className="flex items-center gap-2">
                        <UsersIcon className="h-4 text-gray-600" />
                        Users
                      </div>
                    </StyledTab>
                  </StyledTabList>
                  <TabPanels maxW="full">
                    <StyledTabPanel
                      minW={columns === 3 ? "980px" : "790px"}
                      w="100%"
                    >
                      <div className="mb-4 flex items-center justify-between">
                        <p className="text-lg font-semibold">Overview</p>
                        <Flex justifyContent="flex-end" w="100%" gap={1}>
                          <AddMenu
                            onAddInsightClick={() => {
                              setShowAddModal(!showAddModal);
                              dispatch(toggleEdit());
                            }}
                            onAddSQLClick={() => {
                              setShowSQLModal(!showSQLModal);
                              dispatch(toggleEdit());
                            }}
                            onAddAudienceClick={() => {
                              setShowAudienceModal(!showAudienceModal);
                              dispatch(toggleEdit());
                            }}
                          />

                          <Button
                            key={isEditing ? "edit" : "done"}
                            variant={isEditing ? "solid" : "ghost"}
                            colorScheme={isEditing ? "purple" : "gray"}
                            onClick={() => {
                              dispatch(toggleEdit());
                            }}
                            leftIcon={<Squares2X2Icon className="h-4 w-4" />}
                          >
                            {isEditing ? "Done" : "Edit"}
                          </Button>
                        </Flex>
                      </div>
                      <Box pb={10} w="full">
                        {view && (
                          <ViewContainer
                            view={view}
                            location={ViewLocation.Company}
                            viewId={Number(view.id)}
                            columns={columns}
                            width={columns === 3 ? "315px" : "385px"}
                            paywallInsights
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            showSQLModal={showSQLModal}
                            setShowSQLModal={setShowSQLModal}
                            showAudienceModal={showAudienceModal}
                            setShowAudienceModal={setShowAudienceModal}
                          />
                        )}
                      </Box>
                    </StyledTabPanel>
                    <StyledTabPanel
                      minW={columns === 3 ? "980px" : "790px"}
                      w="100%"
                    >
                      {company && (
                        <ContactEventLog
                          appId={Number(appId)}
                          groupId={groupId}
                          groupType={GroupType.Company}
                        />
                      )}
                    </StyledTabPanel>
                    <StyledTabPanel
                      minW={columns === 3 ? "980px" : "790px"}
                      w="100%"
                    >
                      <GroupContacts />
                    </StyledTabPanel>
                  </TabPanels>
                </StyledTabs>
              )}
              {showPaywall && (
                <PaywallModal redirectUrl={redirectUrl}>
                  {(paywallModal) => (
                    <paywallModal.Modal
                      isOpen
                      onClose={() => setShowPaywall(false)}
                    />
                  )}
                </PaywallModal>
              )}
            </div>
          </div>
        </div>
      </PageContainer>
    </EditingViewProvider>
  );
}

export default Company;
