import { ITraitWithConfig } from "core/types/Trait";
import { Plan } from "core/types/App";
import { ComputationType, TRAIT_LEVEL } from "core/constants/traits";
import { PaywallPopover } from "core/components/Paywall/Popover";
import {
  ArrowsUpDownIcon,
  ArrowTrendingUpIcon,
  ChevronDownIcon,
  ClockIcon,
  CodeBracketIcon,
  CursorArrowRippleIcon,
  PercentBadgeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

const USER_COUNT_OUTPUT = {
  value: ComputationType.UserCount,
  label: "User count",
  description: "The total number of users that performed the event per company",
  Icon: UserIcon,
};

const EVENT_COUNT_OUTPUT = {
  value: ComputationType.EventCount,
  label: "Total event count",
  description: "The total number of events performed by a user / company",
  Icon: CursorArrowRippleIcon,
};

const DAY_COUNT_OUTPUT = {
  value: ComputationType.DayCount,
  label: "Active days",
  description:
    "The total number of days a user / company has been active in the last X days",
  Icon: CursorArrowRippleIcon,
};

const FIRST_SEEN_OUTPUT = {
  value: ComputationType.FirstSeen,
  label: "First seen",
  description: "The timestamp of the first time an event happened",
  Icon: ClockIcon,
};

const LAST_SEEN_OUTPUT = {
  value: ComputationType.LastSeen,
  label: "Last seen",
  description: "The timestamp of the last time an event happened",
  Icon: ClockIcon,
};

const BOOLEAN_OUTPUT = {
  value: ComputationType.Boolean,
  label: "Boolean",
  description: "Whether an event has happened or not",
  Icon: ArrowsUpDownIcon,
};

const EVENT_COUNT_CHANGE_OUTPUT = {
  value: ComputationType.EventCountChange,
  label: "Event count change",
  description:
    "The percentage change of events performed by a user / company in the selected time range vs the previous time range",
  Icon: ArrowTrendingUpIcon,
};

const RATIO_OUTPUT = {
  value: ComputationType.Ratio,
  label: "Ratio",
  description: "Ratio output of one trait over another",
  Icon: PercentBadgeIcon,
};

const SQL_OUTPUT = {
  value: ComputationType.Sql,
  label: "SQL",
  description: "SQL output",
  Icon: CodeBracketIcon,
};

const OUTPUT_PER_LEVEL = {
  [TRAIT_LEVEL.USER]: [
    EVENT_COUNT_OUTPUT,
    DAY_COUNT_OUTPUT,
    FIRST_SEEN_OUTPUT,
    LAST_SEEN_OUTPUT,
    BOOLEAN_OUTPUT,
    EVENT_COUNT_CHANGE_OUTPUT,
    RATIO_OUTPUT,
    SQL_OUTPUT,
  ],
  [TRAIT_LEVEL.COMPANY]: [
    USER_COUNT_OUTPUT,
    EVENT_COUNT_OUTPUT,
    DAY_COUNT_OUTPUT,
    FIRST_SEEN_OUTPUT,
    LAST_SEEN_OUTPUT,
    BOOLEAN_OUTPUT,
    EVENT_COUNT_CHANGE_OUTPUT,
    RATIO_OUTPUT,
    SQL_OUTPUT,
  ],
  [TRAIT_LEVEL.GROUP]: [
    USER_COUNT_OUTPUT,
    EVENT_COUNT_OUTPUT,
    DAY_COUNT_OUTPUT,
    FIRST_SEEN_OUTPUT,
    LAST_SEEN_OUTPUT,
    BOOLEAN_OUTPUT,
    EVENT_COUNT_CHANGE_OUTPUT,
    RATIO_OUTPUT,
    SQL_OUTPUT,
  ],
};

export const Output: React.FC<{
  trait: ITraitWithConfig;
  onChangeComputationType: (output: ComputationType) => void;
}> = ({ trait, onChangeComputationType }) => {
  const filteredOutputs =
    OUTPUT_PER_LEVEL[trait.level as keyof typeof OUTPUT_PER_LEVEL];

  return (
    <div>
      <Menu>
        <MenuButton
          w="250px"
          as={Button}
          textAlign="left"
          rightIcon={<ChevronDownIcon className="h-3" />}
          isDisabled={trait.config.computationType === ComputationType.Sql}
        >
          {
            OUTPUT_PER_LEVEL[trait.level as keyof typeof OUTPUT_PER_LEVEL].find(
              (output: { value: ComputationType; label: string }) =>
                output.value === trait.config.computationType,
            )?.label
          }
        </MenuButton>
        <MenuList
          maxW="300px"
          maxH="375px"
          overflowY="auto"
          shadow="lg"
          border="1px solid"
          borderColor="gray.300"
          bg="white"
          position="static"
          zIndex="overlay"
        >
          {filteredOutputs
            .filter((output) => output.value !== ComputationType.Sql)
            .map((output) => (
              <PaywallPopover
                feature={`computed traits`}
                redirect={`settings/computed-traits`}
                plan={Plan.Pro}
                placement="right"
              >
                <MenuItem
                  data-testid="menu-item"
                  bg="white"
                  p={0}
                  key={output.value}
                  onClick={() => onChangeComputationType(output.value)}
                >
                  <div className="flex gap-2 bg-white p-2">
                    <div>{<output.Icon className="h-4" />}</div>
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium">{output.label}</p>
                      <p className="text-xs text-gray-500">
                        {output.description}
                      </p>
                    </div>
                  </div>
                </MenuItem>
              </PaywallPopover>
            ))}
        </MenuList>
      </Menu>
    </div>
  );
};
