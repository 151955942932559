import humps from "humps";
import { SnakeCasedProperties } from "type-fest";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IGroup } from "core/types/Group";

// TODO: Configure interface later
export interface IObject extends IGroup {}

export const objectApi = createApi({
  baseQuery,
  reducerPath: "objectApi",
  tagTypes: ["Object"],
  endpoints: (builder) => ({
    getObject: builder.query({
      query: ({
        appId,
        objectId,
        appObjectId,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
      }) => ({
        url: `/objects/object/${objectId}`,
        params: humps.decamelizeKeys({ appId, appObjectId }),
      }),
      transformResponse: (response: SnakeCasedProperties<IObject>): IObject =>
        humps.camelizeKeys(response) as IObject,
      providesTags: (result) =>
        result ? [{ type: "Object", id: result.id }] : [],
    }),
    updateObject: builder.mutation({
      query: ({
        appId,
        objectId,
        data,
      }: {
        appId: number;
        objectId: string;
        data: IObject;
      }) => ({
        url: `/objects/${objectId}`,
        method: "PUT",
        body: humps.decamelizeKeys(data),
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: "Object", id: objectId },
        { type: "Object", id: "LIST" },
      ],
    }),
    deleteObject: builder.mutation({
      query: ({
        appId,
        appObjectId,
        objectId,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
      }) => ({
        url: `/objects/object`,
        method: "DELETE",
        body: humps.decamelizeKeys({ appId, appObjectId, objectId }),
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: "Object", id: objectId },
        { type: "Object", id: "LIST" },
      ],
    }),
    mergeObject: builder.mutation({
      query: ({
        appId,
        appObjectId,
        sourceObjectId,
        targetObjectId,
      }: {
        appId: number;
        appObjectId: number;
        targetObjectId: string;
        sourceObjectId: string;
      }) => ({
        url: `/objects/object/merge`,
        method: "POST",
        body: humps.decamelizeKeys({
          appId,
          appObjectId,
          targetObjectId,
          sourceObjectId,
        }),
      }),
      invalidatesTags: (result, error, { targetObjectId }) => [
        { type: "Object", id: targetObjectId },
        { type: "Object", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetObjectQuery,
  useUpdateObjectMutation,
  useDeleteObjectMutation,
  useMergeObjectMutation,
} = objectApi;
