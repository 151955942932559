import { useGetSettingsQuery } from "core/models/hubspot";
import { SCOPE_VERSION_V2 } from "core/hooks/useHubspotSettings";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Button } from "Components/ui/button";

export const GrantAdditionalScopesCard = () => {
  const currentApp = useCurrentApp();
  const { data: dataSetting } = useGetSettingsQuery({
    appId: currentApp.id,
  });

  const hasLatestScopeVersion = dataSetting?.scopeVersion === SCOPE_VERSION_V2;

  if (hasLatestScopeVersion) {
    return null;
  }

  return (
    <div className="rounded-lg border border-zinc-200 bg-zinc-50/50 p-3 backdrop-blur-sm">
      <div className="flex flex-col gap-4">
        <div>
          <h3 className="text-sm font-medium text-zinc-900">
            Additional permissions required
          </h3>
          <p className="mt-2 text-sm text-zinc-600">
            We've recently updated our HubSpot integration to enable two-way
            syncs of data between HubSpot and June and support for syncing
            account ownership. To enable these features, we need additional
            permissions.
          </p>
        </div>
        <div className="flex justify-end">
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              window.location.replace(
                `${import.meta.env.VITE_API_HOST}/auth/hubspot/install`,
              );
            }}
          >
            Grant Additional Permissions
          </Button>
        </div>
      </div>
    </div>
  );
};
