import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IQuery } from "core/types/Query";
import { useQueries } from "core/hooks/useQueries";
import { COLOR_NAME_TO_PALETTE } from "core/hooks/useEditViewInsight";
import colors from "core/design-system/constants/theme/colors";
import InsightCard from "core/components/InsightCard/Index";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { IResult } from "./Result";
import { Graph } from "./Graph";

export interface IResultColumn {
  name: string;
  type: string;
}

export function getInitialYKey(
  availableKeys: string[],
  query?: IQuery,
): string {
  if (query?.graphYAxis) return query?.graphYAxis;

  if (availableKeys?.length > 0)
    return (
      availableKeys?.find((k: string) => k.toLowerCase().includes(`count`)) ||
      availableKeys[0] ||
      ""
    );

  return "";
}

export function getInitialXKey(
  availableKeys: string[],
  query?: IQuery,
): string {
  if (query?.graphXAxis) return query?.graphXAxis;
  if (availableKeys?.length > 0) {
    return (
      availableKeys.find(
        (k: string) =>
          k.toLowerCase().includes(`timestamp`) ||
          k.toLowerCase().includes(`date`) ||
          k.toLowerCase().includes(`_id`) ||
          k.toLowerCase().includes(`name`) ||
          k.toLowerCase().includes(`day`) ||
          k.toLowerCase().includes(`month`) ||
          k.toLowerCase().includes(`week`),
      ) ||
      availableKeys[1] ||
      ""
    );
  }
  return "";
}

export const PublicResult: React.FC<IResult> = ({ viewInsight, query }) => {
  const { appId, groupId, sharingSecretToken } = useParams();
  const {
    query: latestQuery,
    queryResult,
    executeQuery,
    isExecutingQuery,
  } = useQueries(query.id, groupId, sharingSecretToken);

  const availableKeys = queryResult?.result?.columns?.map(
    (c: IResultColumn) => c.name,
  );
  const defaultYDataKey = getInitialYKey(availableKeys, query);
  const defaultXDataKey = getInitialXKey(availableKeys, query);

  const [xDataKey, setXDataKey] = useState(defaultXDataKey);
  const [yDataKey, setYDataKey] = useState(defaultYDataKey);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [result, setResult] = useState<{
    columns: IResultColumn[];
    data: { [key: string]: string }[];
  } | null>(queryResult?.result);

  useEffect(() => {
    if (!yDataKey) setYDataKey(defaultYDataKey);
    if (!xDataKey) setXDataKey(defaultXDataKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xDataKey, yDataKey, queryResult]);

  useEffect(() => {
    const newResult = queryResult?.result;
    const pagy = queryResult?.pagy;

    if (!newResult || !pagy) {
      setResult(null);
      setHasMore(false);
      return;
    }

    if (page === 1) {
      setResult(newResult);
    } else if (pagy.page === page) {
      setResult((prevResult) => ({
        columns: newResult.columns,
        data: [...(prevResult?.data || []), ...newResult.data],
      }));
    }

    setHasMore(pagy.page < pagy.last);
  }, [queryResult, page]);

  useEffect(() => {
    if (query.id) {
      executeQuery({
        appId: Number(appId),
        queryId: query.id,
        page,
        items: 20,
        groupId,
        sharingSecretToken,
      });
    }
  }, [query.id, page, appId, groupId, sharingSecretToken, executeQuery, query]);

  const isList = latestQuery?.graphType === "list";

  if (!latestQuery) return null;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container id={String(latestQuery?.id)}>
          <div className="flex w-full items-center justify-between border-b border-gray-200 px-5 py-3">
            <div>
              <div className="flex items-center gap-2 font-semibold">
                <div>{viewInsight && <>{viewInsight.title}</>}</div>
              </div>
            </div>
          </div>
          <Box
            h={isList ? "full" : "300px"}
            px={isList ? 0 : 6}
            py={isList ? 0 : 4}
          >
            {result &&
              result.data &&
              result.data.length > 0 &&
              !isExecutingQuery &&
              !queryResult?.error && (
                <Graph
                  key={`${page}-${xDataKey}-${yDataKey}-${JSON.stringify(result)}`}
                  result={result}
                  query={latestQuery}
                  xKey={xDataKey}
                  yKey={yDataKey}
                  page={page}
                  setPage={setPage}
                  hasMore={hasMore}
                  viewInsight={viewInsight}
                  color={
                    viewInsight
                      ? COLOR_NAME_TO_PALETTE[viewInsight.color]
                      : colors.purple
                  }
                />
              )}
            {!isExecutingQuery && queryResult?.error && (
              <div className="flex h-[200px] w-full items-center justify-center">
                <div className="flex flex-col gap-2 text-center">
                  <ExclamationTriangleIcon className="h-6 text-gray-600" />
                  <p className="text-sm font-medium">
                    Error occurred running query
                  </p>
                </div>
              </div>
            )}
            {!isExecutingQuery &&
              !queryResult?.error &&
              (!result ||
                !result.data ||
                (result.data && result.data.length === 0)) && (
                <div className="flex h-[200px] w-full items-center justify-center">
                  <div className="flex flex-col gap-2 text-center">
                    <ExclamationTriangleIcon className="h-6 text-gray-600" />
                    <p className="text-sm font-medium">
                      Error occurred running query
                    </p>
                  </div>
                </div>
              )}
            {isExecutingQuery && (
              <Center h="200px" w="full">
                <Spinner />
              </Center>
            )}
          </Box>
        </Card.Container>
      )}
    </InsightCard>
  );
};
