import { JoinOperatorValue } from "core/types/Audience";
import { IViewInsight } from "core/models/viewInsights";
import { useTraits } from "core/hooks/useTraits";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { useQueryParam } from "core/hooks/useQueryParam";
import { usePeopleList } from "core/hooks/usePeopleList";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Table } from "core/components/Table/People";

function getHeight(gridHeight: number, isScreenshotMode: boolean) {
  if (isScreenshotMode) return "min-h-[600px] max-h-[600px]";
  if (gridHeight === 1) return "min-h-[300px] max-h-[300px]";
  if (gridHeight === 2) return "min-h-[705px] max-h-[705px]";
  return "min-h-[900px] max-h-[900px]";
}

export const AudienceTable: React.FC<{
  viewInsight: IViewInsight;
}> = ({ viewInsight }) => {
  const isScreenshotMode = useQueryParam("screenshot_mode") === "true";
  const {
    tableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    updateTraitConfigOrder,
  } = useTableTraitConfig(
    viewInsight.appObject?.objectType,
    undefined,
    viewInsight.id,
  );
  const {
    audienceFilters,
    entities,
    isLoading,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = usePeopleList({
    appObjectParam: viewInsight.appObject,
    skipUrlHashing: true,
    audienceFiltersParam: {
      filterGroups: viewInsight.audience?.filterGroups || [],
      joinOperator: viewInsight.audience?.joinOperator || JoinOperatorValue.AND,
    },
    viewInsightId: viewInsight.id,
  });
  const { userAppObject } = useAppObjects();
  const { traits } = useTraits(viewInsight.appObject?.objectType);

  if (!entities) return null;

  return (
    <div className="z-0 h-full overflow-scroll pt-4">
      <div className="h-[1px] w-full bg-gray-200" />
      <Table
        id="loaded"
        marginTop="mt-0"
        height={getHeight(viewInsight.gridHeight, isScreenshotMode)}
        key={viewInsight.appObject?.objectType}
        data={entities || []}
        appObject={viewInsight.appObject || userAppObject}
        tableTraitConfigs={tableTraitConfigs || []}
        traits={traits || []}
        createTraitConfig={createTraitConfig}
        deleteTraitConfig={deleteTraitConfig}
        updateTraitConfigOrder={updateTraitConfigOrder}
        hasFilters={!!audienceFilters.filterGroups.length}
        onClearFilters={() => {}}
        isLoading={isLoading}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        showAddTraitButton={false}
        highlightSorting={false}
      />
    </div>
  );
};
