import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { motion } from "framer-motion";
import { Business, CompanySize, ReferralSource } from "core/types/App";
import { updateApp } from "core/models/app";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import NewLogo from "core/design-system/components/Icon/Logos/NewLogo.svg";
import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";

const REFERRAL_SOURCE_OPTIONS = [
  { value: "word_of_mouth", label: "A friend or coworker recommended it" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "twitter", label: "Twitter" },
  { value: "product_hunt", label: "Product Hunt" },
  { value: "google_search", label: "Google" },
  { value: "blogs", label: "I read an article" },
  {
    value: "startup_communities",
    label: "Startup communities (YC, On Deck etc..)",
  },
  { value: "no_code_communities", label: "No-code communities" },
  { value: "other", label: "Other" },
];

const BUSINESS_OPTIONS = [
  {
    value: "b2b",
    label: "B2B",
  },
  {
    value: "b2c",
    label: "B2C",
  },
];

const COMPANY_SIZE_OPTIONS = [
  {
    value: "1-5",
    label: "1-5",
  },
  {
    value: "5-10",
    label: "5-10",
  },
  {
    value: "10-25",
    label: "10-25",
  },
  {
    value: "25-50",
    label: "25-50",
  },
  {
    value: "50+",
    label: "50+",
  },
];

export const Questions: React.FC = () => {
  const {
    id: appId,
    business: appBusiness,
    referralSource: appReferralSource,
    companySize: appCompanySize,
    onboarded,
  } = useCurrentApp();
  const dispatch = useDispatch();
  const [referralSource, setSource] = useState<ReferralSource | undefined>(
    appReferralSource,
  );
  const [business, setBusiness] = useState<Business | undefined>(appBusiness);
  const [companySize, setCompanySize] = useState<CompanySize | undefined>(
    appCompanySize,
  );
  const navigate = useNavigate();
  const NEXT_ROUTE = `getting-started`;

  if (onboarded) {
    return <Navigate to={`/a/${appId}/${NEXT_ROUTE}`} replace={true} />;
  }

  const handleOnboardingComplete = () => {
    if (appId && referralSource && business && companySize) {
      dispatch(updateApp({ appId, business, companySize, referralSource }));
      if (companySize !== "1-5" && business === "b2b") {
        navigate(`/a/${appId}/onboarding/schedule`);
      } else {
        navigate(`/a/${appId}/${NEXT_ROUTE}`);
      }
    }
  };

  return (
    <Center
      h="100vh"
      w="100vw"
      backgroundImage="radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%)"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        style={{
          width: "480px",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "2rem 1.5rem",
          boxShadow:
            "rgb(0 0 0 / 5%) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 2px 4px",
        }}
      >
        <Flex direction="column" alignItems="center" w="full">
          <Box backgroundImage={NewLogo} w="38px" h="40px"></Box>
          <Text fontSize="md" fontWeight="semibold" mt={4} mb={2}>
            Welcome to June
          </Text>
          <Text fontSize="sm" color="gray.600" textAlign="center">
            Tell us about yourself so we can help you set up your workspace.
          </Text>
        </Flex>

        <Stack gap={2}>
          <Stack>
            <Text fontWeight="semibold" fontSize="xs">
              Are you a B2B or B2C company?
            </Text>
            <Select
              onChange={(e) => setBusiness(e.currentTarget.value as Business)}
              placeholder="Select your business type"
              focusBorderColor="purple.400"
              fontSize="sm"
              defaultValue={business}
            >
              {BUSINESS_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Stack>

          <Stack>
            <Text fontWeight="semibold" fontSize="xs">
              How many people work there?
            </Text>
            <Select
              onChange={(e) =>
                setCompanySize(e.currentTarget.value as CompanySize)
              }
              placeholder="Select your company size"
              focusBorderColor="purple.400"
              fontSize="sm"
              defaultValue={companySize}
            >
              {COMPANY_SIZE_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Stack>

          <Stack>
            <Text fontWeight="semibold" fontSize="xs">
              How did you hear about us?
            </Text>
            <Select
              onChange={(e) =>
                setSource(e.currentTarget.value as ReferralSource)
              }
              placeholder="Please let us know how you heard about June"
              focusBorderColor="purple.400"
              fontSize="sm"
              defaultValue={referralSource}
            >
              {REFERRAL_SOURCE_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Stack>
        </Stack>
        <Button
          colorScheme="purple"
          isDisabled={!referralSource || !companySize || !business}
          onClick={handleOnboardingComplete}
        >
          Get started
        </Button>
      </motion.div>
    </Center>
  );
};
