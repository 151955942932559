import { useParams } from "react-router-dom";
import { IAudience, JoinOperatorValue } from "core/types/Audience";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { Level } from "core/models/people";
import { useGetAudienceCountQuery } from "core/models/audiences";
import { useQueryParam } from "./useQueryParam";

export const useAudienceViewInsightDescription = ({
  audience,
  appObject,
  viewInsightId,
}: {
  audience: IAudience;
  appObject: IAppObject;
  viewInsightId: number;
}) => {
  const { appId } = useParams();
  const token = useQueryParam("token") || undefined;
  const levels = {
    [AppObjectType.User]: Level.User,
    [AppObjectType.Group]: Level.Group,
    [AppObjectType.Company]: Level.Company,
  } as { [key in AppObjectType]: Level };

  const { data } = useGetAudienceCountQuery({
    appId: Number(appId),
    audienceFilters: {
      filterGroups: audience?.filterGroups || [],
      joinOperator: audience?.joinOperator || JoinOperatorValue.AND,
    },
    level: levels[appObject.objectType] as Level,
    token,
    viewInsightId,
  });

  return data?.count;
};
