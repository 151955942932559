import { useCallback } from "react";
import {
  FavouriteObjectType,
  IFavourite,
  useCreateFavouriteMutation,
  useDeleteFavouriteMutation,
  useGetFavouritesQuery,
} from "core/models/favourites";
import { FAVOURITES } from "core/constants/features";
import useFlag from "./useFlag";
import { toast } from "sonner";

export const useFavourites = (
  appId: number,
  objectTypes: FavouriteObjectType[],
) => {
  const { data: favourites, isLoading } = useGetFavouritesQuery({
    appId,
    objectTypes,
  });
  const [createFavourite] = useCreateFavouriteMutation();
  const [deleteFavourite, { isLoading: isDeleting }] =
    useDeleteFavouriteMutation();
  const hasFavouritesEnabled = useFlag(FAVOURITES);

  const onCreateFavourite = useCallback(
    (favourite: Pick<IFavourite, "objectId" | "objectType">) => {
      createFavourite({ ...favourite, appId })
        .unwrap()
        .then(() => {
          toast.success("Added to favourites");
        })
        .catch((err) => {
          toast.error("Failed to add to favourites");
        });
    },
    [createFavourite, appId, toast],
  );

  const onDeleteFavourite = useCallback(
    (favourite: IFavourite) => {
      deleteFavourite({
        id: favourite.id,
        appId,
        objectId: favourite.objectId,
        objectType: favourite.objectType,
      })
        .unwrap()
        .then(() => {
          toast.success("Removed from favourites");
        })
        .catch((err) => {
          toast.error("Failed to remove from favourites");
        });
    },
    [deleteFavourite, appId, toast],
  );

  return {
    favourites,
    isLoading,
    isDeleting,
    onCreateFavourite,
    onDeleteFavourite,
    hasFavouritesEnabled,
  };
};
