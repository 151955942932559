import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AddMenu } from "modules/Views/AddMenu";
import { ViewContainer } from "modules/ViewInsight/ViewInsightsContainer";
import { NotFound } from "modules/Profiles/NotFound";
import { Header } from "modules/Groups/Group/Header";
import { EnrichmentCard } from "modules/Groups/Group/EnrichmentCard";
import { GroupContacts } from "modules/Contacts/GroupContacts";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { ITrait } from "core/types/Trait";
import { useUpdateViewMutation } from "core/models/views";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import { ViewLocation } from "core/hooks/useViews";
import { useRecentlyViewedPeople } from "core/hooks/useRecentlyViewedPeople";
import { useNavigation } from "core/hooks/useNavigation";
import { useGroup } from "core/hooks/useGroup";
import { useCompanyView } from "core/hooks/useCompanyView";
import { useCompanyColumns } from "core/hooks/useCompanyColumns";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from "core/design-system/components/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { EditingViewProvider } from "core/contexts/EditingViewContext";
import { TRAIT_LEVEL } from "core/constants/traits";
import { baseUrl } from "core/constants/paywall";
import WarningBanner from "core/components/WarningBanner";
import { TraitsContainer } from "core/components/Traits/Container";
import { PaywallModal } from "core/components/Paywall/Modal";
import { PageContainer } from "core/components/PageContainer";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { UseInternalUsersToggle } from "core/components/App/UseInternalUsersToggle";
import {
  BuildingOfficeIcon,
  ListBulletIcon,
  Squares2X2Icon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Divider,
  Flex,
  Skeleton,
  Spinner,
  TabPanels,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { NEW_PROFILE } from "core/constants/features";
import useFlag from "core/hooks/useFlag";

function UnidentifiedGroupDescription() {
  return (
    <>
      We received track calls, but no group calls for this company. Send a group
      call to add information about this company.{" "}
      <Text
        as="a"
        fontWeight="semibold"
        color="purple.500"
        target="blank"
        href="https://segment.com/docs/connections/spec/group/"
      >
        Learn more
      </Text>
    </>
  );
}

function Group() {
  const [showPaywall, setShowPaywall] = useState<boolean>(false);
  const { save } = useRecentlyViewedPeople();
  const { view, isLoadingView } = useCompanyView();
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { fetchGroup, groupLoading, groupError, group } = useGroup({
    id: groupId,
  });
  const dispatch = useDispatch();
  const redirectUrl = `${baseUrl}/a/${appId}/home`;
  const { groupAppObject } = useAppObjects();
  const { columns } = useCompanyColumns();
  const { navigation, toggleSidebar } = useNavigation();
  const [updateView, { isLoading: isUpdatingView }] = useUpdateViewMutation();
  const hasNewProfile = useFlag(NEW_PROFILE);

  group && groupAppObject && save(appId, group, groupAppObject.objectType);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasNewProfile && groupAppObject) {
    return (
      <Navigate
        to={`/a/${appId}/objects/${groupAppObject.id}/object/${groupId}/`}
      />
    );
  }

  if (!groupId) {
    return <Navigate to={`/a/${appId}/groups`} />;
  }

  const pages = [
    {
      name: groupAppObject?.pluralName ?? "Workspaces",
      path: `/a/${appId}/people`,
      current: false,
    },
    {
      name: group?.name ?? "Unknown",
      path: `/a/${appId}/group/${encodedGroupId}`,
      current: true,
    },
  ];

  if (groupError) {
    return (
      <NotFound
        title="Group not found"
        description="Looks like this group does not exist"
        buttonText="Return to groups"
        breadcrumbs={<Breadcrumbs pages={pages} />}
      />
    );
  }

  return (
    <EditingViewProvider>
      <PageContainer
        className="h-full bg-gray-50"
        bgColor="bg-gray-50"
        minW="2xl"
        maxW="full"
        shadow={false}
        h="full"
        py="4px"
        pr="0"
        pl="0"
      >
        <div className="min-w-screen-lg mx-auto flex h-full flex-row">
          <Box
            minW="340px"
            w="25%"
            opacity={isEditing ? 0.5 : 1}
            pointerEvents={isEditing ? "none" : "auto"}
            overflowY="auto"
            pr="16px"
          >
            <div className="flex max-h-full flex-col gap-5">
              {groupLoading ? (
                <Skeleton borderRadius="lg" height="264px" w="100%" />
              ) : (
                <div className="mx-4 border-b border-gray-200 bg-gray-50 pb-4 opacity-100">
                  {groupError && (
                    <div className="flex py-2">
                      <WarningBanner
                        title="This company was not identified"
                        description={<UnidentifiedGroupDescription />}
                      />
                    </div>
                  )}
                  <div className="mb-6 mt-2">
                    <Breadcrumbs pages={pages} />
                  </div>
                  <Header />
                </div>
              )}
              <div className="flex flex-1 flex-col gap-4 overflow-y-auto">
                {appId === "9" && (
                  <div className="px-4">
                    <Tooltip
                      placement="top"
                      label="Don't worry! This is only visible to our June app"
                      size="sm"
                      hasArrow
                    >
                      <Button
                        colorScheme="purple"
                        variant="outline"
                        as="a"
                        href={`https://backend.june.so/admin/app/${groupId}`}
                        target="_blank"
                        rightIcon={<ExternalLinkIcon />}
                        w="100%"
                      >
                        Admin page
                      </Button>
                    </Tooltip>
                  </div>
                )}
                {groupLoading ? (
                  <div className="px-4">
                    <Divider />
                    <Skeleton borderRadius="lg" height="300px" w="100%" />
                  </div>
                ) : (
                  <div className="px-4">
                    <TraitsContainer
                      id={groupId}
                      traitLevel={TRAIT_LEVEL.GROUP}
                      traitKeyValues={group?.traits as ITrait[]}
                      showError={false}
                    />
                  </div>
                )}
                {groupLoading ? (
                  <div className="px-4">
                    <Skeleton borderRadius="lg" height="300px" w="100%" />
                  </div>
                ) : (
                  <div className="px-4 pb-8">
                    <Divider mb="4" />
                    {group && <EnrichmentCard />}
                  </div>
                )}
              </div>
            </div>
          </Box>
          <div
            className={`flex max-w-full flex-1 overflow-y-auto rounded-lg border border-gray-100 px-4 pb-16 pt-4 shadow-sm ${
              isEditing ? "!border-none bg-gray-25 !shadow-none" : "bg-white"
            }`}
          >
            <div className="mx-auto">
              {groupLoading ? (
                <>
                  <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
                  <Skeleton borderRadius="lg" height="300px" w="100%" mb={4} />
                  <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
                </>
              ) : (
                <StyledTabs isLazy defaultIndex={0} w="980px" maxW="full">
                  <StyledTabList
                    boxShadow="sm"
                    w="100%"
                    opacity={isEditing ? 0.5 : 1}
                  >
                    <StyledTab>
                      <div className="flex items-center gap-2">
                        <BuildingOfficeIcon className="h-4 text-gray-600" />
                        Overview
                      </div>
                    </StyledTab>
                    <StyledTab>
                      <div className="flex items-center gap-2">
                        <ListBulletIcon className="h-4 text-gray-600" />
                        Feed
                      </div>
                    </StyledTab>
                    <StyledTab>
                      <div className="flex items-center gap-2">
                        <UsersIcon className="h-4 text-gray-600" />
                        Users
                      </div>
                    </StyledTab>
                  </StyledTabList>
                  <TabPanels maxW="full">
                    <StyledTabPanel
                      minW={columns === 3 ? "980px" : "790px"}
                      w="100%"
                    >
                      <div className="mb-4 flex items-center justify-between">
                        <p className="text-lg font-semibold">Overview</p>
                        <Flex justifyContent="flex-end" w="100%" gap={1}>
                          {view && (
                            <UseInternalUsersToggle
                              isEnabled={view.useInternalUsers}
                              isLoading={isLoadingView || isUpdatingView}
                              onChange={(useInternalUsers) => {
                                updateView({
                                  id: Number(view.id),
                                  appId: Number(appId),
                                  useInternalUsers,
                                });
                              }}
                            />
                          )}
                          <AddMenu
                            onAddInsightClick={() => {
                              setShowAddModal(!showAddModal);
                              dispatch(toggleEdit());
                            }}
                            onAddSQLClick={() => {
                              setShowSQLModal(!showSQLModal);
                              dispatch(toggleEdit());
                            }}
                          />

                          <Button
                            key={isEditing ? "edit" : "done"}
                            variant={isEditing ? "solid" : "ghost"}
                            colorScheme={isEditing ? "purple" : "gray"}
                            onClick={() => {
                              dispatch(toggleEdit());
                            }}
                            leftIcon={<Squares2X2Icon className="h-4 w-4" />}
                          >
                            {isEditing ? "Done" : "Edit"}
                          </Button>
                        </Flex>
                      </div>
                      {isLoadingView && !view && (
                        <div className="flex h-[700px] w-full flex-col items-center justify-center gap-y-2">
                          <Spinner
                            emptyColor="gray.200"
                            color="gray.500"
                            thickness="3px"
                          />
                        </div>
                      )}

                      <Box pb={10} w="full">
                        {view && (
                          <ViewContainer
                            view={view}
                            location={ViewLocation.Company}
                            viewId={Number(view.id)}
                            columns={columns}
                            width={columns === 3 ? "315px" : "385px"}
                            paywallInsights
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            showSQLModal={showSQLModal}
                            setShowSQLModal={setShowSQLModal}
                          />
                        )}
                      </Box>
                    </StyledTabPanel>
                    <StyledTabPanel
                      minW={columns === 3 ? "980px" : "790px"}
                      w="100%"
                    >
                      <div className="mb-2 flex w-full justify-end">
                        {view && (
                          <UseInternalUsersToggle
                            isEnabled={view.useInternalUsers}
                            isLoading={isLoadingView || isUpdatingView}
                            onChange={(useInternalUsers) => {
                              updateView({
                                id: Number(view.id),
                                appId: Number(appId),
                                useInternalUsers,
                              });
                            }}
                          />
                        )}
                      </div>
                      {group && (
                        <ContactEventLog
                          key={JSON.stringify(view)}
                          appId={Number(appId)}
                          groupId={groupId}
                          groupType={GroupType.Group}
                          useInternalUsers={view?.useInternalUsers}
                        />
                      )}
                    </StyledTabPanel>
                    <StyledTabPanel
                      minW={columns === 3 ? "980px" : "790px"}
                      w="100%"
                    >
                      <div className="mb-2 flex w-full justify-end">
                        {view && (
                          <UseInternalUsersToggle
                            isEnabled={view.useInternalUsers}
                            isLoading={isLoadingView || isUpdatingView}
                            onChange={(useInternalUsers) => {
                              updateView({
                                id: Number(view.id),
                                appId: Number(appId),
                                useInternalUsers,
                              });
                            }}
                          />
                        )}
                      </div>
                      <GroupContacts
                        key={JSON.stringify(view)}
                        showListActions={false}
                        useInternalUsers={view?.useInternalUsers}
                      />
                    </StyledTabPanel>
                  </TabPanels>
                </StyledTabs>
              )}
              {showPaywall && (
                <PaywallModal redirectUrl={redirectUrl}>
                  {(paywallModal) => (
                    <paywallModal.Modal
                      isOpen
                      onClose={() => setShowPaywall(false)}
                    />
                  )}
                </PaywallModal>
              )}
            </div>
          </div>
        </div>
      </PageContainer>
    </EditingViewProvider>
  );
}

export default Group;
